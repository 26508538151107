// import React from 'react';
// import { Accordion, AccordionSummary, AccordionDetails, Typography, Box, List, ListItem, ListItemIcon, ListItemText, Grid } from '@mui/material';
// import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
// import CheckCircleIcon from '@mui/icons-material/CheckCircle';

// const FAQSection = () => {
//     const faqs = [
//         {
//             question: 'If you are going to use a passage of Lorem Ipsum, you need to be sure there isn’t anything',
//             answers: 'Lorem ipsum is are many variations of pass of majorityLorem ipsum is are many variations of pass of majorityLorem ipsum is are many variations of pass of majorityLorem ipsum is are many variations of pass of majorityLorem ipsum is are many variations of pass of majorityLorem ipsum is are many variations of pass of majority.',

//         },
//         {
//             question: 'Why do we use Lorem Ipsum?',
//             answers: 'Lorem ipsum is are many variations of pass of majorityLorem ipsum is are many variations of pass of majorityLorem ipsum is are many variations of pass of majorityLorem ipsum is are many variations of pass of majorityLorem ipsum is are many variations of pass of majorityLorem ipsum is are many variations of pass of majority.',

//         },
//         {
//             question: 'If you are going to use a passage of Lorem Ipsum, you need to be sure there isn’t anything',
//             answers:
//                 'Lorem ipsum is are many variations of pass of majorityLorem ipsum is are many variations of pass of majorityLorem ipsum is are many variations of pass of majorityLorem ipsum is are many variations of pass of majorityLorem ipsum is are many variations of pass of majorityLorem ipsum is are many variations of pass of majority.',


//         },
//         {
//             question: 'If you are going to use a passage of Lorem Ipsum, you need to be sure there isn’t anything',
//             answers: 'Lorem ipsum is are many variations of pass of majorityLorem ipsum is are many variations of pass of majorityLorem ipsum is are many variations of pass of majorityLorem ipsum is are many variations of pass of majorityLorem ipsum is are many variations of pass of majorityLorem ipsum is are many variations of pass of majority.',

//         },
//     ];

//     return (
//         <Grid
//         // sx={{ bgcolor: "#F7F8FA" }}
//         >
//             <Box sx={{ maxWidth: '80%', margin: '0 auto', padding: 2 }}>
//                 <Typography variant="h4" textAlign="center" gutterBottom fontSize="30px">
//                     Frequently <span style={{ fontWeight: "800" }}>Asked Questions</span>
//                 </Typography>
//                 <Typography
//                     sx={{
//                         paddingBottom: "5px",
//                         maxWidth: "900px",
//                         margin: "0 auto",
//                         textAlign: "center",
//                         color: "#b0aaaa",
//                         fontSize: "14px",

//                     }}
//                 >
//                     Lorem Ipsum is simply dummy text of the printing and typesetting industry.
//                     Lorem Ipsum has been the industry’s standard dummy text ever since the
//                     1500s, when an unknown printer took a galley of type and scrambled it to
//                     make a type specimen book.
//                 </Typography>
//                 {faqs.map((faq, index) => (
//                     <Accordion key={index}
//                         sx={{
//                             // border: '1px solid #FFAA17',
//                             borderRadius: '4px',
//                             mb: 2,
//                             boxShadow: "none",
//                             background: "#F7F7F7",

//                             '&::before': {
//                                 display: 'none', // This hides the ::before pseudo-element
//                             },

//                         }}>
//                         <AccordionSummary
//                             expandIcon={<ExpandMoreIcon />}
//                             aria-controls={`panel${index}-content`}
//                             id={`panel${index}-header`}
//                             sx={{
//                                 //   backgroundColor: '#FFF8E1',
//                                 // boxShadow: "none",
//                                 '& .MuiAccordionSummary-expandIconWrapper': { color: '#FFA000' },
//                             }}
//                         >
//                             <Typography sx={{ fontWeight: 'bold', color: '#FFA000' }}><span style={{ marginRight: "10px" }}> {index + 1} </span>{faq.question}</Typography>
//                         </AccordionSummary>
//                         <AccordionDetails sx={{p:"0 20px 16px 50px",display:"flex",justifyContent:"center",color:'#7A7A7A'}}>
//                             <Typography sx={{pr:4}}>{faq.answers}</Typography>

//                         </AccordionDetails>
//                     </Accordion>
//                 ))}
//             </Box>
//         </Grid>
//     );
// };

// export default FAQSection;



import React, { useState } from 'react';
import { Accordion, AccordionSummary, AccordionDetails, Typography, Box, Grid } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const FAQSection = ({ faqsDates }) => {
    const [expanded, setExpanded] = useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const faqs = faqsDates?.[0]?.additional_data?.card_design || [
        {
            title: 'If you are going to use a passage of Lorem Ipsum, you need to be sure there isn’t anything',
            content: 'Lorem ipsum is are many variations of pass of majorityLorem ipsum is are many variations of pass of majorityLorem ipsum is are many variations of pass of majorityLorem ipsum is are many variations of pass of majorityLorem ipsum is are many variations of pass of majorityLorem ipsum is are many variations of pass of majority.',
        },
        {
            title: 'Why do we use Lorem Ipsum?',
            content: 'Lorem ipsum is are many variations of pass of majorityLorem ipsum is are many variations of pass of majorityLorem ipsum is are many variations of pass of majorityLorem ipsum is are many variations of pass of majorityLorem ipsum is are many variations of pass of majorityLorem ipsum is are many variations of pass of majority.',
        },
        {
            title: 'If you are going to use a passage of Lorem Ipsum, you need to be sure there isn’t anything',
            content:
                'Lorem ipsum is are many variations of pass of majorityLorem ipsum is are many variations of pass of majorityLorem ipsum is are many variations of pass of majorityLorem ipsum is are many variations of pass of majorityLorem ipsum is are many variations of pass of majorityLorem ipsum is are many variations of pass of majority.',
        },
        {
            title: 'If you are going to use a passage of Lorem Ipsum, you need to be sure there isn’t anything',
            content: 'Lorem ipsum is are many variations of pass of majorityLorem ipsum is are many variations of pass of majorityLorem ipsum is are many variations of pass of majorityLorem ipsum is are many variations of pass of majorityLorem ipsum is are many variations of pass of majorityLorem ipsum is are many variations of pass of majority.',
        },
        {
            title: 'If you are going to use a passage of Lorem Ipsum, you need to be sure there isn’t anything',
            content: 'Lorem ipsum is are many variations of pass of majorityLorem ipsum is are many variations of pass of majorityLorem ipsum is are many variations of pass of majorityLorem ipsum is are many variations of pass of majorityLorem ipsum is are many variations of pass of majorityLorem ipsum is are many variations of pass of majority.',
        },
    ];


    return (
        <Grid sx={{ pb: 2 ,pt:4}}>
            <Box sx={{ maxWidth: {xs:'100%',md:"80%"}, margin: {xs:'0 20px',md:"0 auto" }}}>
                <Typography variant="h3" textAlign="center" gutterBottom sx={{ mb: 2 }}>
                    {faqsDates?.[0]?.heading || "Default Heading"} <span style={{ fontWeight: '800' }}>{faqsDates?.[0]?.sub_heading || "Default Sub-heading"}</span>
                </Typography>
                <Typography
                    variant='body1'
                    sx={{
                        paddingBottom: 4,
                        maxWidth: '900px',
                        margin: '0 10px',
                        textAlign: 'center',
                        color: '#b0aaaa',


                    }}
                >
                    {faqsDates?.[0]?.content || "Default Content"}

                </Typography>
                {faqs.map((faq, index) => (
                    <Accordion
                        key={index}
                        expanded={expanded === `panel${index}`}
                        onChange={handleChange(`panel${index}`)}
                        sx={{
                            borderRadius: '4px',
                            mb: 2,
                            boxShadow: 'none',
                            background: '#F7F7F7',
                            '&::before': { display: 'none' },
                        }}
                    >
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls={`panel${index}-content`}
                            id={`panel${index}-header`}
                            sx={{
                                '& .MuiAccordionSummary-expandIconWrapper': { color: "#3B4056" },
                            }}
                        >
                            <Typography
                                variant='body2'
                                sx={{
                                    // fontWeight: 'bold',
                                    color: expanded === `panel${index}` ? '#C38F51' : '#3B4056',
                                }}
                            >
                                <span style={{ marginRight: '10px' }}>{index + 1}</span>
                                {faq.title}
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails
                            sx={{ p: '0 20px 16px 37px', display: 'flex', justifyContent: 'center', color: '#7A7A7A' }}
                        >
                            <Typography variant="body1" sx={{ pr: 4 }}>{faq.content}</Typography>
                        </AccordionDetails>
                    </Accordion>
                ))}
            </Box>
        </Grid>
    );
};

export default FAQSection;
