import { combineReducers } from 'redux';
import { configureStore } from '@reduxjs/toolkit';
import getAllHomeSectionsSlice from '../Slices/website/home/getAllHomeSections'
import getAllstakeNowSectionsSlice from '../Slices/website/stakeNow/getAllstakeNowSections'
import getAllReferralsSectionsSlice from '../Slices/website/referrals/getAllReferralsSections'
import getAllBlogSectionsSlice from '../Slices/website/blog/getAllBlogSections'
import getAllContactUsSectionsSlice from '../Slices/website/contactUs/getAllContactUsSections'
import getAllAboutUsSectionsSlice from '../Slices/website/AboutUs/getAllAboutUsSections'
import postContactUsFormSlice from '../Slices/website/contactUs/postContactUsForm'
import cartSlice from '../Slices/AddToCard/cartSlice'
import customerRegisterSlice from '../Slices/Customer/customerRegister'
import customerLoginSlice from '../Slices/Customer/customerLogin'
import PurchaseSchemesSlice from '../Slices/Schemes/PurchaseSchemes'
import ImageUploadPostSlice from '../Slices/Customer/ImageUploadPost';
import getKYCByCountrySlice from '../Slices/Customer/getKYCByCountry';
import getCustomerGoldBalanceDashboardSlice from '../Slices/Customer/getCustomerGoldBalanceDashboard'
import getPurchasesByCustomerIdSlice from '../Slices/Customer/getPurchasesByCustomerId'
import getCustomerRewardsListSlice from '../Slices/Customer/getCustomerRewardsList'
import getReferralRewardDashboardSlice from '../Slices/Customer/getReferralRewardDashboard';
import  getCartItemCountSlice from '../Slices/AddToCard/getCartItemCount';
import  EmailSendOtpSlice from '../Slices/VerificationOtp/EmailSendOtp';
import EmailVerifyOtpSlice from '../Slices/VerificationOtp/EmailVerifyOtp';
import addMultiPurchaseSlice from '../Slices/Customer/addMultiPurchase'
import resetPasswordForCustomerSlice from '../Slices/VerificationOtp/resetPasswordForCustomer';
import  getPlanByIdSlice from '../Slices/AddToCard/getPlanById';

const reducer = combineReducers({


  getAllHomeSections: getAllHomeSectionsSlice,
  getAllstakeNowSections: getAllstakeNowSectionsSlice,
  getAllReferralsSections: getAllReferralsSectionsSlice,
  getAllBlogSections: getAllBlogSectionsSlice,
  getAllContactUsSections: getAllContactUsSectionsSlice,
  getAllAboutUsSections: getAllAboutUsSectionsSlice,
  postContactUsForm: postContactUsFormSlice,
  cart: cartSlice,
  customerRegister: customerRegisterSlice,
  customerLogin: customerLoginSlice,
  PurchaseSchemes: PurchaseSchemesSlice,
  ImageUploadPost: ImageUploadPostSlice,
  getKYCByCountry: getKYCByCountrySlice,
  getCustomerGoldBalanceDashboard:getCustomerGoldBalanceDashboardSlice,
  getPurchasesByCustomerId:getPurchasesByCustomerIdSlice,
  getCustomerRewardsList:getCustomerRewardsListSlice,
  getReferralRewardDashboard:getReferralRewardDashboardSlice,
  getCartItemCount:getCartItemCountSlice,
  EmailSendOtp:EmailSendOtpSlice,
  EmailVerifyOtp:EmailVerifyOtpSlice,
  addMultiPurchase:addMultiPurchaseSlice,
  resetPasswordForCustomer:resetPasswordForCustomerSlice,
  getPlanById:getPlanByIdSlice


});

const store = configureStore({
  reducer,
});
export default store;
