import React from 'react';
import { Box, Button, Typography } from '@mui/material';
import { Formik, Form } from 'formik';
import CustomInput from '../CustomInput/CustomInput';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';

const SignInForm = ({ handleSubmit, toggleForgotPassword }) => {
    const initialValues = {
        email: '',
        password: ''
    };

    const validationSchema = Yup.object({
        email: Yup.string().required('Email is required'),
        password: Yup.string().required('Password is required')
    });

 const navigate=useNavigate()

    const handleGoRegistrationPage = () => {
        navigate("/register");
      };

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
        >
            {({ values, handleChange, handleBlur, errors, touched }) => (
                <Form style={{ width: '100%' }}>
                    <Box mb={1}>
                        <CustomInput
                            name="email"
                            label="Email"
                            custPlaceholder="Enter Email"
                            inputType="text"
                        />
                    </Box>

                    <Box mb={1}>
                        <CustomInput
                            name="password"
                            label="Password"
                            custPlaceholder="Enter Password"
                            inputType="password"
                        />
                    </Box>

                    <Box
                        display="flex"
                        justifyContent="end"
                        alignItems="end"
                        width="100%"
                        mb={3}
                        color="#C38F51"
                    >
                        <Typography variant='body1' fontSize="16px">
                            <Typography
                                variant='subtitle1'
                                onClick={toggleForgotPassword}
                                underline="none"
                                sx={{ cursor: "pointer" }}
                                color='#C38F51'
                            >
                                Forgot password?
                            </Typography>
                        </Typography>
                    </Box>

                    <Button
                        fullWidth
                        type="submit"
                        variant="contained"
                        sx={{
                            borderRadius: 23,
                            py: "7px",
                            color: "white",
                            backgroundColor: "#C38F51"
                        }}
                    >
                        Sign In
                    </Button>

                    <Typography variant="body1" mt={2} sx={{ fontSize: "16px", textAlign: 'center' }}>
                        Don't have an account?&nbsp;
                        <Box sx={{ color: "#C38F51", cursor: "pointer" }} onClick={handleGoRegistrationPage}>
                            Register here
                        </Box>
                    </Typography>
                </Form>
            )}
        </Formik>
    );
};

export default SignInForm;
