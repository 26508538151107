// // import React from 'react'
// // import LandingPage from './LandingPage'
// // import ProsperitySteps from '../ProperitySteps/ProperitySteps'
// // import OfferSection from '../OfferSection/OfferSection'

// // const Home = () => {
// //   return (
// //     <div>
// //         <LandingPage />
// //         <ProsperitySteps />

// //         <OfferSection />
// //     </div>
// //   )
// // }

// // export default Home


// import React from 'react';
// import LandingPage from './LandingPage';
// import ProsperitySteps from '../ProperitySteps/ProperitySteps';
// import OfferSection from '../OfferSection/OfferSection';
// import FAQSection from '../FAQSection/FAQSection';
// // import Footer from '../Footer/Footer';
// import ReferSection from '../ReferSection/ReferSection';
// import ContactUsection from '../ContactUsection/ContactUsection';


// const Home = () => {
//   const handleScrollToTop = () => {
//     window.scrollTo({ top: 0, behavior: "smooth" });
//   };
//   return (

//     <div style={{ position: "relative" }}>
//       {/* <MyAppBar /> */}

//       <LandingPage />
//       <ProsperitySteps />
//       <ReferSection />
//       <OfferSection />
//       <FAQSection />
//       <ContactUsection />
//       {/* <Footer /> */}

//       {/* <Fab
//         size="small"
//         aria-label="scroll to top"
//         onClick={handleScrollToTop}
//         sx={{
//           position: "sticky",
//           bottom: 0,
//           right: 0,
//           // top: -30,
//           borderRadius: "10px",
//           boxShadow: "none",
//           transform: "translateX(50%)",
//           backgroundColor: "red",
//           color: "#C38F51",
//           "&:hover": {
//             backgroundColor: "black",
//             color: "white"
//           },
//         }}
//       >
//         <KeyboardArrowUpIcon />
//       </Fab> */}

//     </div>
//   )
// }


// export default Home;




// import React from 'react'
// import LandingPage from './LandingPage'
// import ProsperitySteps from '../ProperitySteps/ProperitySteps'
// import OfferSection from '../OfferSection/OfferSection'

// const Home = () => {
//   return (
//     <div>
//         <LandingPage />
//         <ProsperitySteps />

//         <OfferSection />
//     </div>
//   )
// }

// export default Home


import React, { useEffect, useState } from 'react';
import LandingPage from './LandingPage';
import ProsperitySteps from '../ProperitySteps/ProperitySteps';
import OfferSection from '../OfferSection/OfferSection';
import FAQSection from '../FAQSection/FAQSection';
// import Footer from '../Footer/Footer';
import ReferSection from '../ReferSection/ReferSection';
import actions from "../../../ReduxStore/actions/index";
import { useDispatch, useSelector } from "react-redux";
import { CircularProgress, Box } from '@mui/material';
import AreYouFinancial from '../AreYouFinancial/AreYouFinancial';
import DownloadPhone from '../DownloadPhone/DownloadPhone';


const Home = () => {
  const [loading, setLoading] = useState(true);
  const handleScrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const dispatch = useDispatch();

  const { getAllHomeSections } = useSelector((state) => state?.getAllHomeSections);

  console.log(getAllHomeSections);


  useEffect(() => {
    const fetchData = async () => {
      const data1 = { data: {}, method: "get", apiName: "getAllHomeSections" };

      setLoading(true); // Set loading to true when the request starts
      await dispatch(actions.GETALLHOMESECTIONS(data1));
      setLoading(false); // Set loading to false after data is fetched
    };
    fetchData();
  }, [dispatch]);


  // Function to get dates by section_name
  const getDatesBySectionName = (sectionName) => {
    return getAllHomeSections?.data
      ?.filter((section) => section.section_name === sectionName)
      || [];
  };


  const bannerDates = getAllHomeSections?.data
    ? getDatesBySectionName('banner')
    : [];
  const stakeNowDates = getAllHomeSections?.data
    ? getDatesBySectionName('stakeNow')
    : [];

  const referralsDates = getAllHomeSections?.data
    ? getDatesBySectionName('referrals')
    : [];

  const offersDates = getAllHomeSections?.data
    ? getDatesBySectionName('offers')
    : [];


  const faqsDates = getAllHomeSections?.data
    ? getDatesBySectionName('faqs')
    : [];


  const getInTouchDates = getAllHomeSections?.data
    ? getDatesBySectionName('getInTouch')
    : [];




  // console.log(bannerDates, "section 1");
  // console.log(stakeNowDates, "section 2");




  return (

    <div style={{ position: "relative" }}>
      {loading ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="100vh"
          style={{ background: "#fff", zIndex: 10 }}
        >
          <CircularProgress style={{ color: 'gold' }} /> {/* Gold spinner */}
        </Box>
      ) : (
        <>

          <LandingPage bannerDates={bannerDates} />
          <OfferSection offersDates={offersDates} />

          <ProsperitySteps stakeNowDates={stakeNowDates} />
          <ReferSection referralsDates={referralsDates} />
          <AreYouFinancial />
          <DownloadPhone />
          
          <FAQSection faqsDates={faqsDates} />
          {/* <ContactUsection getInTouchDates={getInTouchDates} /> */}
        </>
      )}

      {/* <Fab
        size="small"
        aria-label="scroll to top"
        onClick={handleScrollToTop}
        sx={{
          position: "sticky",
          bottom: 0,
          right: 0,
          // top: -30,
          borderRadius: "10px",
          boxShadow: "none",
          transform: "translateX(50%)",
          backgroundColor: "red",
          color: "#C38F51",
          "&:hover": {
            backgroundColor: "black",
            color: "white"
          },
        }}
      >
        <KeyboardArrowUpIcon />
      </Fab> */}

    </div>
  )
}


export default Home;



