// import React from "react";
// import {
//     Box,
//     Typography,
//     Paper,
//     Grid,
//     Button,
//     Card,
//     CardMedia,
//     CardContent,
//     Container,
//     ListItemText
// } from "@mui/material";
// import gold_image from "../../../Assets/gold-bars-background-free-photo.jpg"
// import { Link } from "react-router-dom";
// import { IoMdPricetag } from "react-icons/io";
// import GoldInfoAccordion from "./GoldInfoAccordion";



// const GoldInvestment = () => {
//     return (
//         <Grid container p={6}>
//             {/* Gold Bar Section */}

//             <Grid
//                 xs={6}
//                 sx={{
//                     position: "relative",
//                     background: `linear-gradient(90deg, #000000 0%, #2E2E2ED6 51%, #1D1D1D 100%) , url(${gold_image})`,
//                     backgroundSize: "cover",
//                     backgroundRepeat: "no-repeat",
//                     backgroundPosition: "center",
//                     display: "flex",
//                     flexDirection: "column", // Ensures children are stacked vertically
//                     justifyContent: "flex-end", // Pushes children to the bottom
//                     alignItems: "center", // Centers horizontally
//                     overflow: "hidden",
//                     // textAlign:"center"
//                 }}
//             >
//                 <Container maxWidth="lg" sx={{ zIndex: 2 }}>
//                     <Box
//                         display="flex"
//                         alignItems="center"
//                         justifyContent="center"
//                         // flexWrap="wrap"
//                         p={10}
//                         color="white"
//                     >
//                         <Box sx={{ textAlign: "center" }}>
//                             <Typography
//                                 sx={{
//                                     marginTop: 2,
//                                     fontSize: "16px",
//                                 }}
//                             >
//                                 Estimated Reap Benefit
//                             </Typography>
//                             <Typography
//                                 sx={{
//                                     color: "#1FC45B",
//                                     fontSize: "25px",
//                                     fontWeight: "bold",
//                                 }}
//                             >
//                                 6%* <span style={{ color: "white", fontSize: "13px" }}>Per Quarter</span>
//                             </Typography>
//                             <Link style={{ fontSize: "10px", color: "white" }}>
//                                 * Read Offer Documents
//                             </Link>
//                         </Box>
//                     </Box>
//                 </Container>
//             </Grid>


//             {/* Details Section */}
//             <Grid xs={6}>
//                 <Paper elevation={3} sx={{ padding: 2 }}>
//                     {/* <Typography
//                         variant="h6"
//                         sx={{ marginBottom: 2, display: "flex", alignItems: "center" }}
//                     >
//                         <IoMdPricetag style={{ marginRight: 8 }} /> Initial Stake
//                     </Typography>

//                     <Grid container spacing={2}>
//                         <Grid item xs={8}>
//                             <Typography>13.8 Grm Staked</Typography>
//                             <Typography>$1000 worth Gold Purchased</Typography>
//                         </Grid>
//                         <Grid item xs={4}>
//                             <Typography align="right">on 15-Jan-2025</Typography>
//                         </Grid>
//                     </Grid>
//                     <Box
//                         sx={{
//                             marginTop: 2,
//                             padding: 2,
//                             backgroundColor: "#ffe5b4",
//                             borderRadius: 1,
//                         }}
//                     >
//                         <Typography variant="body2" sx={{ fontWeight: "bold" }}>
//                             6% Per Quarter
//                         </Typography>
//                     </Box> */}

//                     <ListItemText
//                         primary="Brunch this weekend?"
//                         secondary={
//                             <React.Fragment>
//                                 <Typography
//                                     component="span"
//                                     variant="body2"
//                                     sx={{ color: 'text.primary', display: 'inline' }}
//                                 >
//                                     Ali Connors
//                                 </Typography>
//                                 {" — I'll be in your neighborhood doing errands this…"}
//                             </React.Fragment>
//                         }
//                     />
//                     {[
//                         { duration: "+ 6 Months", grams: "0.831759" },
//                         { duration: "+ 12 Months", grams: "0.831759" },
//                         { duration: "+ 18 Months", grams: "0.831759" },
//                         { duration: "+ 24 Months", grams: "0.831759" },
//                     ].map((item, index) => (
//                         <Grid
//                             container
//                             spacing={2}
//                             key={index}
//                             sx={{
//                                 padding: 1,
//                                 backgroundColor: index % 2 === 0 ? "#f9f9f9" : "white",
//                                 borderRadius: 1,
//                                 marginY: 1,
//                             }}
//                         >
//                             <Grid item xs={8}>
//                                 {/* <Typography>Purchase Date {item.duration}</Typography> */}
//                                 <Box component="ul" sx={{ paddingLeft: 2, listStyleType: 'disc' }}>

//                                     <Typography
//                                         // variant="h6"
//                                         sx={{ fontSize: "16px", fontWeight: "600", marginBottom: 2, display: "flex", alignItems: "center" }}
//                                     >
//                                         <IoMdPricetag
//                                             style={{ marginRight: 8 }}
//                                         /> Purchase Date {item.duration}
//                                     </Typography>

//                                     {/* <Box component="ul" sx={{ paddingLeft: 2, listStyleType: 'disc' }}> */}

//                                     <Typography component="li" sx={{ marginBottom: 1 }}>
//                                         {item.grams} Grm Staked
//                                     </Typography>
//                                     <Typography component="li" sx={{ marginBottom: 1 }}>
//                                         $1000 worth Gold Purchased
//                                     </Typography>
//                                 </Box>
//                             </Grid>
//                             <Grid item xs={4}>
//                                 <Typography align="right">on 15-Jan-2025</Typography>
//                             </Grid>
//                         </Grid>
//                     ))}
//                     <Typography variant="body2" sx={{ marginTop: 2 }}>
//                         Total Of <b>13.8 GRAMS + 3.32704 Grams</b> i.e.{" "}
//                         <b>17.127 Grams</b> Worth Of Gold Will Be Staked On The Maturity Date.
//                     </Typography>
//                 </Paper>

//                 {/* Buttons */}
//                 <Box sx={{ display: "flex", justifyContent: "space-between", marginTop: 2 }}>
//                     <Button
//                         variant="contained"
//                         sx={{
//                             backgroundColor: "#f5f5f5",
//                             color: "black",
//                             "&:hover": { backgroundColor: "#e0e0e0" },
//                         }}
//                     >
//                         Add to Cart
//                     </Button>
//                     <Button
//                         variant="contained"
//                         color="warning"
//                         startIcon={<span>⚡</span>}
//                     >
//                         Buy Now
//                     </Button>
//                 </Box>
//             </Grid>
//             <GoldInfoAccordion />
//         </Grid>
//     );
// };

// export default GoldInvestment;



import React from "react";
import {
    Box,
    Typography,
    Paper,
    Grid,
    Button,
    Card,
    CardMedia,
    CardContent,
    Container,
    ListItemText,
    List,
    ListItem,
    Divider,
    ButtonGroup,
    Stack
} from "@mui/material";
import gold_image from "../../../Assets/gold-bars-background-free-photo.jpg"
import { Link, useNavigate } from "react-router-dom";
import { IoMdPricetag } from "react-icons/io";
import GoldInfoAccordion from "./GoldInfoAccordion";
import { GoDotFill } from "react-icons/go";
import { AiFillThunderbolt } from "react-icons/ai";
import cart_icon from "../../../Assets/cart_icon.svg"





const GoldInvestment = () => {
    const navigate = useNavigate()
    const handleNavigate = () => {
        navigate("/addtocart")
    }

    const items = [
        {
            primary: "Initial Stake",
            grams: "13.8",
            worth: "$1000 worth Gold Purchased",
            date: "on 15-Jan-2025",
        },
        {
            primary: "Purchase Date + 6 Months",
            grams: "0.831759",
            worth: "$1000 worth Gold Purchased",
            date: "on 15-Jan-2025",
        },
        {
            primary: "Purchase Date + 12 Months",
            grams: "0.831759",
            worth: "$1000 worth Gold Purchased",
            date: "on 15-Jan-2025",
        },
        {
            primary: "Purchase Date + 18 Months",
            grams: "0.831759",
            worth: "$1000 worth Gold Purchased",
            date: "on 15-Jan-2025",
        },
        {
            primary: "Purchase Date + 24 Months",
            grams: "0.831759",
            worth: "$1000 worth Gold Purchased",
            date: "on 15-Jan-2025",
        },
    ];
    return (
        <Grid container sx={{ p: { xs: 0, md: 6 } }}>
            {/* Content */}


            {/* Gold Bar Section */}

            <Grid
                xs={12} md={5}
                sx={{
                    position: "relative",
                    background: `linear-gradient(90deg, #000000 0%, #2E2E2ED6 51%, #1D1D1D 100%) , url(${gold_image})`,
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                    display: "flex",
                    flexDirection: "column", // Ensures children are stacked vertically
                    justifyContent: "flex-end", // Pushes children to the bottom
                    alignItems: "center", // Centers horizontally
                    overflow: "hidden",
                    // textAlign:"center"
                }}
            >
                <Container maxWidth="lg" sx={{ zIndex: 2 }}>
                    <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        // flexWrap="wrap"
                        p={4}
                        color="white"
                    >
                        <Box sx={{ textAlign: "center" }}>
                            <Typography
                            // variant="s"
                                sx={{
                                    marginTop: 2,
                                    fontSize: "19px",
                                }}
                            >
                                Estimated Reap Benefit
                            </Typography>
                            <Typography
                                sx={{
                                    color: "#1FC45B",
                                    fontSize: "30px",
                                    fontWeight: "bold",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center"
                                }}
                            >
                                6%* <span style={{ color: "white", fontSize: "13px" }}>Per Quarter</span>
                            </Typography>
                            <Link style={{ fontSize: "12px", color: "white" }}>
                                * Read Offer Documents
                            </Link>
                            <Typography
                                variant="subtitle2"
                                align="end"
                                sx={{
                                    marginTop: 3, color: "#7A7A7A", fontSize: "15px", color: "white"

                                }}
                            >
                                Scheme Closes on: -<span style={{ color: "#F53E5C" }}>15/Mar/2025</span>
                            </Typography>
                        </Box>
                    </Box>
                </Container>
            </Grid>


            {/* Details Section */}
            <Grid xs={12} md={7} p="0 10px">
                <Box>

                    <List>
                        {items.map((item, index) => (
                            <React.Fragment key={index}>
                                <ListItem alignItems="flex-start">
                                    <ListItemText
                                        // primary={<span><IoMdPricetag style={{ marginRight: 8 }} /></span>{item.primary}}
                                        primary={
                                            <Box style={{ display: 'flex', alignItems: 'center', justifyContent: "space-between" }}>
                                                <Box>
                                                    <Typography variant="body2" style={{ display: 'flex', alignItems: 'center', marginBottom: "5px" }}>
                                                        <IoMdPricetag style={{ marginRight: 8 }} />
                                                        {item.primary}
                                                    </Typography>
                                                </Box>
                                                <Box>
                                                    <Typography
                                                    variant="body1"
                                                        component="span"
                                                        // variant="caption"
                                                        color="text.secondary"
                                                        sx={{ display: "block", mt: 1 }}


                                                    >
                                                        {item.date}
                                                    </Typography>
                                                </Box>
                                            </Box>
                                        }


                                        secondary={
                                            <div style={{ marginLeft: "15px" }}>

                                                <Typography
                                                    component="span"
                                                    variant="subtitle2"
                                                    color="text.primary"
                                                    sx={{ display: "block", fontWeight: 500 }}
                                                    style={{ display: 'flex', alignItems: 'center' }}
                                                >
                                                    <GoDotFill style={{ color: "#E5E5E5", marginRight: "5px" }} /> <span style={{ fontSize: "18px", color: "#C38F51", fontWeight: "600", marginRight: "2px" }}>{item.grams}</span>Grm Staked
                                                </Typography>
                                                <Typography
                                                    component="span"
                                                    // variant="body2"
                                                    variant="subtitle2"

                                                    color="text.primary"
                                                    sx={{ display: "block", fontWeight: 500 }}
                                                    style={{ display: 'flex', alignItems: 'center' }}
                                                >
                                                    <GoDotFill style={{ color: "#E5E5E5", marginRight: "5px" }} />  {item.worth}
                                                </Typography>


                                            </div>
                                        }
                                    />
                                </ListItem>
                                {/* {item.primary === "Initial Stake" ? <Typography><span><hr></hr><span style={{border:"1px solid red"}}>6% per Quarter</span><hr></hr></span></Typography> : null} */}

                                {item.primary === "Initial Stake" ? (
                                    <Typography variant="subtitle2">
                                        <span style={{ display: "flex", alignItems: "center", marginBottom: "10px" }}>
                                            <hr style={{ flexGrow: 1, margin: "0 0 0 10px", borderColor: "#C38F51", color: "#C38F51" }} />
                                            <span style={{ border: "1px solid #C38F51", padding: "5px 10px", background: "#C38F51", color: "white" }}>6% per Quarter</span>
                                            <hr style={{ flexGrow: 1, margin: "0 10px 0 0", borderColor: "#C38F51" }} />
                                        </span>
                                    </Typography>
                                ) : index < items.length && <Divider sx={{ margin: "0 10px" }} />}


                            </React.Fragment>
                        ))}
                    </List>
                    <Typography variant="body1" sx={{ textAlign: "center", px: 4, fontSize: "14px", color: "#7A7A7A" }}>Total Of <span style={{ color: "#C38F51", fontWeight: "600" }}>13.8 GRAMS + 3.32704</span> Grams I.E <span style={{ color: "#C38F51", fontWeight: "600" }}>17.127</span> Grams Worth Of Gold Will Be Staked On The Maturity Date.</Typography>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            marginTop: 2,
                            width: '100%'
                        }}
                    >
                        <Box
                            gap={2}
                            sx={{
                                display: "flex",
                                // flexDirection: "column",
                                justifyContent: "space-between", // Ensures buttons are spaced evenly
                                alignItems: "center", // Centers buttons vertically
                            }}
                        >
                            <Button
                                // onClick={handleNavigate}
                                onClick={handleNavigate}
                                variant="contained"
                                startIcon={<img src={cart_icon} style={{ height: "20px" }} />}
                                sx={{
                                    textTransform: "none",
                                    color: "white",
                                    // fontSize: "16px",
                                    padding: "0px 30px",
                                    minHeight: "40px",
                                    borderRadius: "23px",
                                    background: "#252525 0% 0% no-repeat padding-box",
                                }}
                            >
                                Add to Cart
                            </Button>
                            <Button
                                onClick={handleNavigate}

                                variant="contained"
                                startIcon={<AiFillThunderbolt sx={{ fontSize: "16px", color: "white" }} />}
                                sx={{
                                    textTransform: "none",
                                    color: "white",
                                    padding: "0px 30px",
                                    // fontSize: "16px",
                                    minHeight: "40px",
                                    borderRadius: "23px",
                                    background: "#C38F51 0% 0% no-repeat padding-box",
                                }}
                            >
                                Buy Now
                            </Button>
                        </Box>

                    </Box>


                </Box>


                {/* Buttons */}



            </Grid>
        </Grid >
    );
};

export default GoldInvestment;
