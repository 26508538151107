
// // // // import React from "react";
// // // // import { Avatar, Box, IconButton, TextField, Tooltip } from "@mui/material";
// // // // import PhotoCamera from "@mui/icons-material/PhotoCamera";

// // // // const ProfileUpload = () => {
// // // //     return (
// // // //         <Box
// // // //             sx={{
// // // //                 display: "flex",
// // // //                 flexDirection: "column",
// // // //                 alignItems: "center",
// // // //                 gap: 2,
// // // //                 mt: 4,
// // // //             }}
// // // //         >
// // // //             {/* Profile Picture Section */}
// // // //             <Box
// // // //                 sx={{
// // // //                     position: "relative",
// // // //                     display: "inline-block",
// // // //                 }}
// // // //             >
// // // //                 <Avatar
// // // //                     sx={{
// // // //                         width: 100,
// // // //                         height: 100,
// // // //                         bgcolor: "grey.400",
// // // //                     }}
// // // //                 >
// // // //                     {/* Placeholder Icon */}
// // // //                     <Box component="span" sx={{ fontSize: 40 }}>
// // // //                         👤
// // // //                     </Box>
// // // //                 </Avatar>
// // // //                 {/* Upload Icon */}
// // // //                 <Tooltip title="Upload">
// // // //                     <IconButton
// // // //                         sx={{
// // // //                             position: "absolute",
// // // //                             bottom: 5,
// // // //                             right: 5,
// // // //                             bgcolor: "orange",
// // // //                             "&:hover": { bgcolor: "darkorange" },
// // // //                         }}
// // // //                         color="primary"
// // // //                         component="label"
// // // //                     >
// // // //                         <input hidden accept="image/*" type="file" />
// // // //                         <PhotoCamera sx={{ color: "white" }} />
// // // //                     </IconButton>
// // // //                 </Tooltip>
// // // //             </Box>

// // // //         </Box>
// // // //     );
// // // // };

// // // // export default ProfileUpload;



// // // import React, { useState } from "react";
// // // import { Avatar, Box, IconButton, Tooltip } from "@mui/material";
// // // import PhotoCamera from "@mui/icons-material/PhotoCamera";
// // // import { RiUser6Fill } from "react-icons/ri";


// // // const ProfileUpload = ({name}) => {
// // //     const [selectedImage, setSelectedImage] = useState(null);

// // //     const handleImageChange = (event) => {
// // //         const file = event.target.files[0];
// // //         if (file) {
// // //             const imageUrl = URL.createObjectURL(file); // Create a temporary URL for the selected image
// // //             setSelectedImage(imageUrl);
// // //         }
// // //     };

// // //     return (
// // //         <Box
// // //             sx={{
// // //                 display: "flex",
// // //                 flexDirection: "column",
// // //                 alignItems: "center",
// // //                 gap: 2,
// // //                 mt: 4,
// // //             }}
// // //         >
// // //             {/* Profile Picture Section */}
// // //             <Box
// // //                 sx={{
// // //                     position: "relative",
// // //                     display: "inline-block",
// // //                 }}
// // //             >
// // //                 <Avatar
// // //                     sx={{
// // //                         width: 70,
// // //                         height: 70,
// // //                         // bgcolor: "grey.400",
// // //                         background:"black"
// // //                     }}
// // //                     src={selectedImage} // Set the Avatar's src to the selected image URL
// // //                 >
// // //                     {!selectedImage && (
// // //                         <Box component="span" sx={{ fontSize: 40 ,color:"white",background:"black"}}>
// // //                             <RiUser6Fill />
// // //                         </Box>
// // //                     )}
// // //                 </Avatar>
// // //                 {/* Upload Icon */}
// // //                 <Tooltip title="Upload">
// // //                     <IconButton
// // //                         sx={{
// // //                             position: "absolute",
// // //                             bottom: 0,
// // //                             right: 0,
// // //                             bgcolor: "#C38F51",
// // //                             "&:hover": { bgcolor: "#C38F51" },
// // //                         }}
// // //                         color="primary"
// // //                         component="label"
// // //                     >
// // //                         <input
// // //                             hidden
// // //                             name={name}
// // //                             accept="image/*"
// // //                             type="file"
// // //                             onChange={handleImageChange} // Update the image on file select
// // //                         />
// // //                         <PhotoCamera sx={{ color: "white" ,fontSize:"10px"}} />
// // //                     </IconButton>
// // //                 </Tooltip>
// // //             </Box>
// // //         </Box>
// // //     );
// // // };

// // // export default ProfileUpload;



// // import React, { useState } from "react";
// // import { Avatar, Box, IconButton, Tooltip } from "@mui/material";
// // import PhotoCamera from "@mui/icons-material/PhotoCamera";
// // import { RiUser6Fill } from "react-icons/ri";

// // const ProfileUpload = ({ name, setFieldValue }) => {
// //     const [selectedImage, setSelectedImage] = useState(null);

// //     const handleImageChange = (event) => {
// //         const file = event.target.files[0];
// //         if (file) {
// //             const imageUrl = URL.createObjectURL(file); // Create a temporary URL for the selected image
// //             setSelectedImage(imageUrl);
// //             setFieldValue(name, imageUrl); // Update Formik's values
// //         }
// //     };

// //     return (
// //         <Box
// //             sx={{
// //                 display: "flex",
// //                 flexDirection: "column",
// //                 alignItems: "center",
// //                 gap: 2,
// //                 mt: 4,
// //             }}
// //         >
// //             <Box
// //                 sx={{
// //                     position: "relative",
// //                     display: "inline-block",
// //                 }}
// //             >
// //                 <Avatar
// //                     sx={{
// //                         width: 70,
// //                         height: 70,
// //                         background: "black",
// //                     }}
// //                     src={selectedImage}
// //                 >
// //                     {!selectedImage && (
// //                         <Box component="span" sx={{ fontSize: 40, color: "white", background: "black" }}>
// //                             <RiUser6Fill />
// //                         </Box>
// //                     )}
// //                 </Avatar>
// //                 <Tooltip title="Upload">
// //                     <IconButton
// //                         sx={{
// //                             position: "absolute",
// //                             bottom: 0,
// //                             right: 0,
// //                             bgcolor: "#C38F51",
// //                             "&:hover": { bgcolor: "#C38F51" },
// //                         }}
// //                         color="primary"
// //                         component="label"
// //                     >
// //                         <input
// //                             hidden
// //                             name={name}
// //                             accept="image/*"
// //                             type="file"
// //                             onChange={handleImageChange}
// //                         />
// //                         <PhotoCamera sx={{ color: "white", fontSize: "10px" }} />
// //                     </IconButton>
// //                 </Tooltip>
// //             </Box>
// //         </Box>
// //     );
// // };

// // export default ProfileUpload;




// import React, { useState, useRef, useEffect } from "react";
// import { Field, ErrorMessage } from "formik";
// import { adminUrl as baseUrl } from "../../constants";
// import { useDispatch, useSelector } from "react-redux";
// import actions from "../../ReduxStore/actions/index";
// import { Avatar, Box, IconButton, Tooltip, Typography } from "@mui/material";
// import { Add as AddIcon, PhotoCamera } from "@mui/icons-material";
// import axios from "axios";
// import { RiUser6Fill } from "react-icons/ri";
// // import "./ImageBox.css"; // Import your CSS file
// // import { adminUrl as baseUrl } from "../../constants";


// const CustImageUploadInput = ({ label, name, isSubmitting, ...rest }) => {
//     const [viewImgApi, setViewImgApi] = useState(null);

//     const fileInputRef = useRef(null);



//     const handleImageChange = async (event, setFieldValue) => {
//         const file = await event.currentTarget.files[0];
//         try {
//             // Create a new FormData object
//             const formData = new FormData();

//             // Append the file to the FormData object
//             formData.append("uploadImage", file);
//             console.log(formData, "formData12121")

//             // Make a POST request to the API
//             const response = await axios.post(
//                 `${baseUrl}uploadImage`,
//                 formData,
//                 {
//                     headers: {
//                         "Content-Type": "multipart/form-data",
//                     },
//                 }
//             );

//             // Handle the response
//             const data = response.data; // This is the entire response object

//             // Now navigate to data.data.imageUrl to get the correct URL
//             if (data && data.data && data.data.imageUrl) {
//                 const imageUrl = data.data.imageUrl;
//                 setFieldValue(name, `${baseUrl}${imageUrl}`);
//                 setViewImgApi(`${baseUrl}${imageUrl}`);
//             } else {
//                 console.error("Image URL not found in response:", data);
//             }
//         } catch (error) {
//             // Handle any errors
//             console.error("Error uploading image:", error);
//         }

//         // Set the selected image in Formik's field value
//     };

//     // Reset preview and file input when the form is reset or submitting
//     useEffect(() => {
//         if (isSubmitting) {
//             setViewImgApi(null);
//             if (fileInputRef.current) {
//                 fileInputRef.current.value = ""; // Clear file input value
//             }
//         }
//     }, [isSubmitting]);



//     return (
//         <Box className="image-box">
//             {/* {viewImgApi ? (
//                 <img src={viewImgApi} className="view-img-api" />
//             ) : ( */}
//                 <>


//                     <Box
//                         sx={{
//                             display: "flex",
//                             flexDirection: "column",
//                             alignItems: "center",
//                             gap: 2,
//                             mt: 4,
//                         }}
//                     >
//                         <Box
//                             sx={{
//                                 position: "relative",
//                                 display: "inline-block",
//                             }}
//                         >
//                             <Avatar
//                                 sx={{
//                                     width: 70,
//                                     height: 70,
//                                     background: "black",
//                                 }}
//                                 src={viewImgApi}
//                             >
//                                 {!viewImgApi && (
//                                     <Box component="span" sx={{ fontSize: 40, color: "white", background: "black" }}>
//                                         <RiUser6Fill />
//                                     </Box>
//                                 )}
//                             </Avatar>
//                             <Tooltip title="Upload">
//                                 <IconButton
//                                     sx={{
//                                         position: "absolute",
//                                         bottom: 0,
//                                         right: 0,
//                                         // bgcolor: "#C38F51",
//                                         // "&:hover": { bgcolor: "#C38F51" },
//                                     }}
//                                     color="primary"
//                                     component="label"
//                                 >

//                                     < Field name={name}>
//                                         {({ field, form }) => (
//                                             <div>
//                                                 <input
//                                                     ref={fileInputRef}
//                                                     id={name}
//                                                     type="file"
//                                                     accept="image/*"
//                                                     onChange={(event) =>
//                                                         handleImageChange(event, form.setFieldValue)
//                                                     }
//                                                     className="image-box-input"
//                                                     style={{
//                                                         // position: "absolute",
//                                                         // width: "100%",
//                                                         // height: "100%",
//                                                         opacity: 0,
//                                                         cursor: "pointer",
//                                                     }}
//                                                     {...rest}
//                                                 />
//                                             </div>
//                                         )}
//                                     </Field>
//                                     {/* <input
//                                         hidden
//                                         name={name}
//                                         accept="image/*"
//                                         type="file"
//                                         onChange={handleImageChange}
//                                     /> */}
//                                     <PhotoCamera sx={{ color: "white", fontSize: "10px" }} />
//                                 </IconButton>
//                             </Tooltip>
//                         </Box>
//                     </Box>

//                     < Field name={name}>
//                         {({ field, form }) => (
//                             <div>
//                                 <input
//                                     ref={fileInputRef}
//                                     id={name}
//                                     type="file"
//                                     accept="image/*"
//                                     onChange={(event) =>
//                                         handleImageChange(event, form.setFieldValue)
//                                     }
//                                     className="image-box-input"
//                                     style={{
//                                         // position: "absolute",
//                                         // width: "100%",
//                                         // height: "100%",
//                                         opacity: 0,
//                                         cursor: "pointer",
//                                     }}
//                                     {...rest}
//                                 />
//                             </div>
//                         )}
//                     </Field>
//                     {/* <label htmlFor={name}>
//                         <Box className="image-box-button">
//                             <AddIcon sx={{ fontSize: "14px" }} />
//                         </Box>
//                     </label>
//                     <Typography variant="caption" sx={{ color: "#666" }}>
//                         {label}
//                     </Typography> */}
//                 </>

//             <ErrorMessage name={name} component="div" className="inputs-error-msg" />
//         </Box >
//     );
// };

// export default CustImageUploadInput;


import React, { useState, useRef, useEffect } from "react";
import { Field, ErrorMessage } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { Avatar, Box, IconButton, Tooltip } from "@mui/material";
import { PhotoCamera } from "@mui/icons-material";
import axios from "axios";
import { RiUser6Fill } from "react-icons/ri";
import { adminUrl as baseUrl } from "../../constants"; // Assuming the baseUrl is needed

const CustImageUploadInput = ({ label, name, isSubmitting, ...rest }) => {
    const [viewImgApi, setViewImgApi] = useState(null);
    const fileInputRef = useRef(null);

    const handleImageChange = async (event, setFieldValue) => {
        const file = event.currentTarget.files[0];
        try {
            const formData = new FormData();
            formData.append("uploadImage", file);

            const response = await axios.post(
                `${baseUrl}uploadImage`,
                formData,
                {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                }
            );

            const data = response.data;
            if (data && data.data && data.data.imageUrl) {
                const imageUrl = data.data.imageUrl;
                setFieldValue(name, `${baseUrl}${imageUrl}`);
                setViewImgApi(`${baseUrl}${imageUrl}`);
            } else {
                console.error("Image URL not found in response:", data);
            }
        } catch (error) {
            console.error("Error uploading image:", error);
        }
    };

    useEffect(() => {
        if (isSubmitting) {
            setViewImgApi(null);
            if (fileInputRef.current) {
                fileInputRef.current.value = ""; // Clear file input value
            }
        }
    }, [isSubmitting]);

    return (
        <Box className="image-box">
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    gap: 2,
                    mt: 4,
                }}
            >
                <Box sx={{ position: "relative", display: "inline-block" }}>
                    <Avatar
                        sx={{
                            width: 70,
                            height: 70,
                            background: "black",
                        }}
                        src={viewImgApi}
                    >
                        {!viewImgApi && (
                            <Box
                                component="span"
                                sx={{
                                    fontSize: 40,
                                    color: "white",
                                    background: "black",
                                }}
                            >
                                <RiUser6Fill />
                            </Box>
                        )}
                    </Avatar>
                    <Tooltip title="Upload">
                        <IconButton
                            sx={{
                                position: "absolute",
                                bottom: 0,
                                right: 0,
                                bgcolor: "#C38F51",
                                "&:hover": { bgcolor: "#C38F51" }
                            }}
                            color="primary"
                            component="label"
                        >
                            <Field name={name}>
                                {({ form }) => (
                                    <input
                                        ref={fileInputRef}
                                        id={name}
                                        type="file"
                                        accept="image/*"
                                        onChange={(event) =>
                                            handleImageChange(event, form.setFieldValue)
                                        }
                                        // className="image-box-input"

                                        style={{
                                            opacity: 0,
                                            cursor: "pointer",
                                            width: "0px",
                                            height:"0px"

                                        }}
                                        {...rest}
                                    />
                                )}
                            </Field>
                            <PhotoCamera sx={{ color: "white", fontSize: "15px" }} />
                        </IconButton>
                    </Tooltip>
                </Box>
            </Box>
            <ErrorMessage name={name} component="div" className="inputs-error-msg" />
        </Box>
    );
};

export default CustImageUploadInput;
