

/* eslint-disable no-sequences */
/* eslint-disable no-unused-vars */
/* eslint-disable no-param-reassign */
/* eslint-disable no-unused-expressions */

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { defaultReject, defaultState } from "../../../constants";
import { fetchData } from "../../../helpers";
// customerRegister
// CUSTOMERREGISTER
const GETCARTITEMCOUNT= createAsyncThunk(
  "getCartItemCount/getCartItemCount",
  // eslint-disable-next-line default-param-last
  async (
    // eslint-disable-next-line default-param-last
    payload = {},
    { rejectWithValue }
  ) => {
    try {
      const data = await fetchData(
        payload?.data,
        payload?.method,
        payload?.apiName
      );
      return {
        ...defaultState.List,
        message: data?.data.Message,
        data: data?.data?.data,
      };
    } catch (error) {
      return rejectWithValue({
        ...defaultReject.List,
        message: error.message,
      });
    }
  }
);

// PublishPostSlice
const getCartItemCountSlice = createSlice({
  name: "getCartItemCountSlice",
  initialState: {
    getCartItemCount: {
      ...defaultState.List,
      loading: false, 
      error: false, 
    },
  },
  reducers: {
    setInitialStateOfgetCartItemCount: (state) => {
        state.getCartItemCount.loading = false
        state.getCartItemCount.error = false
        state.getCartItemCount.data = []
        state.getCartItemCount.message = ''
      }
  },
  extraReducers: (builder) => {
    builder.addCase(GETCARTITEMCOUNT.fulfilled, (state, action) => {
      state.getCartItemCount = {
        ...state.getCartItemCount,
        loading: false,
        error: false,
        ...action.payload,
      };
    });
    builder.addCase(GETCARTITEMCOUNT.pending, (state, action) => {
      state.getCartItemCount = {
        ...state.getCartItemCount,
        loading: true,
        error: false,
        ...action.payload,
      };
    });
    builder.addCase(GETCARTITEMCOUNT.rejected, (state, action) => {
      state.getCartItemCount = {
        ...state.getCartItemCount,
        loading: false,
        error: true,
        ...action.payload,
      };
    });
  },
});

const getCartItemCountAction = {
    GETCARTITEMCOUNT,
};

export { getCartItemCountAction };
export default getCartItemCountSlice.reducer;
export const {setInitialStateOfgetCartItemCount} = getCartItemCountSlice.actions
