// // import logo from './logo.svg';
// // import './App.css';
// // import CustomAppBar from './Components/AppBar/CustomAppBar';
// // import Home from './Pages/Home/Home';
// // import ProsperitySteps from './Pages/ProperitySteps/ProperitySteps';
// // import OfferSection from './Pages/OfferSection/OfferSection';


// // function App() {
// //   return (
// //     <div className="App">

// //       <CustomAppBar />
// //       <Home />
// //       <ProsperitySteps />
// //       <OfferSection />
// //     </div>
// //   );
// // }

// // export default App;



// // import React from 'react';
// // import { Routes, Route } from 'react-router-dom';
// // import './App.css';
// // import CustomAppBar from './Components/AppBar/CustomAppBar';
// // import Home from './Pages/Home/Home';
// // import ProsperitySteps from './Pages/ProperitySteps/ProperitySteps';
// // import OfferSection from './Pages/OfferSection/OfferSection';

// // function App() {
// //   return (
// //     <div className="App">
// //       <div 
// //       // style={{ position: "relative" }}
// //       >
// //         <CustomAppBar />
// //       </div>
// //       <div 
// //       // style={{ position: "absolute",top:50 }}
// //       >


// //         <Routes>
// //           <Route path="/" element={<Home />} />

// //         </Routes>
// //       </div>
// //     </div>
// //   );
// // }

// // export default App;


// // 

// import React from 'react';
// import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
// import MyAppBar from './Components/AppBar/CustomAppBar'; // The AppBar component you created
// import Home from './Pages/Home/Home'; // The AppBar component you created
// import Footer from './Pages/Footer/Footer';
// import LoginComponent from './Components/LoginComponent/LoginComponent';
// import RegistrationComponent from './Components/RegistrationComponent/RegistrationComponent';
// import StakeNowHome from './Pages/StakeNow/Home/StakeNowHome';
// import ReferralHome from './Pages/Referrals/ReferralHome/ReferralHome';
// import AddToCartHome from './Pages/StakeNow/AddToCart/AddToCartHome';
// import BlogsHome from './Pages/Blogs/BlogsHome';
// import RegisterComplete from './Components/RegistrationComponent/RegisterComplete';
// import CartPageComponent from './Pages/StakeNow/AddToCart/CartPageComponent/CartPageComponent';
// import PaymentPage from './Pages/StakeNow/AddToCart/CartPageComponent/PaymentPage';
// import Customer from './Pages/Customer/Customer';

// // const Home = () => <div>Home Page</div>;
// // const About = () => <div>About Page</div>;
// // const Contact = () => <div>Contact Page</div>;
// import { createTheme, ThemeProvider } from '@mui/material/styles';

// const theme = createTheme({
//   typography: {
//     fontFamily: 'Poppins, Roboto, Arial, sans-serif',
//     h1: {
//       fontFamily: 'Poppins',
//       // fontWeight: 600,
//     },
//     h2: {
//       fontFamily: 'Poppins',
//       fontWeight: 600,
//     },
//     h3: {
//       fontFamily: 'Roboto',
//       fontWeight: 400,
//     },
//     h4: {
//       fontFamily: 'Roboto',
//       fontWeight: 400,
//     },
//     h6: {
//       fontFamily: 'Roboto',
//       fontWeight: 400,
//     },
//     body1: {
//       fontFamily: 'Roboto',
//       fontWeight: 400,
//     },
//     body2: {
//       fontFamily: 'Poppins',
//       fontWeight: "Regular",
//     },
//     button:{
//       fontFamily: 'Roboto',
//       fontWeight:"normal",fontWeight:"400"
//     }
//   },
// });
// const App = () => {
//   return (
//     // <Router>
//     <ThemeProvider theme={theme}>
//       {/* Your components */}

//       <div >
//         <div style={{ minHeight: "70vh" }}>

//           <Routes>
//             <Route path="/" element={<Home />} />
//             <Route path="/stake-now" element={<StakeNowHome />} />

//             <Route path="/referrals" element={<ReferralHome />} />

//             <Route path="/login" element={<LoginComponent />} />
//             <Route path="/register" element={<RegistrationComponent />} />
//             <Route path="/Add-to-cart" element={<AddToCartHome />} />
//             <Route path="/blogs" element={<BlogsHome />} />
//             <Route path="/registercomplete" element={<RegisterComplete />} />
//             <Route path="/addtocart" element={<CartPageComponent />} />
//             <Route path="/payment" element={<PaymentPage />} />
//             <Route path="/Customer-dashboard" element={<Customer />} />

//           </Routes>
//         </div>

//       </div>
//     </ThemeProvider>
//   );
// };

// export default App;



import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import MyAppBar from './Components/AppBar/CustomAppBar'; // The AppBar component you created
import Home from './Pages/HomeSection/Home/Home';
// import Footer from './Pages/Footer/Footer';
import LoginComponent from './Components/LoginComponent/LoginComponent';
import RegistrationComponent from './Components/RegistrationComponent/RegistrationComponent';
import StakeNowHome from './Pages/StakeNow/Home/StakeNowHome';
import ReferralHome from './Pages/Referrals/ReferralHome/ReferralHome';
import AddToCartHome from './Pages/StakeNow/AddToCart/AddToCartHome';
import BlogsHome from './Pages/Blogs/BlogsHome';
import RegisterComplete from './Components/RegistrationComponent/RegisterComplete';
import CartPageComponent from './Pages/StakeNow/AddToCart/CartPageComponent/CartPageComponent';
import PaymentPage from './Pages/StakeNow/AddToCart/CartPageComponent/PaymentPage';
import Customer from './Pages/Customer/Customer';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Footer from './Components/Footer/Footer';
import ScrollToTop from './Components/ScrollTop/ScrollToTop';
import "./App.css"
import ContactUsHome from './Pages/ContactUs/ContactUsHome';
import AboutUsHome from './Pages/AboutUs/AboutUsHome';
import { loadCartFromLocalStorage } from './ReduxStore/Slices/AddToCard/cartSlice';
import { useDispatch } from 'react-redux';
import ProfileComponent from './Components/RegistrationComponent/ProfileComponent';


// const theme = createTheme({
//   typography: {
//     fontFamily: 'Poppins, Roboto, Arial, sans-serif',
//     h1: { fontFamily: 'Poppins' },
//     h2: { fontFamily: 'Poppins', fontWeight: 600 },
//     h3: { fontFamily: 'Roboto', fontWeight: 400 },
//     h4: { fontFamily: 'Roboto', fontWeight: 400 },
//     h6: { fontFamily: 'Roboto', fontWeight: 400 },
//     body1: { fontFamily: 'Roboto', fontWeight: 400 },
//     body2: { fontFamily: 'Poppins', fontWeight: 'Regular' },
//     button: { fontFamily: 'Roboto', fontWeight: 400 },
//   },
// });


const theme = createTheme({
  typography: {
    fontFamily: "Roboto, Arial, sans-serif", // Default font for body
    h1: {
      fontFamily: "Poppins",
      fontWeight: "bold",
      fontSize: "50px",
      [`@media (max-width:600px)`]: {
        fontSize: "40px", // Larger font size for larger screens
      },
    },
    h2: {
      fontFamily: "Poppins",
      fontWeight: "medium",
      fontSize: "40px",
      [`@media (max-width:600px)`]: {
        fontSize: "30px", // Larger font size for larger screens
      },
    },
    h3: {
      fontFamily: "Poppins",
      fontWeight: "normal",
      fontSize: "30px",
      [`@media (max-width:600px)`]: {
        fontSize: "22px", // Larger font size for larger screens
      },
    },
    h6: {
      fontFamily: "Poppins",
      fontWeight: "medium",
      fontSize: "18px",
      color: "#252525",
      [`@media (max-width:600px)`]: {
        fontSize: "16px", // Larger font size for larger screens
      },
    },
    h5: {
      fontFamily: "Poppins",
      fontWeight: "medium",
      fontSize: "16px",
      color: "#252525",
      [`@media (max-width:600px)`]: {
        fontSize: "14px", // Larger font size for larger screens
      },

    },

    body1: {
      fontFamily: "Roboto",
      fontWeight: "normal",
      fontSize: "14px",
      [`@media (max-width:600px)`]: {
        fontSize: "11px", // Larger font size for larger screens
      },
    },
    body2: {
      fontFamily: "Roboto",
      fontWeight: "bold",
      fontSize: "16px",
      [`@media (max-width:600px)`]: {
        fontSize: "14px", // Larger font size for larger screens
      },
    },
    subtitle1: {
      fontFamily: "Roboto",
      fontWeight: "normal",
      fontSize: "15px",
      [`@media (max-width:600px)`]: {
        fontSize: "13px", // Larger font size for larger screens
      },
    },
    subtitle2: {
      fontFamily: "poppins",
      fontWeight: "normal",
      fontSize: "14px",
      [`@media (max-width:600px)`]: {
        fontSize: "12px", // Larger font size for larger screens
      },
    },
    button: {
      fontFamily: "Roboto", // Buttons will use Poppins
      fontWeight: "normal",
      textTransform: "uppercase", // Optional: makes text uppercase
      fontSize: "16px",
     
      [`@media (max-width:600px)`]: {
        fontSize: "12px", // Larger font size for larger screens
      },
    },


  },
  components: {
    // MuiButton: {
    //   styleOverrides: {
    //     root: {
    //       fontFamily: "Poppins", // Ensure Poppins applies to buttons
    //       fontWeight: "bold",
    //       fontSize: "0.875rem",
    //       textTransform: "uppercase", // Optional: uppercase for buttons
    //     },
    //   },
    // },
    MuiListItemText: {
      styleOverrides: {
        primary: {
          fontFamily: "Poppins", // Custom font
          fontWeight: "medium",
          fontSize: "16px",
        }
      }
    },
    MuiListItem: {
      styleOverrides: {
        primary: {
          fontFamily: "Poppins", // Custom font
          fontWeight: "medium",
          fontSize: "16px",
        }
      }
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontFamily: "Roboto", // Menu items use Roboto
          fontSize: "1rem",
          fontWeight: "normal",
        },
      },
    },
  },
});

// Wrapper component to handle layout rendering
const Layout = ({ children }) => {
  const location = useLocation();
  const hideHeaderAndFooter = location.pathname === '/login' || location.pathname === '/register' || location.pathname === '/registercomplete';

  return (
    <>
      {!hideHeaderAndFooter && <MyAppBar />}
      <div style={{ minHeight: '70vh' }}>{children}</div>
      {!hideHeaderAndFooter && <Footer />}
    </>
  );
};

const App = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadCartFromLocalStorage());
  }, [dispatch]);

  return (
    // <Router>
    <ThemeProvider theme={theme} className="content">
      <Layout >
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/stake-now" element={<StakeNowHome />} />
          <Route path="/referrals" element={<ReferralHome />} />
          <Route path="/login" element={<LoginComponent />} />
          <Route path="/register" element={<RegistrationComponent />} />

          <Route path="/profile" element={<ProfileComponent />} />

          <Route path="/Add-to-cart" element={<AddToCartHome />} />
          <Route path="/blogs" element={<BlogsHome />} />
          <Route path="/registercomplete" element={<RegisterComplete />} />
          <Route path="/addtocart" element={<CartPageComponent />} />
          <Route path="/payment" element={<PaymentPage />} />
          <Route path="/Customer-dashboard" element={<Customer />} />
          <Route path="/Contactus" element={<ContactUsHome />} />
          <Route path="about-us" element={<AboutUsHome />} />
                 
        </Routes>
      </Layout>
    </ThemeProvider>
    // </Router>
  );
};

export default App;
