
// import React, { useState } from 'react';
// import {
//     Box,
//     Card,

//     Typography,

//     Button,
//     Divider,
//     Grid,
//     RadioGroup,
//     Radio,
//     FormControlLabel,
//     Dialog,
//     DialogTitle,
//     DialogContent,
//     DialogActions,
//     IconButton,
//     Checkbox,


// } from '@mui/material';
// import { Close as CloseIcon } from '@mui/icons-material';

// // import './PaymentSummaryModal copy' // Import external CSS
// import "./BannerinputGetModal.css"
// import { MdCancel } from "react-icons/md";

// import { CiSquarePlus, CiSquareMinus } from "react-icons/ci";
// import { Formik, Form, Field } from "formik";

// import { AiFillThunderbolt } from "react-icons/ai";
// import { Link, useNavigate } from "react-router-dom";


// const ReadDocumentsModal = ({ open, onClose, setSubmittedValues, submittedValues, modalHeadingName, handleNextModal }) => {

// const documentDatas = [
//     "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.",
//     "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.",
//     "Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC.",
//     "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum. It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using ‘Content here, content here’, making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for ‘lorem ipsum’ will uncover many web sites still in their infancy.",
//     " Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like). Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature, discovered the undoubtable source. Lorem Ipsum comes from sections 1.10.32 and 1.10.33 of “de Finibus Bonorum et Malorum” (The Extremes of Good and Evil) by Cicero, written in 45 BC.",
//     " This book is a treatise on the theory of ethics, very popular during the Renaissance. The first line of Lorem Ipsum, “Lorem ipsum dolor sit amet..”, comes from a line in section 1.10.32. The standard chunk of Lorem Ipsum used since the 1500s is reproduced below for those interested. Sections 1.10.32 and 1.10.33 from “de Finibus Bonorum et Malorum” by Cicero are also reproduced in their exact original form, accompanied by English versions from the 1914 translation by H. Rackham. Where can I get some? There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don’t look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn’t anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable. The generated Lorem Ipsum is therefore always free from repetition, injected humour, or non-characteristic words etc."
// ]


//     const navigate = useNavigate()


//     return (
//         <Dialog
//             open={open}
//             onClose={onClose}
//             fullWidth
//             maxWidth="md"
//             className="custom-dialog"
//             sx={{
//                 "& .MuiPaper-root": {
//                     maxWidth: "800px",
//                     borderRadius: "14px", // Target the Paper component
//                 },
//             }}
//         >
//             <Box sx={{ position: 'relative' }}>
//                 <Box className="dialog-heading">
//                     <IconButton
//                         aria-label="Close"
//                         onClick={onClose}
//                         sx={{ position: 'absolute', top: 8, right: 8, color: 'text.secondary' }}
//                     >
//                         <MdCancel />
//                     </IconButton>
//                     <DialogTitle className="custom-dialog-title">
//                         <Box>
//                             <Typography className="custom-title-text" color='white'>
//                                 {/* {modalHeadingName} */}
//                                 Payment Summary
//                             </Typography>
//                         </Box>
//                     </DialogTitle>
//                 </Box>

//                 <DialogContent
//                     dividers
//                     sx={{
//                         padding: 0, // Removes the padding completely
//                         '& .MuiDialogContent-root': {
//                             padding: 0, // Ensures no padding inside DialogContent
//                         },
//                     }}
//                 >
//                     <>
//                         {/* <MyAppBar /> */}

//                         <Grid
//                             container
//                             spacing={2}
//                             sx={{ p: { xs: 2, md: 4 }, flexDirection: { xs: "column", md: "row" } }}
//                         >
//                             {/* Product Table */}

//                             {/* Order Summary */}

//                             {documentDatas.map((data, index) => (
//                                 <Grid item xs={12} >
//                                     <Typography variant='body1' key={index}>{data}</Typography>
//                                 </Grid>

//                             ))}
//                             <Box>
//                                 <Typography component="div">
//                                     <Field
//                                         as={FormControlLabel}
//                                         name="privacyChecked"
//                                         control={
//                                             <Checkbox
//                                                 sx={{
//                                                     color: "#3B4056",
//                                                     "&.Mui-checked": {
//                                                         color: "#C38F51",
//                                                     },
//                                                 }}
//                                             />
//                                         }
//                                         label={
//                                             <Typography variant="body1">
//                                                 You have read the{" "}
//                                                 <span style={{ color: "red" }}>
//                                                     <Link href="#" underline="hover" onClick={handleDocumentOpenModal} style={{ color: '#C38F51' }}>purchase documents</Link>
//                                                 </span>{" "}
//                                                 and other documents carefully.
//                                             </Typography>
//                                         }
//                                     />
//                                 </Typography>

//                             </Box>
//                         </Grid>

//                         {/* <Footer /> */}
//                     </>
//                 </DialogContent>
//             </Box>
//         </Dialog >
//     );
// };

// export default ReadDocumentsModal;


import React, { useState } from 'react';
import {
    Box,
    Card,
    Typography,
    Button,
    Divider,
    Grid,
    RadioGroup,
    Radio,
    FormControlLabel,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    IconButton,
    Checkbox,
} from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import { MdCancel } from "react-icons/md";
import { CiSquarePlus, CiSquareMinus } from "react-icons/ci";
import { Formik, Form, Field } from "formik";
import { AiFillThunderbolt } from "react-icons/ai";
import { Link, useNavigate } from "react-router-dom";
import "./BannerinputGetModal.css";

const ReadDocumentsModal = ({ open, onClose, setSubmittedValues, submittedValues, modalHeadingName, handleNextModal }) => {

    const documentDatas = [
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.",
        "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.",
        "Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC.",
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum. It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using ‘Content here, content here’, making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for ‘lorem ipsum’ will uncover many web sites still in their infancy.",
        " Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like). Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature, discovered the undoubtable source. Lorem Ipsum comes from sections 1.10.32 and 1.10.33 of “de Finibus Bonorum et Malorum” (The Extremes of Good and Evil) by Cicero, written in 45 BC.",
        " This book is a treatise on the theory of ethics, very popular during the Renaissance. The first line of Lorem Ipsum, “Lorem ipsum dolor sit amet..”, comes from a line in section 1.10.32. The standard chunk of Lorem Ipsum used since the 1500s is reproduced below for those interested. Sections 1.10.32 and 1.10.33 from “de Finibus Bonorum et Malorum” by Cicero are also reproduced in their exact original form, accompanied by English versions from the 1914 translation by H. Rackham. Where can I get some? There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don’t look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn’t anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable. The generated Lorem Ipsum is therefore always free from repetition, injected humour, or non-characteristic words etc."
    ]

    const navigate = useNavigate();

    return (
        <Dialog
            open={open}
            onClose={onClose}
            fullWidth
            maxWidth="md"
            className="custom-dialog"
            sx={{
                "& .MuiPaper-root": {
                    maxWidth: "800px",
                    borderRadius: "14px",
                },
            }}
        >
            <Box sx={{ position: 'relative' }}>
                <Box className="dialog-heading">
                    <IconButton
                        aria-label="Close"
                        onClick={onClose}
                        sx={{ position: 'absolute', top: 8, right: 8, color: 'text.secondary' }}
                    >
                        <MdCancel />
                    </IconButton>
                    <DialogTitle className="custom-dialog-title">
                        <Box>
                            <Typography className="custom-title-text" color='white'>
                                Purchase Document

                            </Typography>
                        </Box>
                    </DialogTitle>
                </Box>

                <DialogContent
                    dividers
                    sx={{
                        padding: 0,
                        '& .MuiDialogContent-root': {
                            padding: 0,
                        },
                        color: "#7A7A7A",
                        p: { xs: 2, md: "16px 24px" }
                    }}
                // sx={{ flexDirection: { xs: "column", md: "row" } }}

                >
                    <Grid item sx={{ display: "flex", justifyContent: "center" }}>
                        <Typography variant='h3' color="black" textAlign="center">
                            Purchase Document
                        </Typography>
                    </Grid>
                    <Formik
                        initialValues={{ privacyChecked: false }}
                        onSubmit={(values) => {
                            // handle form submission
                            setSubmittedValues(values);
                            onClose()
                        }}
                    >
                        {({ values, handleChange }) => (
                            <Form>

                                <Grid
                                    container
                                    spacing={2}
                                    mt="4px"
                                >

                                    {documentDatas.map((data, index) => (
                                        <Grid item xs={12} key={index}>
                                            <Typography variant='body1'>{data}</Typography>
                                        </Grid>
                                    ))}

                                    <Box sx={{ p: 1 }}>
                                        <Typography component="div">
                                            <Field
                                                as={FormControlLabel}
                                                name="privacyChecked"
                                                control={
                                                    <Checkbox
                                                        sx={{
                                                            color: "#3B4056",
                                                            "&.Mui-checked": {
                                                                color: "#C38F51",
                                                            },
                                                        }}
                                                        checked={values.privacyChecked}
                                                        onChange={handleChange}
                                                    />
                                                }
                                                label={
                                                    <Typography variant="body1">
                                                        You have read the
                                                        purchase documents

                                                        and other documents carefully.
                                                    </Typography>
                                                }
                                            />
                                        </Typography>
                                    </Box>


                                    <Grid xs={12}>
                                        <Grid
                                            container
                                            justifyContent="center"
                                            alignItems="center"
                                            spacing={2} // Add spacing between buttons
                                        >
                                            <Grid item>
                                                <Button
                                                    // onClick={
                                                    //     // onClose()
                                                    // }
                                                    variant="contained"
                                                    sx={{
                                                        textTransform: "none",
                                                        color: "white",
                                                        fontSize: "16px",
                                                        padding: { xs: "0px 20px", md: "0px 40px" },
                                                        minHeight: "40px",
                                                        borderRadius: "23px",
                                                        background: "white",
                                                        boxShadow: "none",
                                                        color: "black",
                                                        border: "1px solid black",
                                                        "&:hover": {
                                                            backgroundColor: "#3B4056",
                                                            color: "white",
                                                        },
                                                    }}
                                                >
                                                    Cancel
                                                </Button>
                                            </Grid>
                                            <Grid item>
                                                <Button
                                                    type="submit"
                                                    variant="contained"
                                                    sx={{
                                                        textTransform: "none",
                                                        color: "white",
                                                        fontSize: "16px",
                                                        boxShadow: "none",
                                                        padding: { xs: "0px 20px", md: "0px 40px" },
                                                        minHeight: "40px",
                                                        borderRadius: "23px",
                                                        background: "#C38F51 0% 0% no-repeat padding-box",
                                                        "&:hover": {
                                                            backgroundColor: "#3B4056",
                                                            boxShadow: "none",
                                                        },
                                                    }}
                                                    disabled={!values.privacyChecked} // Disable button if checkbox is not checked
                                                >
                                                    Submit
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                </Grid>
                            </Form>
                        )}
                    </Formik>
                </DialogContent>
            </Box>
        </Dialog >
    );
};

export default ReadDocumentsModal;
