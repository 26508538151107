



import React from "react";
import { Box, Typography, Grid, Avatar } from "@mui/material";
import StorefrontIcon from "@mui/icons-material/Storefront";
import { styled } from "@mui/system";
import { adminUrl } from "../../constants";


const ProsperityCard = ({ icon, title, description }) => {
  return (
    <Box textAlign="center">
      <Avatar
        sx={{
          bgcolor: "#D49C63",
          width: 80,
          height: 80,
          margin: "0 auto",
        }}
      >
        <img src={`${adminUrl}/${icon}`} alt="logo" 
        style={{ width: "50%", height: "50%" }}
         />

      </Avatar>
      <Typography variant="h6" sx={{ mt: 2 }}>
        {title}
      </Typography>
      <Typography variant="body1" sx={{ mt: 1, color: "gray", textAlign: "center" }}>
        {description}
      </Typography>
    </Box>
  );
}

export default ProsperityCard;