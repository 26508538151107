


// // import React from "react";
// // import { Box, Typography, Button, Card, CardContent } from "@mui/material";
// // import LatestNewsImage from "../../Assets/latestNewsImage.jpg";

// // const LatestNewsSection = () => {
// //     return (
// //         <Box sx={{ padding: 4, backgroundColor: "#f9f9f9", minHeight: "90vh" }}>
// //             {/* Header Section */}
// //             <Box sx={{ textAlign: "center", marginBottom: 4 }}>
// //                 <Typography
// //                     variant="h4"
// //                     sx={{
// //                         fontSize: { xs: 24, md: 32 },
// //                         marginBottom: 1,
// //                     }}
// //                 >
// //                     Stay Connected{" "}
// //                     <span style={{ color: "black", fontWeight: "700" }}>
// //                         Discover Opportunities
// //                     </span>
// //                 </Typography>
// //                 <Typography
// //                     variant="body2"
// //                     sx={{
// //                         maxWidth: "800px",
// //                         margin: "0 auto",
// //                         color: "#555",
// //                         fontSize: { xs: 14, md: 16 },
// //                         lineHeight: 1.6,
// //                     }}
// //                 >
// //                     Lorem Ipsum is simply dummy text of the printing and typesetting
// //                     industry. Lorem Ipsum has been the industry’s standard dummy text ever
// //                     since the 1500s, when an unknown printer took a galley of type and
// //                     scrambled it to make a type specimen book.
// //                 </Typography>
// //             </Box>

// //             {/* Image and Card Section */}
// //             <Box
// //                 sx={{
// //                     position: "relative",
// //                     maxWidth: "100%",
// //                     margin: "0 auto",
// //                     px: { xs: 2, md: 4 },
// //                     pb: 6,
// //                 }}
// //             >
// //                 {/* Image */}
// //                 <Box
// //                     component="img"
// //                     src={LatestNewsImage}
// //                     alt="Blog Preview"
// //                     sx={{
// //                         maxWidth: "100%",
// //                         borderRadius: "12px",
// //                         height: { xs: "300px", sm: "400px", md: "370px" },
// //                         objectFit: "cover",
// //                     }}
// //                 />

// //                 {/* Card */}
// //                 <Card
// //                     sx={{
// //                         position: "absolute",
// //                         // top: "50%",
// //                         top: { xs: "80%", sm: "50%" },
// //                         top: { xs: "30%", sm: "30%" },
// //                         //                         left: { xs: "15%", sm: "30%" },
// //                         left: "50%",
// //                         transform: "translate(-50%, -50%)",
// //                         width: { xs: "60%", sm: "60%" },
// //                         borderRadius: 2,
// //                         boxShadow: "0px 8px 20px rgba(0,0,0,0.15)",
// //                         backgroundColor: "#fff",
// //                         zIndex: 10,
// //                         padding: 3,
// //                     }}
// //                 >
// //                     <CardContent>
// //                         <Typography
// //                             variant="subtitle1"
// //                             sx={{ color: "#FF5733", fontWeight: "bold", marginBottom: 1 }}
// //                         >
// //                             Stay Connected
// //                         </Typography>
// //                         <Typography
// //                             variant="h6"
// //                             sx={{
// //                                 fontWeight: "bold",
// //                                 marginBottom: 2,
// //                                 color: "#333",
// //                                 fontSize: { xs: 18, md: 24 },
// //                             }}
// //                         >
// //                             Discover the Golden Opportunity
// //                         </Typography>
// //                         <Typography
// //                             variant="body2"
// //                             sx={{
// //                                 color: "#666",
// //                                 textAlign: "justify",
// //                                 marginBottom: 2,
// //                                 fontSize: { xs: 12, md: 14 },
// //                                 lineHeight: 1.6,
// //                             }}
// //                         >
// //                             Lorem Ipsum is simply dummy text of the printing and typesetting
// //                             industry. Lorem Ipsum has been the industry's standard dummy text
// //                             ever since the 1500s, when an unknown printer took a galley of
// //                             type and scrambled it to make a type specimen book.
// //                         </Typography>
// //                         <Button
// //                             variant="contained"
// //                             color="warning"
// //                             sx={{
// //                                 textTransform: "none",
// //                                 fontWeight: "bold",
// //                                 borderRadius: 20,
// //                                 paddingX: 3,
// //                                 fontSize: { xs: 12, md: 14 },
// //                             }}
// //                         >
// //                             Let&apos;s Explore...
// //                         </Button>
// //                     </CardContent>
// //                 </Card>
// //             </Box>
// //         </Box>
// //     );
// // };

// // export default LatestNewsSection;





// import React from "react";
// import { Box, Typography, Button, Card, CardContent } from "@mui/material";
// import LatestNewsImage from "../../Assets/latestNewsImage.jpg";

// const LatestNewsSection = () => {
//     return (
//         <Box sx={{ padding: 4, backgroundColor: "#f9f9f9", minHeight: "70vh" }}>
//             {/* Header Section */}
//             <Box sx={{ textAlign: "center", marginBottom: 4 }}>
//                 <Typography
//                     variant="h4"
//                     sx={{
//                         fontSize: { xs: 24, md: 32 },
//                         marginBottom: 1,
//                     }}
//                 >
//                     Latest News &{" "}
//                     <span style={{ color: "black", fontWeight: "700" }}>
//                         Blogs
//                     </span>
//                 </Typography>
//                 <Typography
//                     variant="body2"
//                     sx={{
//                         maxWidth: "800px",
//                         margin: "0 auto",
//                         color: "#555",
//                         fontSize: { xs: 14, md: 16 },
//                         lineHeight: 1.6,
//                     }}
//                 >
//                     Lorem Ipsum is simply dummy text of the printing and typesetting
//                     industry. Lorem Ipsum has been the industry’s standard dummy text ever
//                     since the 1500s, when an unknown printer took a galley of type and
//                     scrambled it to make a type specimen book.
//                 </Typography>
//             </Box>

//             {/* Image and Card Section */}
//             <Box
//                 sx={{
//                     position: "relative",
//                     maxWidth: "100%",
//                     margin: "0 auto",
//                     // px: { xs: 2, md: 4 },
//                     // height:{sm:"400px"},
//                     pb: 6,
//                 }}
//             >
//                 {/* Image */}
//                 <Box
//                     component="img"
//                     src={LatestNewsImage}
//                     alt="Blog Preview"
//                     sx={{
//                         // width: "100%",
//                         maxWidth: { xs: "100%", sm: "70%", md: "100%" },

//                         borderRadius: "12px",
//                         height: { xs: "300px", sm: "300px", md: "350px" },
//                         objectFit: "cover",
//                     }}
//                 />

//                 {/* Card */}
//                 <Card
//                     sx={{
//                         position: { xs: "absolute", sm: "absolute" },
//                         top: { xs: "10%", sm: "30%" },
//                         left: { xs: "9%", sm: "30%" },
//                         marginTop: { xs: 2, sm: "unset" },
//                         width: { xs: "80%", sm: "50%" },
//                         borderRadius: 2,
//                         boxShadow: "0px 8px 20px rgba(0,0,0,0.15)",
//                         backgroundColor: "#fff",
//                         zIndex: 10,
//                         px: 1,
//                     }}
//                 >
//                     <CardContent>
//                         <Typography
//                             variant="subtitle1"
//                             sx={{ color: "#C38F51", fontSize: "14px", marginBottom: 0.5 }}
//                         >
//                             Stay Connected
//                         </Typography>
//                         <Typography
//                             variant="h6"
//                             sx={{
//                                 fontWeight: "bold",
//                                 marginBottom: 1,
//                                 color: "#333",
//                                 fontSize: { xs: 18, md: 24 },
//                             }}
//                         >
//                             Discover the Golden Opportunity
//                         </Typography>
//                         <Typography
//                             variant="body2"
//                             sx={{
//                                 color: "#666",
//                                 textAlign: "justify",
//                                 marginBottom: 2,
//                                 fontSize: { xs: 12, md: 14 },
//                                 lineHeight: 1.6,
//                                 height: { xs: "100px", md: "150px" },
//                                 overflow: "hidden"


//                             }}
//                         >
//                             Lorem Ipsum is simply dummy text of the printing and typesetting
//                             industry. Lorem Ipsum has been the industry's standard dummy text
//                             ever since the 1500s, when an unknown printer took a galley of
//                             type and scrambled it to make a type specimen book Lorem Ipsum is simply dummy text of the printing and typesetting
//                             industry. Lorem Ipsum has been the industry's standard dummy text
//                             ever since the 1500s, when an unknown printer took a galley of
//                             type and scrambled it to make a type specimen book..
//                         </Typography>
//                         <Box sx={{
//                             display: "flex",
//                             justifyContent: "end",
//                         }}>
//                             <Button
//                                 variant="contained"
//                                 color="warning"
//                                 sx={{
//                                     textTransform: "none",
//                                     fontWeight: "bold",

//                                     background: "#C38F51",
//                                     borderRadius: 20,
//                                     paddingX: 3,
//                                     fontSize: { xs: 12, md: 14 },
//                                 }}
//                             >
//                                 Let&apos;s Explore...
//                             </Button>
//                         </Box>
//                     </CardContent>
//                 </Card>
//             </Box>
//         </Box>
//     );
// };

// export default LatestNewsSection;







// import React from "react";
// import { Box, Typography, Button, Card, CardContent } from "@mui/material";
// import LatestNewsImage from "../../Assets/latestNewsImage.jpg";

// const LatestNewsSection = () => {
//     return (
//         <Box sx={{ padding: 4, backgroundColor: "#f9f9f9", minHeight: "90vh" }}>
//             {/* Header Section */}
//             <Box sx={{ textAlign: "center", marginBottom: 4 }}>
//                 <Typography
//                     variant="h4"
//                     sx={{
//                         fontSize: { xs: 24, md: 32 },
//                         marginBottom: 1,
//                     }}
//                 >
//                     Stay Connected{" "}
//                     <span style={{ color: "black", fontWeight: "700" }}>
//                         Discover Opportunities
//                     </span>
//                 </Typography>
//                 <Typography
//                     variant="body2"
//                     sx={{
//                         maxWidth: "800px",
//                         margin: "0 auto",
//                         color: "#555",
//                         fontSize: { xs: 14, md: 16 },
//                         lineHeight: 1.6,
//                     }}
//                 >
//                     Lorem Ipsum is simply dummy text of the printing and typesetting
//                     industry. Lorem Ipsum has been the industry’s standard dummy text ever
//                     since the 1500s, when an unknown printer took a galley of type and
//                     scrambled it to make a type specimen book.
//                 </Typography>
//             </Box>

//             {/* Image and Card Section */}
//             <Box
//                 sx={{
//                     position: "relative",
//                     maxWidth: "100%",
//                     margin: "0 auto",
//                     px: { xs: 2, md: 4 },
//                     pb: 6,
//                 }}
//             >
//                 {/* Image */}
//                 <Box
//                     component="img"
//                     src={LatestNewsImage}
//                     alt="Blog Preview"
//                     sx={{
//                         maxWidth: "100%",
//                         borderRadius: "12px",
//                         height: { xs: "300px", sm: "400px", md: "370px" },
//                         objectFit: "cover",
//                     }}
//                 />

//                 {/* Card */}
//                 <Card
//                     sx={{
//                         position: "absolute",
//                         // top: "50%",
//                         top: { xs: "80%", sm: "50%" },
//                         top: { xs: "30%", sm: "30%" },
//                         //                         left: { xs: "15%", sm: "30%" },
//                         left: "50%",
//                         transform: "translate(-50%, -50%)",
//                         width: { xs: "60%", sm: "60%" },
//                         borderRadius: 2,
//                         boxShadow: "0px 8px 20px rgba(0,0,0,0.15)",
//                         backgroundColor: "#fff",
//                         zIndex: 10,
//                         padding: 3,
//                     }}
//                 >
//                     <CardContent>
//                         <Typography
//                             variant="subtitle1"
//                             sx={{ color: "#FF5733", fontWeight: "bold", marginBottom: 1 }}
//                         >
//                             Stay Connected
//                         </Typography>
//                         <Typography
//                             variant="h6"
//                             sx={{
//                                 fontWeight: "bold",
//                                 marginBottom: 2,
//                                 color: "#333",
//                                 fontSize: { xs: 18, md: 24 },
//                             }}
//                         >
//                             Discover the Golden Opportunity
//                         </Typography>
//                         <Typography
//                             variant="body2"
//                             sx={{
//                                 color: "#666",
//                                 textAlign: "justify",
//                                 marginBottom: 2,
//                                 fontSize: { xs: 12, md: 14 },
//                                 lineHeight: 1.6,
//                             }}
//                         >
//                             Lorem Ipsum is simply dummy text of the printing and typesetting
//                             industry. Lorem Ipsum has been the industry's standard dummy text
//                             ever since the 1500s, when an unknown printer took a galley of
//                             type and scrambled it to make a type specimen book.
//                         </Typography>
//                         <Button
//                             variant="contained"
//                             color="warning"
//                             sx={{
//                                 textTransform: "none",
//                                 fontWeight: "bold",
//                                 borderRadius: 20,
//                                 paddingX: 3,
//                                 fontSize: { xs: 12, md: 14 },
//                             }}
//                         >
//                             Let&apos;s Explore...
//                         </Button>
//                     </CardContent>
//                 </Card>
//             </Box>
//         </Box>
//     );
// };

// export default LatestNewsSection;





import React, { useEffect, useState } from "react";
import { Box, Typography, Button, Card, CardContent } from "@mui/material";
import LatestNewsImage from "../../Assets/latestNewsImage.jpg";
import { adminUrl } from "../../constants";


const LatestNewsSection = ({ latestDates, handleReadMoreOpenModal, setReadMoreModalContent }) => {

    const [modalData,setModalData]=useState()
    useEffect(() => {
        setModalData(
            {
                title:latestDates?.[0]?.additional_data?.card_design?.title,
                secondary_title: latestDates?.[0]?.additional_data?.card_design?.secondary_title,
                image: latestDates?.[0]?.additional_data?.card_design?.banner,
                textContent: latestDates?.[0]?.additional_data?.card_design?.content


            }
        )
        
    }, [])

    return (
        <Box sx={{
            padding: 4, backgroundColor: "#f9f9f9"
            // , minHeight: "70vh"
        }}>
            {/* Header Section */}
            <Box sx={{ textAlign: "center", marginBottom: 4 }}>
                <Typography
                    variant="h3"
                    sx={{

                        marginBottom: 1,
                    }}
                >
                    {latestDates?.[0]?.heading || "Default Heading"}
                    <span style={{ color: "black", fontWeight: "700" }}>
                        {latestDates?.[0]?.sub_heading || "Default Sub-heading"}
                    </span>
                </Typography>
                <Typography
                    variant="body1"
                    sx={{
                        maxWidth: "800px",
                        margin: "0 auto",
                        color: "#555",

                        lineHeight: 1.6,
                    }}
                >
                    {latestDates?.[0]?.content || "Default Content"}

                </Typography>
            </Box>

            {/* Image and Card Section */}
            <Box
                sx={{
                    position: "relative",
                    maxWidth: "100%",
                    margin: "0 auto",
                    pb: 6,
                }}
            >
                {/* Image */}
                <Box
                    component="img"
                    src={`${adminUrl}/${latestDates?.[0]?.additional_data?.card_design?.banner}`}
                    alt="Blog Preview"
                    sx={{
                        width: "50%", // Ensure the image takes full width of its container
                        width: { xs: "100%", sm: "60%", md: "50%" }, // Restrict max width

                        maxWidth: { xs: "100%", sm: "70%", md: "100%" }, // Restrict max width
                        height: "auto", // Maintain aspect ratio
                        maxHeight: "350px", // Custom max height for the image
                        borderRadius: "12px",
                        objectFit: "cover", // Prevent stretching or distortion
                    }}
                />

                {/* Card */}
                <Card
                    sx={{
                        position: "absolute",
                        top: { xs: "10%", sm: "30%", md: "20%" },
                        left: { xs: "9%", sm: "30%", md: "40%" },
                        width: { xs: "80%", sm: "65%", md: "55%" },
                        borderRadius: 2,
                        boxShadow: "0px 8px 20px rgba(0,0,0,0.15)",
                        backgroundColor: "#fff",
                        zIndex: 10,
                        px: 1,
                    }}
                >
                    <CardContent>
                        <Typography
                            variant="body1"
                            sx={{ color: "#C38F51", marginBottom: 0.5 }}
                        >
                            {latestDates?.[0]?.additional_data?.card_design?.title}
                        </Typography>
                        <Typography
                            variant="h6"
                            sx={{
                                // fontWeight: "bold",
                                marginBottom: 1,
                                color: "#333",
                                // fontSize: { xs: 18, md: 24 },
                            }}
                        >
                            {latestDates?.[0]?.additional_data?.card_design?.secondary_title}
                        </Typography>
                        <Typography
                            variant="body1"
                            sx={{
                                color: "#666",
                                textAlign: "justify",
                                marginBottom: 2,
                                // fontSize: { xs: 12, md: 14 },
                                lineHeight: 1.6,
                                height: { xs: "100px", md: "160px" }, // Set maximum text height
                                overflow: "hidden", // Hide overflow text
                            }}
                        >
                            {latestDates?.[0]?.additional_data?.card_design?.content}

                        </Typography>
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "end",
                            }}
                        >
                            <Button
                                onClick={()=>{handleReadMoreOpenModal(modalData)}}
                                variant="contained"
                                color="warning"
                                sx={{
                                    textTransform: "none",
                                    // fontWeight: "bold",
                                    background: "#C38F51",
                                    borderRadius: 20,
                                    px: 3,
                                    // fontSize: { xs: 12, md: 14 },
                                }}
                            >
                                {latestDates?.[0]?.additional_data?.card_design?.primary_button_text}

                            </Button>
                        </Box>
                    </CardContent>
                </Card>
            </Box>

        </Box>
    );
};

export default LatestNewsSection;


