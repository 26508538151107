


import React, { useState } from "react";
import {
    Box,
    Card,
    CardMedia,
    Typography,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    IconButton,
    Button,
    Divider,
    Grid,
    RadioGroup,
    Radio,
    FormControlLabel,
    Checkbox,
} from "@mui/material";
import cardImage2 from "../../../../Assets/home_page_Image.png";
import cardImage1 from "../../../../Assets/sheme_small_image.png";
import { CiSquarePlus, CiSquareMinus } from "react-icons/ci";
import { Formik, Form, Field } from "formik";
import actions from "../../../../ReduxStore/actions/index";
import { AiFillThunderbolt } from "react-icons/ai";
import { Link, useNavigate } from "react-router-dom";
import ReadDocumentsModal from "./Modal/ReadDocumentsModal";
import { useSelector, useDispatch } from "react-redux";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const PaymentPage = () => {

    const sessioncustomer_id = sessionStorage.getItem("sessioncustomer_id");
    const sessionFirstName = sessionStorage.getItem("sessionFirstName");

    console.log(typeof sessioncustomer_id, "sessioncustomer_idsessioncustomer_id");


    const cartItems = useSelector((state) => state.cart.cart);
    const dispatch = useDispatch();
    // const [cartItems, setCartItems] = useState([
    //     { id: 1, name: "Scheme 1", price: 1000, quantity: 1, image: cardImage1 },
    //     { id: 2, name: "Scheme 2", price: 10000, quantity: 1, image: cardImage2 },
    // ]);

    const [isDocumentModalOpen, setDocumentModalOpen] = useState(false);

    const handleDocumentOpenModal = () => {
        setDocumentModalOpen(true);
    };

    const handleDocumentCloseModal = () => {
        setDocumentModalOpen(false);
    };



    // const handleQuantityChange = (id, delta) => {
    //     setCartItems((prevItems) =>
    //         prevItems.map((item) =>
    //             item.id === id
    //                 ? { ...item, quantity: Math.max(1, item.quantity + delta) }
    //                 : item
    //         )
    //     );
    // };

    const subtotal = cartItems.reduce(
        (total, item) => total + item.value_of_purchase * item.quantity,
        0
    );

    const initialValues = {
        paymentMethod: "Cash", // Default selected payment method
    };

    const navigate = useNavigate()


    const { addMultiPurchase } = useSelector((state) => state?.addMultiPurchase);

    console.log(addMultiPurchase);


    const handleSubmit = async (values) => {
        console.log("Selected Payment Method:", values.paymentMethod);

        const transformedCartItems = cartItems.map(item => ({
            plan_id: item.plan_id, // Adjust plan_id offset
            purchase_time: item.quantity // Map quantity to purchase_time
        }));

        const data1 = {
            data: {
                customer_id: sessioncustomer_id,
                purchase_contract_accepted: true,
                cart_items: transformedCartItems
            }, method: "post", apiName: "addMultiPurchase"
        };

        const addMultiPurchaseResponse = await dispatch(actions.ADDMULTIPURCHASE(data1));

        //    console.log(addMultiPurchaseResponse.payload.message ==="Purchase added successfully.","addMultiPurchaseResponse");


        if (addMultiPurchaseResponse.payload.message === "Purchase added successfully.") {

            // Clear the entire localStorage
            localStorage.clear();

            // Or if you only want to remove the 'cart' item specifically:
            localStorage.removeItem('cart');

            toast.success(`${addMultiPurchaseResponse.payload.message}`);
            setTimeout(() => {
                navigate("/Customer-dashboard");
            }, 2500);

        }


    };

    return (
        <>
            {/* <MyAppBar /> */}

            <Grid
                container
                spacing={2}
                sx={{ p: { xs: 2, md: 4 }, flexDirection: { xs: "column", md: "row" } }}
            >
                {/* Product Table */}

                {/* Order Summary */}
                <Grid item xs={12} md={8}>
                    <Card
                        sx={{
                            p: 2,
                            background: "#F7F7F7",
                            border: "none",
                            boxShadow: "none",
                        }}
                    >
                        <Typography
                            variant="h5"
                            // gutterBottom
                            sx={{
                                color: "#252525",
                                // fontWeight: 700,
                                // fontSize: "16px",
                            }}
                        >
                            Order Summary
                        </Typography>
                        {/* <Divider /> */}
                        {cartItems.map((item) => (
                            <Box
                                key={item.plan_id}
                                sx={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    mt: 2,
                                }}
                            >
                                <Typography
                                    variant="body1"
                                    sx={{
                                        // fontSize: "14px",
                                        color: "#7A7A7A",
                                    }}
                                >
                                    {item.quantity}x {item.title}
                                </Typography>
                                <Typography
                                    variant="body1"
                                    sx={{
                                        // fontSize: "14px",
                                        color: "#7A7A7A",
                                    }}
                                >
                                    ${item.value_of_purchase * item.quantity}
                                </Typography>
                            </Box>
                        ))}
                        <Divider sx={{ my: 2 }} />
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "space-between",
                            }}
                        >
                            <Typography
                                variant="h5"
                            // sx={{ fontWeight: 700, fontSize: "16px" }}
                            >
                                Sub Total
                            </Typography>
                            <Typography
                                variant="h5"
                                sx={{
                                    // fontSize: "16px",
                                    color: "#C38F51",
                                }}
                            >
                                ${subtotal}
                            </Typography>
                        </Box>

                    </Card>
                </Grid>
                <Grid item xs={12} md={4}>
                    <Card
                        sx={{
                            p: 2,
                            background: "#F7F7F7",
                            border: "none",
                            boxShadow: "none",
                        }}
                    >
                        <Typography
                            variant="h5"
                            // gutterBottom
                            sx={{
                                color: "#252525",
                                // fontWeight: 700,
                                // fontSize: "16px",
                            }}
                        >
                            Payment Type
                        </Typography>




                        <Formik
                            // initialValues={initialValues} 
                            initialValues={{ paymentMethod: "cash", privacyChecked: false }}

                            onSubmit={handleSubmit}>
                            {({ values }) => (
                                <Form>
                                    <Box mt={2}>
                                        <RadioGroup name="paymentMethod">
                                            <Field
                                                as={FormControlLabel}
                                                type="radio"
                                                name="paymentMethod"
                                                value="cash"
                                                color="#3B4056"
                                                control={<Radio sx={{
                                                    color: "#3B4056",
                                                    '&.Mui-checked': {
                                                        color: "#C38F51",
                                                    },
                                                }} />}
                                                label="Cash"
                                            />
                                            <Field
                                                as={FormControlLabel}
                                                type="radio"
                                                name="paymentMethod"
                                                value="Payment Gateway"
                                                // control={<Radio color=""/>}
                                                control={<Radio sx={{
                                                    color: "#3B4056",
                                                    '&.Mui-checked': {
                                                        color: "#C38F51",
                                                    },
                                                }} />}
                                                label="Payment Gateway"
                                            />
                                        </RadioGroup>
                                    </Box>
                                    <Box mt={2}>
                                        <Typography component="div">
                                            <Field
                                                as={FormControlLabel}
                                                name="privacyChecked"
                                                control={
                                                    <Checkbox
                                                        sx={{
                                                            color: "#3B4056",
                                                            "&.Mui-checked": {
                                                                color: "#C38F51",
                                                            },
                                                        }}
                                                    />
                                                }
                                                label={
                                                    <Typography variant="body1">
                                                        You have read the{" "}
                                                        <span style={{ color: "red" }}>
                                                            <Link href="#" underline="hover" onClick={handleDocumentOpenModal} style={{ color: '#C38F51' }}>purchase documents</Link>
                                                        </span>{" "}
                                                        and other documents carefully.
                                                    </Typography>
                                                }
                                            />
                                        </Typography>

                                    </Box>
                                    <Box
                                        sx={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                        }}
                                    >
                                        <Button
                                            type="submit"
                                            variant="contained"
                                            startIcon={<AiFillThunderbolt />}
                                            sx={{
                                                textTransform: "none",
                                                color: "white",
                                                // fontSize: "16px",
                                                padding: "7px 20px",
                                                mt: 2,
                                                minHeight: "30px",
                                                borderRadius: "23px",
                                                background:
                                                    "#C38F51 0% 0% no-repeat padding-box",
                                            }}
                                            disabled={!values.privacyChecked} // Disable button if checkbox is not checked

                                        >
                                            Pay${subtotal}
                                        </Button>
                                    </Box>
                                </Form>
                            )}
                        </Formik>
                    </Card>
                    <ReadDocumentsModal open={isDocumentModalOpen} onClose={handleDocumentCloseModal} />

                </Grid>

                <ToastContainer
                    position="top-right" // or "top-center", "bottom-left", etc.
                    autoClose={1500} // Auto close duration in ms
                    hideProgressBar={false} // Hide or show progress bar
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="dark"
                />
            </Grid>

            {/* <Footer /> */}
        </>
    );
};

export default PaymentPage;
