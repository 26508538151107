
// import { GetAllOrdersByCustomerAction } from "../Slices/Customer/GetAllOrdersByCustomer";
import { getCartItemCountAction } from "../Slices/AddToCard/getCartItemCount";
import { getPlanByIdAction } from "../Slices/AddToCard/getPlanById";
import { addMultiPurchaseAction } from "../Slices/Customer/addMultiPurchase";
import { customerLoginAction } from "../Slices/Customer/customerLogin";
import { customerRegisterAction } from "../Slices/Customer/customerRegister";
import { getCustomerGoldBalanceDashboardAction } from "../Slices/Customer/getCustomerGoldBalanceDashboard";
import { getCustomerRewardsListAction } from "../Slices/Customer/getCustomerRewardsList";
import { getKYCByCountryAction } from "../Slices/Customer/getKYCByCountry";
import { getPurchasesByCustomerIdAction } from "../Slices/Customer/getPurchasesByCustomerId";
import { getReferralRewardDashboardAction } from "../Slices/Customer/getReferralRewardDashboard";
import { ImageUploadPostAction } from "../Slices/Customer/ImageUploadPost";
import { PurchaseSchemesAction } from "../Slices/Schemes/PurchaseSchemes";
import { EmailSendOtpAction } from "../Slices/VerificationOtp/EmailSendOtp";
import { EmailVerifyOtpAction } from "../Slices/VerificationOtp/EmailVerifyOtp";
import { resetPasswordForCustomerAction } from "../Slices/VerificationOtp/resetPasswordForCustomer";
import { getAllAboutUsSectionsAction } from "../Slices/website/AboutUs/getAllAboutUsSections";
import { getAllBlogSectionsAction } from "../Slices/website/blog/getAllBlogSections";
import { getAllContactUsSectionsAction } from "../Slices/website/contactUs/getAllContactUsSections";
import { postContactUsFormAction } from "../Slices/website/contactUs/postContactUsForm";
import { getAllHomeSectionsAction } from "../Slices/website/home/getAllHomeSections";
import { getAllReferralsSectionsAction } from "../Slices/website/referrals/getAllReferralsSections";
import { getAllstakeNowSectionsAction } from "../Slices/website/stakeNow/getAllstakeNowSections";


const actions = {

  // ...RptLoadNoDropDownAction,
  // ...RptStatusDropDownAction,

  // ...CategoryTableGetAllAction,
  // ...GetAllOrdersByCustomerAction,
  ...getAllHomeSectionsAction,
  ...getAllstakeNowSectionsAction,
  ...getAllReferralsSectionsAction,
  ...getAllBlogSectionsAction,
  ...getAllContactUsSectionsAction,
  ...getAllAboutUsSectionsAction,
  ...postContactUsFormAction,
  ...customerRegisterAction,
  ...customerLoginAction,
  ...PurchaseSchemesAction,
  ...ImageUploadPostAction,
  ...getKYCByCountryAction,
  ...getCustomerGoldBalanceDashboardAction,
  ...getPurchasesByCustomerIdAction,
  ...getCustomerRewardsListAction,
  ...getReferralRewardDashboardAction,
  ...getCartItemCountAction,
  ...EmailSendOtpAction,
  ...EmailVerifyOtpAction,
  ...addMultiPurchaseAction,
  ...resetPasswordForCustomerAction,
  ...getPlanByIdAction


};

export default actions;
