
// import React from "react";
// import { Box, Button, Typography, Container, Stack } from "@mui/material";
// import home_page_Image from "../../Assets/home_page_Image.png";
// import home_side_image from "../../Assets/Overlay.png"; // Ensure this is the correct path
// import refer_img from "../../Assets/refer_img.svg"; // Ensure this is the correct path


// const ReferSection = () => {
//     return (
//         <Box
//             sx={{
//                 position: "relative", // Needed for positioning child elements
//                 background: "#F7F7F7",

//                 display: "flex",
//                 alignItems: "center",
//                 justifyContent: "space-between",
//                 overflow: "hidden",
//                 padding: "50px 0px",
//                 // left:"100px"
//                 // minHeight: "90vh", // Adjust height as needed
//             }}
//         >
//             <Container maxWidth="lg" sx={{ zIndex: 2 }}>
//                 <Box
//                     display="flex"
//                     // alignItems="center"
//                     justifyContent="space-around"
//                     flexWrap="wrap"
//                 >
//                     {/* Left Section */}
//                     <Box
//                         sx={{

//                             color: "#fff",
//                         }}
//                     >
//                         <Typography
//                             variant="h3"
//                             textAlign="start"
//                             sx={{
//                                 fontWeight: "bold",
//                                 // fontSize: "42px",
//                                 maxWidth: "600px",
//                                 color: "#3B4056",
//                                 mb: 1,
//                                 fontSize:"50px"
//                             }}
//                         >
//                             Refer to your Friends & Earn Gold
//                         </Typography>

//                         <Typography
//                             variant="body1"
//                             textAlign="start"
//                             sx={{
//                                 mb: 3,
//                                 lineHeight: "1.6",
//                                 color: "#7A7A7A",
//                                 // width:"55%",
//                                 maxWidth: "600px",
//                                 fontSize:"14px",

//                                 letterSpacing: "0px",
//                                 // color: "#b0aaaa",
//                                 opacity: 1
//                             }}
//                         >
//                             Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the
//                             industry’s standard dummy text ever since the 1500s, when an unknown
//                         </Typography>
//                         <Stack spacing={2} direction="row">
//                             <Button
//                                 variant="contained"

//                                 sx={{
//                                     // backgroundColor: "#C38F51",
//                                     textTransform: "none",
//                                     color: "white",
//                                     fontSize: "18px",
//                                     padding: "0px 60px",
//                                     height: "40px",
//                                     borderRadius: "23px",
//                                     background: "#3B4056 0% 0% no-repeat padding-box",
//                                     "&:hover": {
//                                         backgroundColor: "#3B4056",
//                                     },
//                                 }}
//                             >
//                                 Refer Now
//                             </Button>

//                         </Stack>
//                     </Box>
//                     <Box>
//                         <img src={refer_img} style={{height:"220px"}}></img>
//                     </Box>
//                 </Box>
//             </Container >
//         </Box >
//     );
// };

// export default ReferSection;



import React from "react";
import { Box, Button, Typography, Container, Stack } from "@mui/material";
import refer_img from "../../../Assets/refer_img.svg"; // Ensure this is the correct path
import { adminUrl } from "../../../constants";

const ReferSection = ({ referralsDates }) => {

    return (
        <Box
            sx={{
                position: "relative",
                // background: "#F7F7F7",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                overflow: "hidden",
                padding: "50px 0px",
            }}
        >
            <Container maxWidth="lg" sx={{ zIndex: 2 }}>
                <Box
                    display="flex"
                    justifyContent="space-around"
                    flexWrap="wrap"
                >
                    {/* Left Section */}
                    <Box sx={{ color: "#fff" }}>
                        <Typography
                            variant="h1"
                            textAlign="start"
                            sx={{
                                // fontSize:"40px",
                                fontSize: { xs: "30px", md: "40px" },

                                maxWidth: "600px",
                                color: "#3B4056",
                                mb: 1,

                            }}
                        >
                            {referralsDates?.[0]?.heading || "Default Sub-heading"}

                        </Typography>

                        <Typography
                            variant="body1"
                            textAlign="start"
                            sx={{
                                mb: 3,
                                lineHeight: "1.6",

                                color: "#7A7A7A",
                                maxWidth: "600px",
                                fontSize: "14px",
                                letterSpacing: "0px",
                                opacity: 1,
                            }}
                        >
                            {referralsDates?.[0]?.content || "Default Sub-heading"}

                        </Typography>
                        <Stack spacing={2} direction="row">
                            <Button
                                variant="contained"
                                sx={{
                                    textTransform: "none",
                                    color: "white",
                                    boxShadow: "none",

                                    padding: "0px 60px",
                                    height: "40px",
                                    borderRadius: "23px",
                                    background: "#3B4056 0% 0% no-repeat padding-box",
                                    "&:hover": {
                                        backgroundColor: "#C38F51",
                                    },
                                }}
                            >
                                {referralsDates?.[0]?.additional_data?.[0]?.primary_button_text || "Primary Button"}

                            </Button>
                        </Stack>
                    </Box>
                    {/* Right Section: Image */}
                    <Box
                        sx={{
                            display: {
                                xs: "none", // Hide on extra small screens
                                sm: "block", // Show on small screens and above
                            },
                        }}
                    >
                        

                        <img src={`${adminUrl}/${referralsDates?.[0]?.additional_data?.[0]?.banner}`} alt="Refer" style={{ height: "220px" }} />
                    </Box>
                </Box>
            </Container>
        </Box>
    );
};

export default ReferSection;
