// // // // import React from 'react';
// // // // import {
// // // //   Table,
// // // //   TableBody,
// // // //   TableCell,
// // // //   TableContainer,
// // // //   TableHead,
// // // //   TableRow,
// // // //   Paper,
// // // //   IconButton,
// // // //   Pagination,
// // // //   Typography,
// // // //   Box,
// // // // } from '@mui/material';
// // // // import VisibilityIcon from '@mui/icons-material/Visibility';

// // // // const CustomTable = () => {
// // // //   const customers = [
// // // //     { id: 1, name: 'Biddu Mohan', profileId: '202411301', dateTime: '12-11-2014 10:05 PM', status: 'Active' },
// // // //     { id: 2, name: 'Mani Krish', profileId: '202411302', dateTime: '12-11-2014 10:07 PM', status: 'Active' },
// // // //     { id: 3, name: 'Binu', profileId: '202411303', dateTime: '12-11-2014 10:15 PM', status: 'Active' },
// // // //     { id: 4, name: 'Arun', profileId: '202411304', dateTime: '12-11-2014 10:18 PM', status: 'Active' },
// // // //     { id: 5, name: 'Karthi', profileId: '202411305', dateTime: '12-11-2014 10:25 PM', status: 'Active' },
// // // //     { id: 6, name: 'Biddu Mohan', profileId: '202411301', dateTime: '12-11-2014 10:05 PM', status: 'Active' },
// // // //     { id: 7, name: 'Mani Krish', profileId: '202411302', dateTime: '12-11-2014 10:07 PM', status: 'Active' },
// // // //     { id: 8, name: 'Binu', profileId: '202411303', dateTime: '12-11-2014 10:15 PM', status: 'Active' },
// // // //     { id: 9, name: 'Arun', profileId: '202411304', dateTime: '12-11-2014 10:18 PM', status: 'Active' },
// // // //     { id: 10, name: 'Karthi', profileId: '202411305', dateTime: '12-11-2014 10:25 PM', status: 'Active' },
// // // //     { id: 11, name: 'Biddu Mohan', profileId: '202411301', dateTime: '12-11-2014 10:05 PM', status: 'Active' },
// // // //     { id: 12, name: 'Mani Krish', profileId: '202411302', dateTime: '12-11-2014 10:07 PM', status: 'Active' },
// // // //     { id: 13, name: 'Binu', profileId: '202411303', dateTime: '12-11-2014 10:15 PM', status: 'Active' },
// // // //     { id: 14, name: 'Arun', profileId: '202411304', dateTime: '12-11-2014 10:18 PM', status: 'Active' },
// // // //     { id: 15, name: 'Karthi', profileId: '202411305', dateTime: '12-11-2014 10:25 PM', status: 'Active' },

// // // //   ];

// // // //   return (
// // // //     <Box sx={{ padding: 2 }}>
// // // //       <Typography variant="h6" gutterBottom>
// // // //         New Customers
// // // //       </Typography>
// // // //       <TableContainer component={Paper}>
// // // //         <Table>
// // // //           <TableHead>
// // // //             <TableRow>
// // // //               <TableCell>S.NO</TableCell>
// // // //               <TableCell>Name</TableCell>
// // // //               <TableCell>Profile ID</TableCell>
// // // //               <TableCell>Date & Time</TableCell>
// // // //               <TableCell>Status</TableCell>
// // // //               <TableCell>Actions</TableCell>
// // // //             </TableRow>
// // // //           </TableHead>
// // // //           <TableBody>
// // // //             {customers.map((customer, index) => (
// // // //               <TableRow key={customer.id}>
// // // //                 <TableCell>{index + 1}</TableCell>
// // // //                 <TableCell>{customer.name}</TableCell>
// // // //                 <TableCell>{customer.profileId}</TableCell>
// // // //                 <TableCell>{customer.dateTime}</TableCell>
// // // //                 <TableCell>{customer.status}</TableCell>
// // // //                 <TableCell>
// // // //                   <IconButton color="primary">
// // // //                     <VisibilityIcon />
// // // //                   </IconButton>
// // // //                 </TableCell>
// // // //               </TableRow>
// // // //             ))}
// // // //           </TableBody>
// // // //         </Table>
// // // //       </TableContainer>
// // // //       <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: 2 }}>
// // // //         <Typography variant="body2">Showing 1 to 5 of 25 entries</Typography>
// // // //         <Pagination count={5} page={1} />
// // // //       </Box>
// // // //     </Box>
// // // //   );
// // // // };

// // // // export default CustomTable;


// // // // import React, { useState } from 'react';
// // // // import {
// // // //   Table,
// // // //   TableBody,
// // // //   TableCell,
// // // //   TableContainer,
// // // //   TableHead,
// // // //   TableRow,
// // // //   Paper,
// // // //   IconButton,
// // // //   Pagination,
// // // //   Typography,
// // // //   Box,
// // // //   TextField,
// // // // } from '@mui/material';
// // // // import VisibilityIcon from '@mui/icons-material/Visibility';

// // // // const CustomTable = () => {
// // // //   const allCustomers = [
// // // //     { id: 1, name: 'Biddu Mohan', profileId: '202411301', dateTime: '12-11-2014 10:05 PM', status: 'Active' },
// // // //     { id: 2, name: 'Mani Krish', profileId: '202411302', dateTime: '12-11-2014 10:07 PM', status: 'Active' },
// // // //     { id: 3, name: 'Binu', profileId: '202411303', dateTime: '12-11-2014 10:15 PM', status: 'Active' },
// // // //     { id: 4, name: 'Arun', profileId: '202411304', dateTime: '12-11-2014 10:18 PM', status: 'Active' },
// // // //     { id: 5, name: 'Karthi', profileId: '202411305', dateTime: '12-11-2014 10:25 PM', status: 'Active' },
// // // //     { id: 6, name: 'Biddu Mohan', profileId: '202411301', dateTime: '12-11-2014 10:05 PM', status: 'Active' },
// // // //     { id: 7, name: 'Mani Krish', profileId: '202411302', dateTime: '12-11-2014 10:07 PM', status: 'Active' },
// // // //     { id: 8, name: 'Binu', profileId: '202411303', dateTime: '12-11-2014 10:15 PM', status: 'Active' },
// // // //     { id: 9, name: 'Arun', profileId: '202411304', dateTime: '12-11-2014 10:18 PM', status: 'Active' },
// // // //     { id: 10, name: 'Karthi', profileId: '202411305', dateTime: '12-11-2014 10:25 PM', status: 'Active' },
// // // //     { id: 11, name: 'Biddu Mohan', profileId: '202411301', dateTime: '12-11-2014 10:05 PM', status: 'Active' },
// // // //     { id: 12, name: 'Mani Krish', profileId: '202411302', dateTime: '12-11-2014 10:07 PM', status: 'Active' },
// // // //     { id: 13, name: 'Binu', profileId: '202411303', dateTime: '12-11-2014 10:15 PM', status: 'Active' },
// // // //     { id: 14, name: 'Arun', profileId: '202411304', dateTime: '12-11-2014 10:18 PM', status: 'Active' },
// // // //     { id: 15, name: 'Karthi', profileId: '202411305', dateTime: '12-11-2014 10:25 PM', status: 'Active' },
// // // //   ];










// // // //   const [searchQuery, setSearchQuery] = useState('');
// // // //   const [currentPage, setCurrentPage] = useState(1);
// // // //   const rowsPerPage = 10;

// // // //   // Filter customers based on search query
// // // //   const filteredCustomers = allCustomers.filter((customer) =>
// // // //     customer.name.toLowerCase().includes(searchQuery.toLowerCase())
// // // //   );

// // // //   // Pagination logic
// // // //   const totalPages = Math.ceil(filteredCustomers.length / rowsPerPage);
// // // //   const paginatedCustomers = filteredCustomers.slice(
// // // //     (currentPage - 1) * rowsPerPage,
// // // //     currentPage * rowsPerPage
// // // //   );

// // // //   // Handle pagination
// // // //   const handlePageChange = (event, page) => {
// // // //     setCurrentPage(page);
// // // //   };

// // // //   return (
// // // //     <Box sx={{ padding: 2 }}>
// // // //       <Typography variant="h6" gutterBottom>
// // // //         New Customers
// // // //       </Typography>
// // // //       <TextField
// // // //         label="Search"
// // // //         variant="outlined"
// // // //         fullWidth
// // // //         margin="normal"
// // // //         onChange={(e) => {
// // // //           setSearchQuery(e.target.value);
// // // //           setCurrentPage(1); // Reset to first page on search
// // // //         }}
// // // //       />
// // // //       <TableContainer component={Paper}>
// // // //         <Table>
// // // //           <TableHead>
// // // //             <TableRow>
// // // //               <TableCell>S.NO</TableCell>
// // // //               <TableCell>Name</TableCell>
// // // //               <TableCell>Profile ID</TableCell>
// // // //               <TableCell>Date & Time</TableCell>
// // // //               <TableCell>Status</TableCell>
// // // //               <TableCell>Actions</TableCell>
// // // //             </TableRow>
// // // //           </TableHead>
// // // //           <TableBody>
// // // //             {paginatedCustomers.map((customer, index) => (
// // // //               <TableRow key={customer.id}>
// // // //                 <TableCell>
// // // //                   {/* Serial number based on pagination */}
// // // //                   {(currentPage - 1) * rowsPerPage + index + 1}
// // // //                 </TableCell>
// // // //                 <TableCell>{customer.name}</TableCell>
// // // //                 <TableCell>{customer.profileId}</TableCell>
// // // //                 <TableCell>{customer.dateTime}</TableCell>
// // // //                 <TableCell>{customer.status}</TableCell>
// // // //                 <TableCell>
// // // //                   <IconButton color="primary">
// // // //                     <VisibilityIcon />
// // // //                   </IconButton>
// // // //                 </TableCell>
// // // //               </TableRow>
// // // //             ))}
// // // //           </TableBody>
// // // //         </Table>
// // // //       </TableContainer>
// // // //       <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: 2 }}>
// // // //         <Typography variant="body2">
// // // //           Showing {filteredCustomers.length === 0 ? 0 : (currentPage - 1) * rowsPerPage + 1} to{' '}
// // // //           {Math.min(currentPage * rowsPerPage, filteredCustomers.length)} of {filteredCustomers.length} entries
// // // //         </Typography>
// // // //         <Pagination count={totalPages} page={currentPage} onChange={handlePageChange} />
// // // //       </Box>
// // // //     </Box>
// // // //   );
// // // // };

// // // // export default CustomTable;




// // // import React, { useState } from 'react';
// // // import {
// // //   Table,
// // //   TableBody,
// // //   TableCell,
// // //   TableContainer,
// // //   TableHead,
// // //   TableRow,
// // //   Paper,
// // //   IconButton,
// // //   Pagination,
// // //   Typography,
// // //   Box,
// // //   TextField,
// // //   InputAdornment,
// // //   Grid,
// // // } from '@mui/material';
// // // import VisibilityIcon from '@mui/icons-material/Visibility';
// // // import { IoSearch } from "react-icons/io5";


// // // const CustomTable = () => {
// // //   const allCustomers = [
// // //     { id: 1, name: 'Biddu Mohan', profileId: '202411301', dateTime: '12-11-2014 10:05 PM', status: 'Active' },
// // //     { id: 2, name: 'Mani Krish', profileId: '202411302', dateTime: '12-11-2014 10:07 PM', status: 'Active' },
// // //     { id: 3, name: 'Binu', profileId: '202411303', dateTime: '12-11-2014 10:15 PM', status: 'Active' },
// // //     { id: 4, name: 'Arun', profileId: '202411304', dateTime: '12-11-2014 10:18 PM', status: 'Active' },
// // //     { id: 5, name: 'Karthi', profileId: '202411305', dateTime: '12-11-2014 10:25 PM', status: 'Active' },

// // //   ];


// // //   const tableHeadData = [
// // //     "S.NO",
// // //     "Name",
// // //     "Profile ID",
// // //     "Date & Time",
// // //     "Status",
// // //     'Actions'

// // //   ]

// // //   const totalData = allCustomers.length

// // //   console.log(totalData, "TotalNumner")

// // //   const [searchQuery, setSearchQuery] = useState('');
// // //   const [currentPage, setCurrentPage] = useState(1);
// // //   const rowsPerPage = 5;

// // //   // Filter customers based on search query
// // //   const filteredCustomers = allCustomers.filter((customer) =>
// // //     customer.name.toLowerCase().includes(searchQuery.toLowerCase())
// // //   );

// // //   // Pagination logic
// // //   const totalPages = Math.ceil(filteredCustomers.length / rowsPerPage);
// // //   console.log(totalPages, "ToatalPages")
// // //   const paginatedCustomers = filteredCustomers.slice(
// // //     (currentPage - 1) * rowsPerPage,
// // //     currentPage * rowsPerPage
// // //   );
// // //   console.log(paginatedCustomers, "ToatalPages12")
// // //   // Handle pagination
// // //   const handlePageChange = (event, page) => {
// // //     setCurrentPage(page);
// // //   };

// // //   return (
// // //     <Box
// // //       sx={{ padding: 2 }}
// // //     >
// // //       <Grid sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
// // //         <Typography variant="h6" gutterBottom>
// // //           New Customers
// // //         </Typography>

// // //         <TextField
// // //           placeholder="Search"
// // //           variant="outlined"
// // //           fullWidth
// // //           helperText="you search"
// // //           margin="normal"
// // //           InputProps={{
// // //             style: {
// // //               height: '35px',
// // //               padding: '0 5px',
// // //             },
// // //             startAdornment:<InputAdornment position='start'><IoSearch /></InputAdornment>,
// // //             endAdornment:<InputAdornment position='end'><IoSearch /></InputAdornment>


// // //           }}
// // //           sx={{
// // //             width: '200px',
// // //             '& .MuiOutlinedInput-root.Mui-focused': {
// // //               borderColor: 'blue',
// // //             },
// // //           }}
// // //           onChange={(e) => {
// // //             setSearchQuery(e.target.value);
// // //             setCurrentPage(1); // Reset to first page on search
// // //           }}
// // //         />
// // //       </Grid>
// // //       <TableContainer component={Paper}>
// // //         <Table>
// // //           <TableHead>
// // //             <TableRow sx={{ background: "#C38F51",color:"white" }}>
// // //               {tableHeadData.map((data) => (
// // //                 <TableCell sx={{color:'white',padding:"8px"}}>{data}</TableCell>
// // //               ))}
// // //             </TableRow>
// // //           </TableHead>
// // //           <TableBody>

// // //             {paginatedCustomers.map((customer, index) => (
// // //               <TableRow key={customer.id}>
// // //                 {/* Serial number based on pagination */}
// // //                 <TableCell>
// // //                   {(currentPage - 1) * rowsPerPage + index + 1}
// // //                 </TableCell>

// // //                 {/* Dynamically render customer data except id */}
// // //                 {Object.keys(customer).map((key) =>
// // //                   key !== "id" && (
// // //                     <TableCell
// // //                       key={key}
// // //                     // style={{ fontWeight: "700", width: "300px", background: "var(--tabledata-bgcolor)" }}
// // //                     >
// // //                       {customer[key]}
// // //                     </TableCell>
// // //                   )
// // //                 )}

// // //                 {/* Action buttons or icons */}
// // //                 <TableCell>
// // //                   <IconButton color="primary">
// // //                     <VisibilityIcon />
// // //                   </IconButton>
// // //                 </TableCell>
// // //               </TableRow>
// // //             ))}

// // //           </TableBody>
// // //         </Table>
// // //       </TableContainer>
// // //       {/* <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: 2 }}>
// // //         <Typography variant="body2">
// // //           Showing {filteredCustomers.length === 0 ? 0 : (currentPage - 1) * rowsPerPage + 1} to{' '}
// // //           {Math.min(currentPage * rowsPerPage, filteredCustomers.length)} of {filteredCustomers.length} entries
// // //         </Typography>
// // //         <Pagination
// // //           count={totalPages}
// // //           page={currentPage}
// // //           onChange={handlePageChange}
// // //           // sx={{color:"red",background:"blue"}}

// // //           sx={{
// // //             "& .MuiPagination-ul": { // Styles the root list of Pagination
// // //               // background: "blue",
// // //               padding: "8px",
// // //               borderRadius: "4px",
// // //             },
// // //             "& .MuiPaginationItem-page.Mui-selected": {
// // //               backgroundColor: "blue", // Change to your desired color
// // //               color: "white",         // Optional: Change text color
// // //             },
// // //             "& .MuiPaginationItem-root": { // Styles individual pagination items
// // //               // color: "red",
// // //               // background:"yellow"
// // //             },
// // //           }}
// // //         />
// // //       </Box> */}
// // //     </Box>
// // //   );
// // // };

// // // export default CustomTable;





// // // import React from 'react';
// // // import {
// // //   Table,
// // //   TableBody,
// // //   TableCell,
// // //   TableContainer,
// // //   TableHead,
// // //   TableRow,
// // //   Paper,
// // //   IconButton,
// // //   Pagination,
// // //   Typography,
// // //   Box,
// // // } from '@mui/material';
// // // import VisibilityIcon from '@mui/icons-material/Visibility';

// // // const CustomTable = () => {
// // //   const customers = [
// // //     { id: 1, name: 'Biddu Mohan', profileId: '202411301', dateTime: '12-11-2014 10:05 PM', status: 'Active' },
// // //     { id: 2, name: 'Mani Krish', profileId: '202411302', dateTime: '12-11-2014 10:07 PM', status: 'Active' },
// // //     { id: 3, name: 'Binu', profileId: '202411303', dateTime: '12-11-2014 10:15 PM', status: 'Active' },
// // //     { id: 4, name: 'Arun', profileId: '202411304', dateTime: '12-11-2014 10:18 PM', status: 'Active' },
// // //     { id: 5, name: 'Karthi', profileId: '202411305', dateTime: '12-11-2014 10:25 PM', status: 'Active' },
// // //     { id: 6, name: 'Biddu Mohan', profileId: '202411301', dateTime: '12-11-2014 10:05 PM', status: 'Active' },
// // //     { id: 7, name: 'Mani Krish', profileId: '202411302', dateTime: '12-11-2014 10:07 PM', status: 'Active' },
// // //     { id: 8, name: 'Binu', profileId: '202411303', dateTime: '12-11-2014 10:15 PM', status: 'Active' },
// // //     { id: 9, name: 'Arun', profileId: '202411304', dateTime: '12-11-2014 10:18 PM', status: 'Active' },
// // //     { id: 10, name: 'Karthi', profileId: '202411305', dateTime: '12-11-2014 10:25 PM', status: 'Active' },
// // //     { id: 11, name: 'Biddu Mohan', profileId: '202411301', dateTime: '12-11-2014 10:05 PM', status: 'Active' },
// // //     { id: 12, name: 'Mani Krish', profileId: '202411302', dateTime: '12-11-2014 10:07 PM', status: 'Active' },
// // //     { id: 13, name: 'Binu', profileId: '202411303', dateTime: '12-11-2014 10:15 PM', status: 'Active' },
// // //     { id: 14, name: 'Arun', profileId: '202411304', dateTime: '12-11-2014 10:18 PM', status: 'Active' },
// // //     { id: 15, name: 'Karthi', profileId: '202411305', dateTime: '12-11-2014 10:25 PM', status: 'Active' },

// // //   ];

// // //   return (
// // //     <Box sx={{ padding: 2 }}>
// // //       <Typography variant="h6" gutterBottom>
// // //         New Customers
// // //       </Typography>
// // //       <TableContainer component={Paper}>
// // //         <Table>
// // //           <TableHead>
// // //             <TableRow>
// // //               <TableCell>S.NO</TableCell>
// // //               <TableCell>Name</TableCell>
// // //               <TableCell>Profile ID</TableCell>
// // //               <TableCell>Date & Time</TableCell>
// // //               <TableCell>Status</TableCell>
// // //               <TableCell>Actions</TableCell>
// // //             </TableRow>
// // //           </TableHead>
// // //           <TableBody>
// // //             {customers.map((customer, index) => (
// // //               <TableRow key={customer.id}>
// // //                 <TableCell>{index + 1}</TableCell>
// // //                 <TableCell>{customer.name}</TableCell>
// // //                 <TableCell>{customer.profileId}</TableCell>
// // //                 <TableCell>{customer.dateTime}</TableCell>
// // //                 <TableCell>{customer.status}</TableCell>
// // //                 <TableCell>
// // //                   <IconButton color="primary">
// // //                     <VisibilityIcon />
// // //                   </IconButton>
// // //                 </TableCell>
// // //               </TableRow>
// // //             ))}
// // //           </TableBody>
// // //         </Table>
// // //       </TableContainer>
// // //       <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: 2 }}>
// // //         <Typography variant="body2">Showing 1 to 5 of 25 entries</Typography>
// // //         <Pagination count={5} page={1} />
// // //       </Box>
// // //     </Box>
// // //   );
// // // };

// // // export default CustomTable;


// // // import React, { useState } from 'react';
// // // import {
// // //   Table,
// // //   TableBody,
// // //   TableCell,
// // //   TableContainer,
// // //   TableHead,
// // //   TableRow,
// // //   Paper,
// // //   IconButton,
// // //   Pagination,
// // //   Typography,
// // //   Box,
// // //   TextField,
// // // } from '@mui/material';
// // // import VisibilityIcon from '@mui/icons-material/Visibility';

// // // const CustomTable = () => {
// // //   const allCustomers = [
// // //     { id: 1, name: 'Biddu Mohan', profileId: '202411301', dateTime: '12-11-2014 10:05 PM', status: 'Active' },
// // //     { id: 2, name: 'Mani Krish', profileId: '202411302', dateTime: '12-11-2014 10:07 PM', status: 'Active' },
// // //     { id: 3, name: 'Binu', profileId: '202411303', dateTime: '12-11-2014 10:15 PM', status: 'Active' },
// // //     { id: 4, name: 'Arun', profileId: '202411304', dateTime: '12-11-2014 10:18 PM', status: 'Active' },
// // //     { id: 5, name: 'Karthi', profileId: '202411305', dateTime: '12-11-2014 10:25 PM', status: 'Active' },
// // //     { id: 6, name: 'Biddu Mohan', profileId: '202411301', dateTime: '12-11-2014 10:05 PM', status: 'Active' },
// // //     { id: 7, name: 'Mani Krish', profileId: '202411302', dateTime: '12-11-2014 10:07 PM', status: 'Active' },
// // //     { id: 8, name: 'Binu', profileId: '202411303', dateTime: '12-11-2014 10:15 PM', status: 'Active' },
// // //     { id: 9, name: 'Arun', profileId: '202411304', dateTime: '12-11-2014 10:18 PM', status: 'Active' },
// // //     { id: 10, name: 'Karthi', profileId: '202411305', dateTime: '12-11-2014 10:25 PM', status: 'Active' },
// // //     { id: 11, name: 'Biddu Mohan', profileId: '202411301', dateTime: '12-11-2014 10:05 PM', status: 'Active' },
// // //     { id: 12, name: 'Mani Krish', profileId: '202411302', dateTime: '12-11-2014 10:07 PM', status: 'Active' },
// // //     { id: 13, name: 'Binu', profileId: '202411303', dateTime: '12-11-2014 10:15 PM', status: 'Active' },
// // //     { id: 14, name: 'Arun', profileId: '202411304', dateTime: '12-11-2014 10:18 PM', status: 'Active' },
// // //     { id: 15, name: 'Karthi', profileId: '202411305', dateTime: '12-11-2014 10:25 PM', status: 'Active' },
// // //   ];










// // //   const [searchQuery, setSearchQuery] = useState('');
// // //   const [currentPage, setCurrentPage] = useState(1);
// // //   const rowsPerPage = 10;

// // //   // Filter customers based on search query
// // //   const filteredCustomers = allCustomers.filter((customer) =>
// // //     customer.name.toLowerCase().includes(searchQuery.toLowerCase())
// // //   );

// // //   // Pagination logic
// // //   const totalPages = Math.ceil(filteredCustomers.length / rowsPerPage);
// // //   const paginatedCustomers = filteredCustomers.slice(
// // //     (currentPage - 1) * rowsPerPage,
// // //     currentPage * rowsPerPage
// // //   );

// // //   // Handle pagination
// // //   const handlePageChange = (event, page) => {
// // //     setCurrentPage(page);
// // //   };

// // //   return (
// // //     <Box sx={{ padding: 2 }}>
// // //       <Typography variant="h6" gutterBottom>
// // //         New Customers
// // //       </Typography>
// // //       <TextField
// // //         label="Search"
// // //         variant="outlined"
// // //         fullWidth
// // //         margin="normal"
// // //         onChange={(e) => {
// // //           setSearchQuery(e.target.value);
// // //           setCurrentPage(1); // Reset to first page on search
// // //         }}
// // //       />
// // //       <TableContainer component={Paper}>
// // //         <Table>
// // //           <TableHead>
// // //             <TableRow>
// // //               <TableCell>S.NO</TableCell>
// // //               <TableCell>Name</TableCell>
// // //               <TableCell>Profile ID</TableCell>
// // //               <TableCell>Date & Time</TableCell>
// // //               <TableCell>Status</TableCell>
// // //               <TableCell>Actions</TableCell>
// // //             </TableRow>
// // //           </TableHead>
// // //           <TableBody>
// // //             {paginatedCustomers.map((customer, index) => (
// // //               <TableRow key={customer.id}>
// // //                 <TableCell>
// // //                   {/* Serial number based on pagination */}
// // //                   {(currentPage - 1) * rowsPerPage + index + 1}
// // //                 </TableCell>
// // //                 <TableCell>{customer.name}</TableCell>
// // //                 <TableCell>{customer.profileId}</TableCell>
// // //                 <TableCell>{customer.dateTime}</TableCell>
// // //                 <TableCell>{customer.status}</TableCell>
// // //                 <TableCell>
// // //                   <IconButton color="primary">
// // //                     <VisibilityIcon />
// // //                   </IconButton>
// // //                 </TableCell>
// // //               </TableRow>
// // //             ))}
// // //           </TableBody>
// // //         </Table>
// // //       </TableContainer>
// // //       <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: 2 }}>
// // //         <Typography variant="body2">
// // //           Showing {filteredCustomers.length === 0 ? 0 : (currentPage - 1) * rowsPerPage + 1} to{' '}
// // //           {Math.min(currentPage * rowsPerPage, filteredCustomers.length)} of {filteredCustomers.length} entries
// // //         </Typography>
// // //         <Pagination count={totalPages} page={currentPage} onChange={handlePageChange} />
// // //       </Box>
// // //     </Box>
// // //   );
// // // };

// // // export default CustomTable;

// // //////////////////////////////////////////////////////////////


// // // import React, { useState } from 'react';
// // // import {
// // //   Table,
// // //   TableBody,
// // //   TableCell,
// // //   TableContainer,
// // //   TableHead,
// // //   TableRow,
// // //   Paper,
// // //   IconButton,
// // //   Pagination,
// // //   Typography,
// // //   Box,
// // //   TextField,
// // //   InputAdornment,
// // //   Grid,
// // // } from '@mui/material';
// // // import VisibilityIcon from '@mui/icons-material/Visibility';
// // // import { IoSearch } from "react-icons/io5";


// // // const CustomTable = () => {
// // //   const allCustomers = [
// // //     { id: 1, name: 'Biddu Mohan', profileId: '202411301', dateTime: '12-11-2014 10:05 PM', status: 'Active' },
// // //     { id: 2, name: 'Mani Krish', profileId: '202411302', dateTime: '12-11-2014 10:07 PM', status: 'Active' },
// // //     { id: 3, name: 'Binu', profileId: '202411303', dateTime: '12-11-2014 10:15 PM', status: 'Active' },
// // //     { id: 4, name: 'Arun', profileId: '202411304', dateTime: '12-11-2014 10:18 PM', status: 'Active' },
// // //     { id: 5, name: 'Karthi', profileId: '202411305', dateTime: '12-11-2014 10:25 PM', status: 'Active' },

// // //   ];


// // //   const tableHeadData = [
// // //     "S.NO",
// // //     "Name",
// // //     "Profile ID",
// // //     "Date & Time",
// // //     "Status",
// // //     'Actions'

// // //   ]

// // //   const totalData = allCustomers.length

// // //   console.log(totalData, "TotalNumner")

// // //   const [searchQuery, setSearchQuery] = useState('');
// // //   const [currentPage, setCurrentPage] = useState(1);
// // //   const rowsPerPage = 5;

// // //   // Filter customers based on search query
// // //   const filteredCustomers = allCustomers.filter((customer) =>
// // //     customer.name.toLowerCase().includes(searchQuery.toLowerCase())
// // //   );

// // //   // Pagination logic
// // //   const totalPages = Math.ceil(filteredCustomers.length / rowsPerPage);
// // //   console.log(totalPages, "ToatalPages")
// // //   const paginatedCustomers = filteredCustomers.slice(
// // //     (currentPage - 1) * rowsPerPage,
// // //     currentPage * rowsPerPage
// // //   );
// // //   console.log(paginatedCustomers, "ToatalPages12")
// // //   // Handle pagination
// // //   const handlePageChange = (event, page) => {
// // //     setCurrentPage(page);
// // //   };

// // //   return (
// // //     <Box
// // //       sx={{ padding: 2 }}
// // //     >
// // //       <Grid sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
// // //         <Typography variant="h6" gutterBottom>
// // //           New Customers
// // //         </Typography>

// // //         <TextField
// // //           placeholder="Search"
// // //           variant="outlined"
// // //           fullWidth
// // //           helperText="you search"
// // //           margin="normal"
// // //           InputProps={{
// // //             style: {
// // //               height: '35px',
// // //               padding: '0 5px',
// // //             },
// // //             startAdornment:<InputAdornment position='start'><IoSearch /></InputAdornment>,
// // //             endAdornment:<InputAdornment position='end'><IoSearch /></InputAdornment>


// // //           }}
// // //           sx={{
// // //             width: '200px',
// // //             '& .MuiOutlinedInput-root.Mui-focused': {
// // //               borderColor: 'blue',
// // //             },
// // //           }}
// // //           onChange={(e) => {
// // //             setSearchQuery(e.target.value);
// // //             setCurrentPage(1); // Reset to first page on search
// // //           }}
// // //         />
// // //       </Grid>
// // //       <TableContainer component={Paper}>
// // //         <Table>
// // //           <TableHead>
// // //             <TableRow sx={{ background: "#C38F51",color:"white" }}>
// // //               {tableHeadData.map((data) => (
// // //                 <TableCell sx={{color:'white',padding:"8px"}}>{data}</TableCell>
// // //               ))}
// // //             </TableRow>
// // //           </TableHead>
// // //           <TableBody>

// // //             {paginatedCustomers.map((customer, index) => (
// // //               <TableRow key={customer.id}>
// // //                 {/* Serial number based on pagination */}
// // //                 <TableCell>
// // //                   {(currentPage - 1) * rowsPerPage + index + 1}
// // //                 </TableCell>

// // //                 {/* Dynamically render customer data except id */}
// // //                 {Object.keys(customer).map((key) =>
// // //                   key !== "id" && (
// // //                     <TableCell
// // //                       key={key}
// // //                     // style={{ fontWeight: "700", width: "300px", background: "var(--tabledata-bgcolor)" }}
// // //                     >
// // //                       {customer[key]}
// // //                     </TableCell>
// // //                   )
// // //                 )}

// // //                 {/* Action buttons or icons */}
// // //                 <TableCell>
// // //                   <IconButton color="primary">
// // //                     <VisibilityIcon />
// // //                   </IconButton>
// // //                 </TableCell>
// // //               </TableRow>
// // //             ))}

// // //           </TableBody>
// // //         </Table>
// // //       </TableContainer>

// // //     </Box>
// // //   );
// // // };

// // // export default CustomTable;




// // import React, { useState } from 'react';
// // import {
// //   Table,
// //   TableBody,
// //   TableCell,
// //   TableContainer,
// //   TableHead,
// //   TableRow,
// //   Paper,
// //   IconButton,
// //   Pagination,
// //   Typography,
// //   Box,
// //   TextField,
// //   InputAdornment,
// //   Grid,
// //   Menu,
// //   MenuItem,
// //   ListItemIcon,
// //   ListItemText,
// // } from '@mui/material';
// // import { IoSearch } from 'react-icons/io5';
// // import { HiDownload, HiPencil } from 'react-icons/hi';
// // import { saveAs } from 'file-saver';
// // import * as XLSX from 'xlsx';

// // const CustomTable = () => {
// //   const allCustomers = [
// //     { id: 1, name: 'Biddu Mohan', profileId: '202411301', dateTime: '12-11-2014 10:05 PM', status: 'Active' },
// //     { id: 2, name: 'Mani Krish', profileId: '202411302', dateTime: '12-11-2014 10:07 PM', status: 'Active' },
// //     { id: 3, name: 'Binu', profileId: '202411303', dateTime: '12-11-2014 10:15 PM', status: 'Active' },
// //     { id: 4, name: 'Arun', profileId: '202411304', dateTime: '12-11-2014 10:18 PM', status: 'Active' },
// //     { id: 5, name: 'Karthi', profileId: '202411305', dateTime: '12-11-2014 10:25 PM', status: 'Active' },
// //   ];

// //   const tableHeadData = ['S.NO', 'Name', 'Profile ID', 'Date & Time', 'Status', 'Actions'];
// //   const rowsPerPage = 5;

// //   const [searchQuery, setSearchQuery] = useState('');
// //   const [currentPage, setCurrentPage] = useState(1);
// //   const [anchorEl, setAnchorEl] = useState(null);

// //   const filteredCustomers = allCustomers.filter((customer) =>
// //     customer.name.toLowerCase().includes(searchQuery.toLowerCase())
// //   );

// //   const totalPages = Math.ceil(filteredCustomers.length / rowsPerPage);
// //   const paginatedCustomers = filteredCustomers.slice(
// //     (currentPage - 1) * rowsPerPage,
// //     currentPage * rowsPerPage
// //   );

// //   const handlePageChange = (event, page) => setCurrentPage(page);

// //   // Handle download of table data
// //   const handleDownload = () => {
// //     const worksheet = XLSX.utils.json_to_sheet(allCustomers);
// //     const workbook = XLSX.utils.book_new();
// //     XLSX.utils.book_append_sheet(workbook, worksheet, 'Customers');
// //     const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
// //     const data = new Blob([excelBuffer], { type: 'application/octet-stream' });
// //     saveAs(data, 'CustomerData.xlsx');
// //   };

// //   // Handle dropdown menu
// //   const handleMenuOpen = (event) => setAnchorEl(event.currentTarget);
// //   const handleMenuClose = () => setAnchorEl(null);

// //   return (
// //     <Box sx={{ padding: 2 }}>
// //       <Grid sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
// //         <Typography variant="h6" gutterBottom>
// //           New Customers
// //         </Typography>


// //         <IconButton onClick={handleDownload}>
// //           <HiDownload />
// //         </IconButton>
// //       </Grid>
// //       <TableContainer component={Paper}>
// //         <Table>
// //           <TableHead>
// //             <TableRow sx={{ background: '#C38F51' }}>
// //               {tableHeadData.map((data, index) => (
// //                 <TableCell key={index} sx={{ color: 'white', padding: '8px' }}>
// //                   {data}
// //                 </TableCell>
// //               ))}
// //             </TableRow>
// //           </TableHead>
// //           <TableBody>
// //             {paginatedCustomers.map((customer, index) => (
// //               <TableRow
// //                 key={customer.id}

// //                 sx={{ backgroundColor: index % 2 === 0 ? '#FFFFFF' : '#F7F7F7', color: '#7A7A7A', P: "8px" }}
// //               >
// //                 <TableCell style={{ color: '#7A7A7A', p: "8px" }}>
// //                   {(currentPage - 1) * rowsPerPage + index + 1}
// //                 </TableCell>
// //                 {Object.keys(customer).map(
// //                   (key) =>
// //                     key !== 'id' && (
// //                       <TableCell key={key} style={{ color: '#7A7A7A', padding: "8px" }}>
// //                         {customer[key]}
// //                       </TableCell>
// //                     )
// //                 )}
// //                 <TableCell>
// //                   <IconButton onClick={handleMenuOpen} color="primary">
// //                     <HiPencil />
// //                   </IconButton>
// //                   <Menu
// //                     sx={{ boxShadow: "none" }}
// //                     anchorEl={anchorEl}
// //                     open={Boolean(anchorEl)}
// //                     onClose={handleMenuClose}
// //                   >
// //                     <MenuItem
// //                       sx={{ boxShadow: "none" }}

// //                       onClick={handleDownload}>
// //                       <ListItemIcon>
// //                         <HiDownload />
// //                       </ListItemIcon>
// //                       <ListItemText primary="Download" />
// //                     </MenuItem>
// //                     <MenuItem onClick={handleMenuClose}>
// //                       <ListItemIcon>
// //                         <HiPencil />
// //                       </ListItemIcon>
// //                       <ListItemText primary="Edit" />
// //                     </MenuItem>
// //                   </Menu>
// //                 </TableCell>
// //               </TableRow>
// //             ))}
// //           </TableBody>
// //         </Table>
// //       </TableContainer>

// //     </Box>
// //   );
// // };

// // export default CustomTable;


// import React, { useState } from 'react';
// import {
//   Table,
//   TableBody,
//   TableCell,
//   TableContainer,
//   TableHead,
//   TableRow,
//   Paper,
//   IconButton,
//   Typography,
//   Box,
//   Grid,
//   Menu,
//   MenuItem,
//   ListItemIcon,
//   ListItemText,
//   Divider,
// } from '@mui/material';
// import { HiDownload, HiPencil } from 'react-icons/hi';
// import { saveAs } from 'file-saver';
// import * as XLSX from 'xlsx';
// import { BsThreeDotsVertical } from "react-icons/bs";
// import { FaEye } from "react-icons/fa6";
// import { FaFileDownload } from "react-icons/fa";

// import jsPDF from "jspdf";
// import "jspdf-autotable";


// const CustomTable = ({ tableData, tableHeadData }) => {
//   const rowsPerPage = 5;

//   const [searchQuery, setSearchQuery] = useState('');
//   const [currentPage, setCurrentPage] = useState(1);
//   const [anchorEl, setAnchorEl] = useState(null);

//   const filteredCustomers = tableData.filter((customer) =>
//     customer.title.toLowerCase().includes(searchQuery.toLowerCase())
//   );

//   const totalPages = Math.ceil(filteredCustomers.length / rowsPerPage);
//   const paginatedCustomers = filteredCustomers.slice(
//     (currentPage - 1) * rowsPerPage,
//     currentPage * rowsPerPage
//   );

//   const handlePageChange = (event, page) => setCurrentPage(page);

//   // Handle download of table data

//   const handleDownload = () => {
//     const doc = new jsPDF();

//     // Table headers
//     const headers = Object.keys(tableData[0]); // Assuming all rows have the same structure

//     // Table rows
//     const rows = tableData.map((row) => headers.map((header) => row[header]));

//     // Add title
//     doc.text("Customer Data", 14, 10);

//     // Add table to PDF
//     doc.autoTable({
//       head: [headers],
//       body: rows,
//       startY: 20, // Position where the table starts
//     });

//     // Save the PDF
//     doc.save("CustomerData.pdf");
//   };

//   // Handle dropdown menu
//   const handleMenuOpen = (event) => setAnchorEl(event.currentTarget);
//   const handleMenuClose = () => setAnchorEl(null);

//   return (
//     <Box
//     // sx={{ padding: 2 }}
//     >
//       <Grid container justifyContent="end" alignItems="end">

//         <IconButton onClick={handleDownload}>
//           <HiDownload />
//         </IconButton>
//       </Grid>
//       <TableContainer component={Paper}>
//         <Table>
//           <TableHead>
//             <TableRow sx={{ background: '#C38F51' }}>
//               {tableHeadData.map((data, index) => (
//                 <TableCell key={index}
//                   // sx={{ color: 'white', padding: '8px' }}
//                   sx={{
//                     color: 'white',
//                     padding: '8px',
//                     whiteSpace: 'nowrap',  // Prevents text from wrapping
//                     overflow: 'hidden',    // Hides overflow text
//                     textOverflow: 'ellipsis', // Adds ellipsis for overflow text
//                     textAlign: 'left',     // Align text to the left (adjust as needed)
//                     // width: '100%'          // Ensures the cell takes full width
//                   }}
//                 >
//                   <Typography variant='subtitle2'>
//                     {data}
//                   </Typography>

//                 </TableCell>
//               ))}
//             </TableRow>
//           </TableHead>
//           <TableBody>
//             {paginatedCustomers.map((customer, index) => (
//               <TableRow
//                 key={customer.id}
//                 sx={{ backgroundColor: index % 2 === 0 ? '#FFFFFF' : '#F7F7F7' }}
//               >
//                 <TableCell sx={{
//                   // color: '#7A7A7A', padding: '8px'
//                   color: '#7A7A7A', padding: '8px',
//                   whiteSpace: 'nowrap',  // Prevents text from wrapping
//                   overflow: 'hidden',    // Hides overflow text
//                   textOverflow: 'ellipsis', // Adds ellipsis for overflow text
//                   textAlign: 'left',     // Align text to the left (adjust as needed)
//                   // width: '100%'
//                 }}>
//                   <Typography variant='body1'>
//                     {(currentPage - 1) * rowsPerPage + index + 1}


//                   </Typography>
//                 </TableCell>
//                 {Object.keys(customer).map(
//                   (key) =>
//                     key !== 'id' && (
//                       <TableCell key={key} sx={{
//                         color: '#7A7A7A', padding: '8px',
//                         whiteSpace: 'nowrap',  // Prevents text from wrapping
//                         overflow: 'hidden',    // Hides overflow text
//                         textOverflow: 'ellipsis', // Adds ellipsis for overflow text
//                         textAlign: 'left',     // Align text to the left (adjust as needed)
//                         // width: '100%'
//                       }}>
//                         <Typography variant='body1'>
//                           {customer[key]}
//                         </Typography>

//                       </TableCell>
//                     )
//                 )}
//                 <TableCell sx={{ padding: '8px' }}>
//                   <IconButton onClick={handleMenuOpen} color="black">
//                     <BsThreeDotsVertical fontSize="14px" />
//                   </IconButton>
//                   <Menu
//                     anchorEl={anchorEl}
//                     open={Boolean(anchorEl)}
//                     onClose={handleMenuClose}
//                     sx={{
//                       '& .MuiPaper-root': {
//                         boxShadow: 'none',
//                         border: "1px solid black",
//                         fontSize: "10px"
//                       },
//                     }}
//                   >
//                     <MenuItem onClick={handleDownload} sx={{ boxShadow: 'none', fontSize: '10px' }}>
//                       <ListItemIcon>
//                         <FaFileDownload style={{ fontSize: "16px", color: "#C38F51" }} />
//                       </ListItemIcon>
//                       <ListItemText primary="Download Certificate"
//                         sx={{

//                           '& .MuiTypography-root': {
//                             fontSize: "15px"
//                           }
//                         }}
//                       />
//                     </MenuItem>
//                     <Divider />
//                     <MenuItem onClick={handleMenuClose} sx={{ boxShadow: 'none', fontSize: '10px' }}>
//                       <ListItemIcon>
//                         <FaEye style={{ fontSize: "16px", color: "#C38F51" }} />
//                       </ListItemIcon>
//                       <ListItemText primary="View"
//                         sx={{

//                           '& .MuiTypography-root': {
//                             fontSize: "15px"
//                           }
//                         }} />
//                     </MenuItem>
//                   </Menu>
//                 </TableCell>
//               </TableRow>
//             ))}
//           </TableBody>
//         </Table>
//       </TableContainer>
//       <Box sx={{ display: "flex", justifyContent: "end" }}>
//         <Pagination
//           count={pageCount}
//           page={currentPage + 1}
//           onChange={handlePageChange}
//           siblingCount={1}
//           boundaryCount={1}
//           shape="rounded"
//           sx={{
//             mt: 2,
//             '& .MuiPaginationItem-root': {
//               color: '#C38F51', // Default text color
//               border: '1px solid #C38F51', // Default border color
//               '&:hover': {
//                 backgroundColor: 'transparent', // Removes hover background color
//               },
//               '&.Mui-selected': {
//                 backgroundColor: '#C38F51', // Selected background color
//                 color: 'white', // Selected text color
//                 border: 'none', // Remove border for selected items
//                 '&:hover': {
//                   backgroundColor: '#C38F51', // Ensure hover does not change selected color
//                 },
//               },
//             },
//           }}
//         />
//       </Box>
//     </Box>
//   );
// };

// export default CustomTable;



import React, { useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Typography,
  Box,
  Grid,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Divider,
  Pagination, // Import Pagination component
} from '@mui/material';
import { HiDownload } from 'react-icons/hi';
import jsPDF from "jspdf";
import "jspdf-autotable";
import { BsThreeDotsVertical } from "react-icons/bs";
import { FaEye, FaFileDownload } from "react-icons/fa";
import { format } from 'date-fns';

const CustomTable = ({ tableData, tableHeadData }) => {
  const rowsPerPage = 5;

  const [searchQuery, setSearchQuery] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [anchorEl, setAnchorEl] = useState(null);

  const filteredCustomers = tableData.filter((customer) =>
    customer.title.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const totalPages = Math.ceil(filteredCustomers.length / rowsPerPage);
  const paginatedCustomers = filteredCustomers.slice(
    (currentPage - 1) * rowsPerPage,
    currentPage * rowsPerPage
  );

  const handlePageChange = (event, page) => setCurrentPage(page);

  const handleDownload = () => {
    const doc = new jsPDF();
    const headers = Object.keys(tableData[0]);
    const rows = tableData.map((row) => headers.map((header) => row[header]));
    doc.text("Customer Data", 14, 10);
    doc.autoTable({
      head: [headers],
      body: rows,
      startY: 20,
    });
    doc.save("CustomerData.pdf");
  };

  const handleMenuOpen = (event) => setAnchorEl(event.currentTarget);
  const handleMenuClose = () => setAnchorEl(null);

  return (
    <Box>
      <Grid container justifyContent="end" alignItems="end">
        <IconButton onClick={handleDownload}>
          <HiDownload />
        </IconButton>
      </Grid>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow sx={{ background: '#C38F51' }}>
              {tableHeadData.map((data, index) => (
                <TableCell key={index} sx={{
                  color: 'white', padding: '8px', whiteSpace: 'nowrap',
                  overflow: 'hidden', textOverflow: 'ellipsis', textAlign: 'left',
                }}>
                  <Typography variant='subtitle2'>{data}</Typography>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {paginatedCustomers.map((customer, index) => (
              <TableRow key={customer.id} sx={{ backgroundColor: index % 2 === 0 ? '#FFFFFF' : '#F7F7F7' }}>
                <TableCell sx={{ color: '#7A7A7A', padding: '8px' }}>
                  <Typography variant='body1'>{(currentPage - 1) * rowsPerPage + index + 1}</Typography>
                </TableCell>
                {Object.keys(customer).map((key) =>
                  key !== 'id' && (
                    <TableCell key={key} sx={{
                      color: '#7A7A7A', padding: '8px', whiteSpace: 'nowrap',
                      overflow: 'hidden', textOverflow: 'ellipsis', textAlign: 'left',
                    }}>

                      <Typography variant='body1'>
                        {key === 'stake_starting' || key === 'stake_maturity' ?
                          (customer[key] && !isNaN(new Date(customer[key]))
                            ? format(new Date(customer[key]), 'MMMM d, yyyy')
                            : 'Invalid Date')
                          : customer[key]}
                      </Typography>
                      {/* <Typography variant='body1'>{customer[key]}</Typography> */}
                    </TableCell>
                  )
                )}
                <TableCell sx={{ padding: '8px' }}>
                  <IconButton onClick={handleMenuOpen} color="black">
                    <BsThreeDotsVertical fontSize="14px" />
                  </IconButton>
                  <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
                    <MenuItem onClick={handleDownload}>
                      <ListItemIcon>
                        <FaFileDownload style={{ fontSize: "16px", color: "#C38F51" }} />
                      </ListItemIcon>
                      <ListItemText primary="Download Certificate" />
                    </MenuItem>
                    <Divider />
                    <MenuItem onClick={handleMenuClose}>
                      <ListItemIcon>
                        <FaEye style={{ fontSize: "16px", color: "#C38F51" }} />
                      </ListItemIcon>
                      <ListItemText primary="View" />
                    </MenuItem>
                  </Menu>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Box sx={{ display: "flex", justifyContent: "end" }}>
        <Pagination
          count={totalPages} // Use totalPages instead of pageCount
          page={currentPage}
          onChange={handlePageChange}
          siblingCount={1}
          boundaryCount={1}
          shape="rounded"
          sx={{
            mt: 2,
            '& .MuiPaginationItem-root': {
              color: '#C38F51',
              border: '1px solid #C38F51',
              '&:hover': {
                backgroundColor: 'transparent',
              },
              '&.Mui-selected': {
                backgroundColor: '#C38F51',
                color: 'white',
                border: 'none',
                '&:hover': {
                  backgroundColor: '#C38F51',
                },
              },
            },
          }}
        />
      </Box>
    </Box>
  );
};

export default CustomTable;
