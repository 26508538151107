



// import React from "react";
// import { Box, Typography, Grid } from "@mui/material";
// import StorefrontIcon from "@mui/icons-material/Storefront";
// import styled from "@emotion/styled";
// import ProsperityCard from "../../../Components/ProsperityCard/ProsperityCard";
// import ReferEranCards from "./ReferEranCard";

// const DashedArrow = styled("div")(() => ({
//   position: "absolute",
//   borderTop: "2px dashed black",
//   width: "calc(33% - 40px)", // Adjust the width for proper spacing
//   top: "50%",
//   transform: "translateY(-50%)",
//   left: "calc(33% - 20px)", // Adjust for spacing between cards
//   "&:after": {
//     content: '""',
//     position: "absolute",
//     top: "-6px",
//     right: "-12px",
//     width: "0",
//     height: "0",
//     borderLeft: "8px solid transparent",
//     borderRight: "8px solid transparent",
//     borderTop: "8px solid black",
//   },
// }));


// const Refer_EarnPage = ({ threeStepsDates }) => {
//   const steps = threeStepsDates?.[0]?.additional_data?.card_design || [
//     {
//       title: "Lorem Ipsum",
//       content:
//         "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s",
//       logo: '<StorefrontIcon sx={{ fontSize: 40 }} />',
//     },
//     {
//       title: "Lorem Ipsum",
//       content:
//         "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s",
//       logo: '<StorefrontIcon sx={{ fontSize: 40 }} />',
//     },
//     {
//       title: "Lorem Ipsum",
//       content:
//         "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s",
//       logo: '<StorefrontIcon sx={{ fontSize: 40 }} />',
//     },
//   ];


//   return (
//     <>
//       <Box sx={{ p: 4 }}>
//         <Box sx={{ textAlign: "center", mb: 4 }}>
//           <Typography
//             variant="h3"
//             gutterBottom
//             sx={{ paddingBottom: "5px" }}
//           >
//             {threeStepsDates?.[0]?.sub_heading || "Default Sub-heading"}
//             <span style={{ color: "black", fontWeight: "800" }}>{threeStepsDates?.[0]?.heading || "Default Heading"}</span>
//           </Typography>
//           <Typography
//             variant="body1"
//             sx={{
//               paddingBottom: "5px",
//               maxWidth: "900px",
//               margin: "0 auto",
//               textAlign: "center",
//               color: "#b0aaaa",
//               // fontSize: "14px",
//             }}
//           >
//             {threeStepsDates?.[0]?.content || "Default Content"}

//           </Typography>
//         </Box>

//         {/* <Box sx={{ position: "relative", display: "flex", justifyContent: "space-between" }}>
//           <Grid container justifyContent="center" spacing={4} alignItems="center">
//             {steps.map((item, index) => (
//               <Grid item xs={12} sm={4} key={index}>
//                 <ProsperityCard
//                   icon={item.icon}
//                   title={item.title}
//                   description={item.description}
//                 />
//                 <Box sx={{ border: "1px dashed red" }}> </Box>

//               </Grid>

//             ))}
//           </Grid> */}
//         <Box sx={{ position: "relative", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
//           <Grid container justifyContent="center" spacing={4} alignItems="center">
//             {steps.map((item, index) => (
//               <Grid item xs={12} sm={4} key={index} sx={{ position: "relative" }}>
//                 <ReferEranCards
//                   icon={item.logo}
//                   title={item.title}
//                   description={item.content}
//                 />
//                 {/* Curved Arrow */}
//                 {/* {index < steps.length - 1 && (
//                   <Box
//                     sx={{
//                       position: "absolute",
//                       top: "50%", // Center vertically
//                       right: "-50px", // Adjust based on curve size
//                       width: "100px", // Width of the arrow
//                       height: "50px", // Height of the curve
//                       border: "none",
//                       borderTop: "2px dashed #ccc", // Dashed line
//                       borderRadius: "50%", // Makes it curved
//                       transform: "translateY(-50%)",
//                       zIndex: -1,
//                     }}
//                   >
//                     <Box
//                       sx={{
//                         position: "absolute",
//                         top: "50%",
//                         right: "-10px", // Position for arrowhead
//                         width: "0",
//                         height: "0",
//                         borderLeft: "6px solid #ccc",
//                         borderTop: "6px solid transparent",
//                         borderBottom: "6px solid transparent",
//                         transform: "rotate(45deg)",
//                       }}
//                     />
//                   </Box>
//                 )} */}
//               </Grid>
//             ))}
//           </Grid>
//         </Box>


//         {/* Add the dashed arrows between the cards */}
//         {/* {steps.slice(0, -1).map((_, index) => (
//             <DashedArrow key={index} style={{ left: `${(index + 1) * 33.33}%` }} />
//           ))} */}
//         {/* </Box> */}
//       </Box>
//     </>
//   );
// };

// export default Refer_EarnPage;




import React from "react";
import { Box, Typography, Grid } from "@mui/material";
import StorefrontIcon from "@mui/icons-material/Storefront";
import styled from "@emotion/styled";
import ProsperityCard from "../../../Components/ProsperityCard/ProsperityCard";
import arrowUpImageCard from "../../../Assets/arrowUpImage.svg"
import arrowDowmImage from "../../../Assets/arrowdDownImage.svg"

const DashedArrow = styled("div")(() => ({
  position: "absolute",
  borderTop: "2px dashed black",
  width: "calc(33% - 40px)", // Adjust the width for proper spacing
  top: "50%",
  transform: "translateY(-50%)",
  left: "calc(33% - 20px)", // Adjust for spacing between cards
  "&:after": {
    content: '""',
    position: "absolute",
    top: "-6px",
    right: "-12px",
    width: "0",
    height: "0",
    borderLeft: "8px solid transparent",
    borderRight: "8px solid transparent",
    borderTop: "8px solid black",
  },
}));


const Refer_EarnPage = ({ stakeNowDates }) => {
  const steps =
    stakeNowDates?.[0]?.additional_data?.card_design || [
      {
        title: "Step One",
        content:
          "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
        logo: "",
      },
      {
        title: "Step Two",
        content:
          "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
        logo: '',
      },
      {
        title: "Step Three",
        content:
          "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
        logo: '',
      },
    ];

  return (
    <>
      <Box sx={{ px: 2, py: 4, pb: 6 ,background:"white"}}>
        <Box sx={{ textAlign: "center", mb: 4 }}>
          <Typography
            variant="h3"
            gutterBottom
            sx={{
              paddingBottom: "5px",
              //  fontSize: "30px" 
            }}
          >
            <span style={{ color: "black", fontWeight: "800" }}>{stakeNowDates?.[0]?.sub_heading || "Default Sub-heading"}</span> {stakeNowDates?.[0]?.heading || "Default Heading"}
          </Typography>
          <Typography
            variant="body1"
            sx={{
              paddingBottom: "5px",

              maxWidth: "800px",
              margin: "0 auto",
              textAlign: "center",
              color: "#b0aaaa",

            }}
          >
            {stakeNowDates?.[0]?.content || "Default Content"}

          </Typography>
        </Box>

        {/* <Box sx={{ position: "relative", display: "flex", justifyContent: "space-between" }}>
          <Grid container justifyContent="center" spacing={4} alignItems="center">
            {steps.map((item, index) => (
              <Grid item xs={12} sm={4} key={index}>
                <ProsperityCard
                  icon={item.icon}
                  title={item.title}
                  description={item.description}
                />
                <Box sx={{ border: "1px dashed red" }}> </Box>

              </Grid>

            ))}
          </Grid> */}

        <Box
          sx={{
            position: "relative",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Grid container justifyContent="center" spacing={4} alignItems="center">
            {steps.map((item, index) => (
              <Grid item xs={12} sm={4} key={index} sx={{ position: "relative" }}>
                {/* Upward Arrow */}
                {index > 0 && index % 2 === 0 && (
                  <Box
                    sx={{
                      position: { xs: "none", md: "absolute" },
                      top: { sm: "26px" }, // Adjust for mobile and larger screens
                      left: { sm: "-95%" },
                      transform: "translateX(-50%)",
                      display: { xs: "none", md: "block" }, // Hide on mobile, show on desktop
                    }}
                  >
                    <img
                      src={arrowUpImageCard}
                      alt="Upward Arrow"
                      style={{
                        height: "auto", // Maintain aspect ratio
                        maxWidth: "183px", // Limit the maximum size
                      }}
                    />
                  </Box>
                )}

                {/* Card */}
                <ProsperityCard
                  icon={item.logo}
                  title={item.title}
                  description={item.content}
                />

                {/* Downward Arrow */}
                {index < steps.length - 1 && index % 2 !== 0 && (
                  <Box
                    sx={{
                      position: { xs: "none", md: "absolute" },
                      bottom: { xs: "50px", sm: "105px" },
                      left: { xs: "90%", sm: "107%" },
                      transform: "translateX(-50%)",
                      display: { xs: "none", md: "block" }, // Hide on mobile, show on desktop
                    }}
                  >
                    <img
                      src={arrowDowmImage}
                      alt="Downward Arrow"
                      style={{
                        height: "auto", // Maintain aspect ratio
                        maxWidth: "182px", // Limit the maximum size
                      }}
                    />
                  </Box>
                )}
              </Grid>
            ))}
          </Grid>
        </Box>



        {/* Add the dashed arrows between the cards */}
        {/* {steps.slice(0, -1).map((_, index) => (
            <DashedArrow key={index} style={{ left: `${(index + 1) * 33.33}%` }} />
          ))} */}
        {/* </Box> */}
      </Box>
    </>
  );
};

export default Refer_EarnPage;
