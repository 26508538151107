

// import React from 'react';

// import { IconButton, Fab } from "@mui/material";
// import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

// import StakeNowMainPage from '../StakeNowMainPage/StakeNowMainPage';

// import Pusrchase_GoldStake from '../Purchase&GoldStack/Pusrchase_GoldStake';
// import ReferSection from '../../HomeSection/ReferSection/ReferSection';
// import PurchaseSchemes from '../Purchase&Schemes/PurchaseSchemes';


// const StakeNowHome = () => {
//   const handleScrollToTop = () => {
//     window.scrollTo({ top: 0, behavior: "smooth" });
//   };
//   return (

//     <div style={{ position: "relative" }}>
//       {/* <MyAppBar /> */}

//       <StakeNowMainPage />
//       <Pusrchase_GoldStake />
//       <ReferSection />
//       <PurchaseSchemes />

//       {/* <Footer /> */}

//       {/* <Fab
//         size="small"
//         aria-label="scroll to top"
//         onClick={handleScrollToTop}
//         sx={{
//           position: "sticky",
//           bottom: 0,
//           right: 0,
//           // top: -30,
//           borderRadius: "10px",
//           boxShadow: "none",
//           transform: "translateX(50%)",
//           backgroundColor: "red",
//           color: "#C38F51",
//           "&:hover": {
//             backgroundColor: "black",
//             color: "white"
//           },
//         }}
//       >
//         <KeyboardArrowUpIcon />
//       </Fab> */}

//     </div>
//   )
// }


// export default StakeNowHome;






import React, { useEffect, useRef, useState } from 'react';

import { IconButton, Fab } from "@mui/material";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

import StakeNowMainPage from '../StakeNowMainPage/StakeNowMainPage';

import Pusrchase_GoldStake from '../Purchase&GoldStack/Pusrchase_GoldStake';

import PurchaseSchemes from '../Purchase&Schemes/PurchaseSchemes';
import actions from "../../../ReduxStore/actions/index";
import { useDispatch, useSelector } from "react-redux";
import { CircularProgress, Box } from '@mui/material';
import ReferSection from '../ReferSection/ReferSection';


const StakeNowHome = () => {
  const [loading, setLoading] = useState(true);
  const handleScrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };



  const dispatch = useDispatch();

  const { getAllstakeNowSections } = useSelector((state) => state?.getAllstakeNowSections);

  console.log(getAllstakeNowSections);


  useEffect(() => {
    const fetchData = async () => {
      const data1 = { data: {}, method: "get", apiName: "getAllStakeNowSections" };



      setLoading(true); // Set loading to true when the request starts
      await dispatch(actions.GETALLSTAKENOWSECTIONS(data1));
      setLoading(false); // Set loading to false after data is fetched
    };
    fetchData();
  }, [dispatch]);


  // Function to get dates by section_name
  const getDatesBySectionName = (sectionName) => {
    return getAllstakeNowSections?.data
      ?.filter((section) => section.section_name === sectionName)
      || [];
  };


  const bannerDates = getAllstakeNowSections?.data
    ? getDatesBySectionName('banner')
    : [];

  const stakeNowDates = getAllstakeNowSections?.data
    ? getDatesBySectionName('stakeNow')
    : [];

  const referralsDates = getAllstakeNowSections?.data
    ? getDatesBySectionName('referrals')
    : [];



  console.log(bannerDates, "getAllstakeNowSections bannerDates ");

  const targetRef = useRef(null); // Create a reference for the target element

  const handleScrollToTarget = () => {
    if (targetRef.current) {
      targetRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };



  return (

    <div style={{ position: "relative" }}>
      {loading ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="100vh"
          style={{ background: "#fff", zIndex: 10 }}
        >
          <CircularProgress style={{ color: 'gold' }} /> {/* Gold spinner */}
        </Box>
      ) : (
        <>
          <StakeNowMainPage bannerDates={bannerDates} onScrollToTarget={handleScrollToTarget}/>
          <Pusrchase_GoldStake stakeNowDates={stakeNowDates} />
          {/* <ReferSection referralsDates={referralsDates} /> */}
          <ReferSection referralsDates={referralsDates} />
          <PurchaseSchemes targetRef={targetRef}/>
        </>
      )}
      {/* <Footer /> */}

      {/* <Fab
        size="small"
        aria-label="scroll to top"
        onClick={handleScrollToTop}
        sx={{
          position: "sticky",
          bottom: 0,
          right: 0,
          // top: -30,
          borderRadius: "10px",
          boxShadow: "none",
          transform: "translateX(50%)",
          backgroundColor: "red",
          color: "#C38F51",
          "&:hover": {
            backgroundColor: "black",
            color: "white"
          },
        }}
      >
        <KeyboardArrowUpIcon />
      </Fab> */}

    </div>
  )
}


export default StakeNowHome;



