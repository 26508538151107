
import React from "react";
import { Box, Button, Typography, Container, Stack, Grid } from "@mui/material";
import downloadPhoneImage from "../../../Assets/downloadPhoneImage.png"; // Ensure this is the correct path
import financialImageBackground from "../../../Assets/financialImageBackgroud.svg"; // Ensure this is the correct path
import downoadPlayStore from "../../../Assets/downoadPlayStore.png"; // Ensure this is the correct path

const DownloadPhone = ({ referralsDates }) => {

    return (
        <Box
            sx={{
                position: "relative",
                // background: "#F7F7F7",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                overflow: "hidden",
                padding: {xs:"30px 0px 10px 0",md:"50px 0px"}
            }}
        >
            <Container maxWidth="lg" sx={{ zIndex: 2 }}>
                <Grid
                    container
                    xs={12}
                >


                    {/* Left Section */}
                    <Grid
                        item
                        md={8}
                        sx={{ color: "#fff", display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <Box>
                            <Typography
                                variant="h1"
                                textAlign="start"
                                sx={{
                                    fontSize: { xs: "30px", md: "40px" },

                                    // maxWidth: "600px",
                                    color: "#3B4056",
                                    mb: 1,

                                }}
                            >
                                {/* {referralsDates?.[0]?.heading || "Default Sub-heading"} */}
                                Download our mobile app & Earn Gold Anywhere Anytime

                            </Typography>


                            <Typography
                                variant="body1"
                                textAlign="start"
                                sx={{
                                    mt: 2,

                                    mb: 3,
                                    lineHeight: "1.6",
                                    color: "#7A7A7A",
                                    maxWidth: "600px",
                                    fontSize: "14px",
                                    letterSpacing: "0px",
                                    opacity: 1,
                                }}
                            >
                                {/* {referralsDates?.[0]?.content || "Default Sub-heading"} */}
                                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s, when an unknown

                            </Typography>

                            <Box
                                component="img"
                                src={downoadPlayStore}
                                sx={{
                                    height: { xs: "30px", md: "50px" },
                                    width: { xs: "200px", md: "300px" }

                                }}
                            >

                            </Box>



                        </Box>
                    </Grid>
                    {/* Right Section: Image */}
                    <Grid
                        item
                        md={4}
                        sx={{
                            display: "flex", // Flexbox to apply justifyContent and alignItems
                            justifyContent: "center", // Center horizontally
                            alignItems: "center", // Center vertically

                            position: "relative", // Needed for z-index control
                            zIndex: 0, // Keep it behind other elements
                            display: {
                                xs: "none", // Hide on extra small screens
                                sm: "flex", // Show on small screens and above
                            },
                        }}
                    >
                        <img src={downloadPhoneImage} alt="Refer" style={{ height: "300px", width: "150px", zIndex: 1, position: "relative" }} />
                    </Grid>

                </Grid>
            </Container>
        </Box >
    );
};

export default DownloadPhone;
