

import React from "react";
import {
    Box,
    Card,
    CardMedia,
    CardContent,
    Typography,
    Button,
    Grid,
    Avatar,
    IconButton,
} from "@mui/material";
import { AccessTime, DoNotTouchRounded, Person } from "@mui/icons-material";
import LatestNewsImage from "../../Assets/latestNewsImage.jpg"
import { FaRegUser } from "react-icons/fa";
import { GoDotFill } from "react-icons/go";
import { adminUrl } from "../../constants";



const RecentPosts = ({ recentPostDates }) => {



    const data = recentPostDates?.[0]?.additional_data?.card_design || [
        {
            "banner": "uploads/images/uploadImage-1734597334354.png",
            "title": "local Allemagne",
            "secondary_title": "Discover the Golden Opportunity",
            // "date": "24 Jan 2024",
            "content": "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book."
            // "primary_button_text": "Let’s Explore..."
        },
        {
            "banner": "uploads/images/uploadImage-1734597334354.png",
            "title": "local Allemagne",
            "secondary_title": "Discover the Golden Opportunity",
            // "date": "24 Jan 2024",
            "content": "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book."
            // "primary_button_text": "Let’s Explore..."
        },
        {
            "banner": "uploads/images/uploadImage-1734597334354.png",
            "title": "local Allemagne",
            "secondary_title": "Discover the Golden Opportunity",
            // "date": "24 Jan 2024",
            "content": "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book."
            // "primary_button_text": "Let’s Explore..."
        },
        {
            "banner": "uploads/images/uploadImage-1734597334354.png",
            "title": "local Allemagne",
            "secondary_title": "Discover the Golden Opportunity",
            // "date": "24 Jan 2024",
            "content": "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book."
            // "primary_button_text": "Let’s Explore..."
        }
    ]
    return (
        <Box sx={{ p: 3 }}>
            <Box sx={{ textAlign: "center", mb: 4, display: "flex", justifyContent: "center" }}>
                {/* Header Section */}
                <Box>
                    <Typography
                        variant="h3"
                        gutterBottom
                        sx={{ paddingBottom: "5px", maxWidth: "600px" }}
                    >
                        {recentPostDates?.[0]?.heading || "Default Heading"}
                        <span style={{ color: "black", fontWeight: "800" }}>  {recentPostDates?.[0]?.sub_heading || "Default Sub-heading"}</span>
                    </Typography>
                </Box>
            </Box>
            {/* Main Content */}


            {/* Right Smaller Cards */}
            <Grid item xs={12} md={6}>
                <Grid container spacing={4}>
                    {data.map((item, index) => (
                        <Grid item xs={12} key={index}>
                            <Card sx={{
                                display: { xs: "block", md: "flex", sm: "flex" }, p: 0, borderRadius: "0", boxShadow: "none"
                                // height: "170px" 
                            }}>
                                <CardMedia
                                    component="img"
                                    sx={{
                                        width: { xs: "100%", md: "275px", sm: "70%" }
                                        // height: "300px"
                                    }}
                                    image={`${adminUrl}/${item.banner}`} // Replace with actual image
                                    alt="Small Card Image"
                                />
                                <CardContent
                                    sx={{
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "space-between", // Ensures the content is spaced evenly
                                        alignItems: "flex-start", // Align content to the left
                                        // height: "170px",
                                        px: { sx: 0, xs: 2 },
                                        paddingBottom: 0, // Remove unnecessary padding
                                    }}
                                >
                                    <Box pb={0} sx={{
                                        width: "100%",
                                        // height: "170px",

                                    }}>
                                        <Box display="flex" alignItems="center"
                                            sx={{ gap: { xs: 1, md: 2 } }} mb={1}>
                                            <Typography
                                                variant="body1"
                                                sx={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent: "center",
                                                    color: "#C38F51",
                                                    // fontSize: { xs: "10px", md: "14px" },

                                                }}
                                            >
                                                {/* <FaRegUser style={{ marginRight: "5px" }} /> */}
                                                {item.title || "default title"}
                                                F
                                            </Typography>

                                        </Box>
                                        <Typography
                                            variant="body2"
                                            sx={{
                                                // fontSize: { xs: "12px", md: "16px" },
                                                // fontWeight: { xs: "400", md: "700" },
                                                mb: 1


                                            }}>
                                            {item.secondary_title || "default secondary_titles"}
                                        </Typography>
                                        <Typography variant="body1" color="text.secondary" sx={{ fontSize: "12px" }}>
                                            <Box
                                                sx={{
                                                    overflow: "hidden",
                                                    // width:"80%"
                                                    // maxHeight: { xs: "50%", md: "50px" },
                                                }}
                                            >
                                                {item.content || "default content"}

                                            </Box>

                                        </Typography>


                                    </Box>
                                </CardContent>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            </Grid>

        </Box>

    );
};

export default RecentPosts;
