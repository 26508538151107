




import React from "react";
import { Box, Button, Typography, Container, Stack } from "@mui/material";
import Referals_home_image from "../../../Assets/Referals_home_image.png";
import { AiFillThunderbolt } from "react-icons/ai";

import { adminUrl } from "../../../constants";

const ReferralsMainPage = ({ bannerDates,onScrollToTarget }) => {
    return (
        <Box
            sx={{
                position: "relative", // Needed for positioning child elements
                background: `linear-gradient(90deg, #000000 0%, #54545400 100%), url(${adminUrl}/${bannerDates?.[0]?.additional_data?.[0]?.banner})`,
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                overflow: "hidden",
                padding: "90px 20px",
                // left:"100px"
                // minHeight: "90vh", // Adjust height as needed

            }}
        >
            <Container maxWidth="lg" sx={{ zIndex: 2 }}>
                <Box
                    display="flex"
                    // alignItems="center"
                    justifyContent="start"
                    flexWrap="wrap"
                >
                    {/* Left Section */}
                    <Box
                        sx={{

                            color: "#fff",
                        }}
                    >
                        <Typography
                            variant="h1"
                            textAlign="start"

                        >

                            {bannerDates?.[0]?.heading || "Default Heading"}
                        </Typography>
                        <Typography
                            variant="h2"
                            textAlign="start"
                            sx={{

                                mb: 2,
                            }}
                        >
                            {bannerDates?.[0]?.sub_heading || "Default Sub-heading"}
                        </Typography>
                        <Typography
                            variant="body1"
                            textAlign="start"
                            sx={{
                                mb: 3,
                                lineHeight: "1.6",
                                color: "#ffffff",
                                // width:"55%",
                                maxWidth: "500px",

                                letterSpacing: "0px",
                                color: "#b0aaaa",
                                opacity: 1
                            }}
                        >
                            {bannerDates?.[0]?.content || "Default Content"}
                        </Typography>
                        <Stack spacing={2} direction="row">
                            <Button
                                variant="contained"
                                startIcon={<AiFillThunderbolt sx={{ fontSize: "12px", color: "white" }} />}

                                onClick={onScrollToTarget}
                                sx={{
                                    // backgroundColor: "#C38F51",
                                    textTransform: "none",
                                    color: "white",
                                    // fontSize: "14px",
                                    padding: "0px 40px",
                                    minHeight: "40px",
                                    borderRadius: "23px",
                                    background: "#C38F51 0% 0% no-repeat padding-box",
                                    "&:hover": {
                                        backgroundColor: "#C38F51",
                                    },
                                }}
                            >
                                {bannerDates?.[0]?.additional_data?.[0]?.primary_button_text || "Primary Button"}

                            </Button>

                        </Stack>
                    </Box>
                </Box>
            </Container >
        </Box >
    );
};

export default ReferralsMainPage;
