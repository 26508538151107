

/* eslint-disable no-sequences */
/* eslint-disable no-unused-vars */
/* eslint-disable no-param-reassign */
/* eslint-disable no-unused-expressions */

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { defaultReject, defaultState } from "../../../constants";
import { fetchData } from "../../../helpers";

// addMultiPurchase
// ADDMULTIPURCHASE
const ADDMULTIPURCHASE= createAsyncThunk(
  "addMultiPurchase/addMultiPurchase",
  // eslint-disable-next-line default-param-last
  async (
    // eslint-disable-next-line default-param-last
    payload = {},
    { rejectWithValue }
  ) => {
    try {
      const data = await fetchData(
        payload?.data,
        payload?.method,
        payload?.apiName
      );
      return {
        ...defaultState.List,
        message: data?.data.Message,
        data: data?.data?.data,
      };
    } catch (error) {
      return rejectWithValue({
        ...defaultReject.List,
        message: error.message,
      });
    }
  }
);

// PublishPostSlice
const addMultiPurchaseSlice = createSlice({
  name: "addMultiPurchaseSlice",
  initialState: {
    addMultiPurchase: {
      ...defaultState.List,
      loading: false, 
      error: false, 
    },
  },
  reducers: {
    setInitialStateOfaddMultiPurchase: (state) => {
        state.addMultiPurchase.loading = false
        state.addMultiPurchase.error = false
        state.addMultiPurchase.data = []
        state.addMultiPurchase.message = ''
      }
  },
  extraReducers: (builder) => {
    builder.addCase(ADDMULTIPURCHASE.fulfilled, (state, action) => {
      state.addMultiPurchase = {
        ...state.addMultiPurchase,
        loading: false,
        error: false,
        ...action.payload,
      };
    });
    builder.addCase(ADDMULTIPURCHASE.pending, (state, action) => {
      state.addMultiPurchase = {
        ...state.addMultiPurchase,
        loading: true,
        error: false,
        ...action.payload,
      };
    });
    builder.addCase(ADDMULTIPURCHASE.rejected, (state, action) => {
      state.addMultiPurchase = {
        ...state.addMultiPurchase,
        loading: false,
        error: true,
        ...action.payload,
      };
    });
  },
});

const addMultiPurchaseAction = {
    ADDMULTIPURCHASE,
};

export { addMultiPurchaseAction };
export default addMultiPurchaseSlice.reducer;
export const {setInitialStateOfaddMultiPurchase} = addMultiPurchaseSlice.actions