
import React, { useEffect, useState } from 'react';

// import ContactUsection from '../ContactUsection/ContactUsection';
// import actions from "../../../ReduxStore/actions/index";
import { CircularProgress, Box, Grid, styled } from '@mui/material';
import ContactUsMainPage from './ContactUsMainPage/ContactUsMainPage';
import ContactUsection from './ContactUsection/ContactUsection';
import CustomMap from '../../Components/CustomMap/CustomMap';
import actions from "../../ReduxStore/actions/index";
import { useDispatch, useSelector } from "react-redux";


const ContactUsHome = () => {
    const [loading, setLoading] = useState(false);
    const handleScrollToTop = () => {
        window.scrollTo({ top: 0, behavior: "smooth" });
    };

    const dispatch = useDispatch();
    const MapContainer = styled(Box)({
        height: "400px", // Set your desired height for the map
        width: "100%",
    });

    const { getAllContactUsSections } = useSelector((state) => state?.getAllContactUsSections);

    console.log(getAllContactUsSections);


    useEffect(() => {
        const fetchData = async () => {
            const data1 = { data: {}, method: "get", apiName: "getAllContactUsSections" };

            setLoading(true); // Set loading to true when the request starts
            await dispatch(actions.GETALLCONTACTUSSECTIONS(data1));
            setLoading(false); // Set loading to false after data is fetched
        };
        fetchData();
    }, [dispatch]);


    // Function to get dates by section_name
    const getDatesBySectionName = (sectionName) => {
        return getAllContactUsSections?.data
            ?.filter((section) => section.section_name === sectionName)
            || [];
    };


    const bannerDates = getAllContactUsSections?.data
        ? getDatesBySectionName('banner')
        : [];


    const getInTouchDates = getAllContactUsSections?.data
        ? getDatesBySectionName('getInTouch')
        : [];
    //   const stakeNowDates = getAllHomeSections?.data
    //     ? getDatesBySectionName('stakeNow')
    //     : [];

    //   const referralsDates = getAllHomeSections?.data
    //     ? getDatesBySectionName('referrals')
    //     : [];

    //   const offersDates = getAllHomeSections?.data
    //     ? getDatesBySectionName('offers')
    //     : [];


    //   const faqsDates = getAllHomeSections?.data
    //     ? getDatesBySectionName('faqs')
    //     : [];







    //   console.log(bannerDates, "section 1");
    //   console.log(stakeNowDates, "section 2");




    return (

        <div style={{ position: "relative" }}>
            {loading ? (
                <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    height="100vh"
                    style={{ background: "#fff", zIndex: 10 }}
                >
                    <CircularProgress style={{ color: 'gold' }} /> {/* Gold spinner */}
                </Box>
            ) : (
                <>
                    <ContactUsMainPage bannerDates={bannerDates} />
                    <ContactUsection getInTouchDates={getInTouchDates} />
                    <CustomMap />
                </>
            )}

            {/* <Fab
        size="small"
        aria-label="scroll to top"
        onClick={handleScrollToTop}
        sx={{
          position: "sticky",
          bottom: 0,
          right: 0,
          // top: -30,
          borderRadius: "10px",
          boxShadow: "none",
          transform: "translateX(50%)",
          backgroundColor: "red",
          color: "#C38F51",
          "&:hover": {
            backgroundColor: "black",
            color: "white"
          },
        }}
      >
        <KeyboardArrowUpIcon />
      </Fab> */}

        </div>
    )
}


export default ContactUsHome;



