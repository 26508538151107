

/* eslint-disable no-sequences */
/* eslint-disable no-unused-vars */
/* eslint-disable no-param-reassign */
/* eslint-disable no-unused-expressions */

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { defaultReject, defaultState } from "../../../constants";
import { fetchData } from "../../../helpers";
// customerRegister
// CUSTOMERREGISTER
const CUSTOMERREGISTER= createAsyncThunk(
  "customerRegister/customerRegister",
  // eslint-disable-next-line default-param-last
  async (
    // eslint-disable-next-line default-param-last
    payload = {},
    { rejectWithValue }
  ) => {
    try {
      const data = await fetchData(
        payload?.data,
        payload?.method,
        payload?.apiName
      );
      return {
        ...defaultState.List,
        message: data?.data.Message,
        data: data?.data?.data,
      };
    } catch (error) {
      return rejectWithValue({
        ...defaultReject.List,
        message: error.message,
      });
    }
  }
);

// PublishPostSlice
const customerRegisterSlice = createSlice({
  name: "customerRegisterSlice",
  initialState: {
    customerRegister: {
      ...defaultState.List,
      loading: false, 
      error: false, 
    },
  },
  reducers: {
    setInitialStateOfcustomerRegister: (state) => {
        state.customerRegister.loading = false
        state.customerRegister.error = false
        state.customerRegister.data = []
        state.customerRegister.message = ''
      }
  },
  extraReducers: (builder) => {
    builder.addCase(CUSTOMERREGISTER.fulfilled, (state, action) => {
      state.customerRegister = {
        ...state.customerRegister,
        loading: false,
        error: false,
        ...action.payload,
      };
    });
    builder.addCase(CUSTOMERREGISTER.pending, (state, action) => {
      state.customerRegister = {
        ...state.customerRegister,
        loading: true,
        error: false,
        ...action.payload,
      };
    });
    builder.addCase(CUSTOMERREGISTER.rejected, (state, action) => {
      state.customerRegister = {
        ...state.customerRegister,
        loading: false,
        error: true,
        ...action.payload,
      };
    });
  },
});

const customerRegisterAction = {
    CUSTOMERREGISTER,
};

export { customerRegisterAction };
export default customerRegisterSlice.reducer;
export const {setInitialStateOfcustomerRegister} = customerRegisterSlice.actions
