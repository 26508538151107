

// import React, { useState } from "react";
// import { Box, Button, Typography, Container, Stack, IconButton } from "@mui/material";
// // Ensure this is the correct path
// import refer_img from "../../../Assets/refer_img.svg"; // Ensure this is the correct path
// import { FaShareAlt } from "react-icons/fa";
// import facebook_icon from "../../../Assets/facebook_icon.svg";
// import whatsapp_icon from "../../../Assets/whatsapp_icon.svg";
// import instagram_icon from "../../../Assets/instagram_icon.svg";
// import linkedin_icon from "../../../Assets/linkedin_icon.svg";
// import twitter_icon from "../../../Assets/twitter_icon.svg";
// import { adminUrl } from "../../../constants";
// import refer_img_background from "../../../Assets/referrals_img_background.svg"



// const InviteFriends = ({ referralsDates, targetRef }) => {
//     const [showIcons, setShowIcons] = useState(false);

//     const handleButtonClick = () => {
//         setShowIcons(!showIcons); // Toggle the visibility of social media icons
//     };


//     return (
//         <Box
//             id="referpage"
//             ref={targetRef}
//             sx={{
//                 position: "relative", // Needed for positioning child elements
//                 background: "#F7F7F7",

//                 display: "flex",
//                 alignItems: "center",
//                 justifyContent: "space-between",
//                 overflow: "hidden",
//                 padding: "50px 0px",
//                 // left:"100px"
//                 // minHeight: "90vh", // Adjust height as needed
//             }}
//         >
//             <Container maxWidth="lg" sx={{ zIndex: 2 }}>
//                 <Box
//                     display="flex"
//                     // alignItems="center"
//                     justifyContent="space-between"
//                     flexWrap="wrap"
//                 >
//                     {/* Left Section */}
//                     <Box
//                         sx={{

//                             color: "#fff",
//                         }}
//                     >
//                         <Typography
//                             variant="h2"
//                             textAlign="start"
//                             sx={{

//                                 color: "#3B4056",
//                                 mb: 2
//                             }}
//                         >
//                             {referralsDates?.[0]?.heading || "Default Sub-heading"}

//                         </Typography>

//                         <Typography
//                             variant="body1"
//                             textAlign="start"
//                             sx={{
//                                 mb: 3,
//                                 lineHeight: "1.6",
//                                 color: "#7A7A7A",
//                                 // width:"55%",
//                                 maxWidth: "500px",

//                                 letterSpacing: "0px",
//                                 // color: "#b0aaaa",
//                                 opacity: 1
//                             }}
//                         >
//                             {referralsDates?.[0]?.content || "Default Sub-heading"}

//                         </Typography>
//                         <Stack spacing={2} direction={{ xs: 'column', md: 'row' }}>

//                             <Button
//                                 variant="outlined"

//                                 sx={{
//                                     // backgroundColor: "#C38F51",
//                                     textTransform: "none",
//                                     color: "#7A7A7A",

//                                     padding: "0px 50px",
//                                     height: "35px",
//                                     borderColor: "white",
//                                     borderRadius: "23px",
//                                     background: "white 0% 0% no-repeat padding-box",
//                                     "&:hover": {
//                                         backgroundColor: "white",
//                                     },
//                                 }}
//                             >
//                                 {referralsDates?.[0]?.additional_data?.[0]?.primary_button_text || "Primary Button"}


//                             </Button>
//                             {!showIcons && (
//                                 <Button
//                                     variant="contained"
//                                     startIcon={<FaShareAlt style={{ color: "white", fontSize: "14px" }} />}
//                                     onClick={handleButtonClick} // Trigger the handleButtonClick function on button click
//                                     sx={{
//                                         textTransform: "none",
//                                         color: "white",
//                                         padding: "0px 30px",
//                                         height: "35px",
//                                         borderRadius: "23px",
//                                         background: "#C38F51 0% 0% no-repeat padding-box",
//                                         "&:hover": {
//                                             backgroundColor: "#C38F51",
//                                         },
//                                     }}
//                                 >
//                                     {referralsDates?.[0]?.additional_data?.[0]?.secondary_button_text || "Primary Button"}
//                                 </Button>
//                             )}

//                             {showIcons && ( // Only show social media icons if showIcons is true
//                                 <Box
//                                     sx={{
//                                         position: "relative",
//                                         display: "flex",
//                                         justifyContent: "center",
//                                         alignItems: "center",
//                                         gap: 2,
//                                         // padding: "16px",
//                                     }}
//                                 >
//                                     {/* Social Media Icons */}
//                                     {[
//                                         { icon: facebook_icon, label: "Facebook" },
//                                         { icon: whatsapp_icon, label: "WhatsApp" },
//                                         { icon: instagram_icon, label: "Instagram" },
//                                         { icon: linkedin_icon, label: "LinkedIn" },
//                                         { icon: twitter_icon, label: "X" },
//                                     ].map((item, index) => (
//                                         <IconButton
//                                             key={index}
//                                             aria-label={item.label}
//                                             sx={{
//                                                 backgroundColor: "#C68A48",
//                                                 color: "white",
//                                                 padding: "12px",
//                                                 height: "40px",
//                                                 width: "40px",
//                                                 "&:hover": {
//                                                     backgroundColor: "#D9A766",
//                                                 },
//                                             }}
//                                         >
//                                             <img src={item.icon} alt={item.label} />
//                                         </IconButton>
//                                     ))}
//                                 </Box>
//                             )}
//                         </Stack>
//                     </Box>
//                     <Box
//                         sx={{
//                             display: "flex", // Flexbox to apply justifyContent and alignItems
//                             justifyContent: "center", // Center horizontally
//                             alignItems: "center", // Center vertically
//                             backgroundImage: `url(${refer_img_background})`, // Set background image
//                             backgroundSize: "contain", // Make the image cover the entire container
//                             backgroundRepeat: "no-repeat", // Prevent the image from repeating
//                             backgroundPosition: "center", // Center the image
//                             height: "300px", // Ensure it covers the full height of the container
//                             width: "100%", // Ensure it covers the full width of the container
//                             position: "relative", // Needed for z-index control
//                             zIndex: 0, // Keep it behind other elements
//                             right:0,
//                             display: {
//                                 xs: "none", // Hide on extra small screens
//                                 sm: "block", // Show on small screens and above
//                             },
//                         }}

//                     >
//                         <img src={`${adminUrl}/${referralsDates?.[0]?.additional_data?.[0]?.banner}`} style={{ height: "220px",zIndex: 1, position: "relative" }}></img>
//                     </Box>
//                 </Box>
//             </Container >
//         </Box >
//     );
// };

// export default InviteFriends;



import React, { useState } from "react";
import { Box, Button, Typography, Container, Stack, IconButton, Grid } from "@mui/material";
import { FaShareAlt } from "react-icons/fa";
import refer_img from "../../../Assets/refer_img.svg";
import facebook_icon from "../../../Assets/facebook_icon.svg";
import whatsapp_icon from "../../../Assets/whatsapp_icon.svg";
import instagram_icon from "../../../Assets/instagram_icon.svg";
import linkedin_icon from "../../../Assets/linkedin_icon.svg";
import twitter_icon from "../../../Assets/twitter_icon.svg";
import { adminUrl } from "../../../constants";
import refer_img_background from "../../../Assets/referrals_img_background.svg";

const InviteFriends = ({ referralsDates, targetRef }) => {
    const [showIcons, setShowIcons] = useState(false);

    const handleButtonClick = () => {
        setShowIcons(!showIcons); // Toggle the visibility of social media icons
    };

    return (
        <Box
            id="referpage"
            ref={targetRef}
            sx={{
                position: "relative", // This is key to make the image positioned relative to this container
                background: "#F7F7F7",
                overflow: "hidden",
                padding:{xs:"50px 0px"},
            }}
        >
            <Container maxWidth="lg" sx={{ zIndex: 2 }}>
                <Grid container spacing={4} alignItems="center">
                    {/* Left Section */}
                    <Grid item xs={12} md={6}>
                        <Box>
                            <Typography
                                variant="h2"
                                textAlign="start"
                                sx={{
                                    color: "#3B4056",
                                    mb: 2,
                                }}
                            >
                                {referralsDates?.[0]?.heading || "Default Heading"}
                            </Typography>
                            <Typography
                                variant="body1"
                                textAlign="start"
                                sx={{
                                    mb: 3,
                                    lineHeight: "1.6",
                                    color: "#7A7A7A",
                                    maxWidth: "500px",
                                }}
                            >
                                {referralsDates?.[0]?.content || "Default Sub-heading"}
                            </Typography>
                            <Stack spacing={2} direction={{ xs: "column", md: "row" }}>
                                <Button
                                    variant="outlined"
                                    sx={{
                                        textTransform: "none",
                                        color: "#7A7A7A",
                                        padding: "0px 50px",
                                        height: "35px",
                                        borderColor: "white",
                                        borderRadius: "23px",
                                        background: "white",
                                        "&:hover": {
                                            backgroundColor: "white",
                                        },
                                    }}
                                >
                                    {referralsDates?.[0]?.additional_data?.[0]?.primary_button_text || "Primary Button"}
                                </Button>
                                {!showIcons && (
                                    <Button
                                        variant="contained"
                                        startIcon={<FaShareAlt style={{ color: "white", fontSize: "14px" }} />}
                                        onClick={handleButtonClick}
                                        sx={{
                                            textTransform: "none",
                                            color: "white",
                                            padding: "0px 30px",
                                            height: "35px",
                                            borderRadius: "23px",
                                            background: "#C38F51",
                                            "&:hover": {
                                                backgroundColor: "#C38F51",
                                            },
                                        }}
                                    >
                                        {referralsDates?.[0]?.additional_data?.[0]?.secondary_button_text || "Secondary Button"}
                                    </Button>
                                )}
                                {showIcons && (
                                    <Box
                                        sx={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            gap: 2,
                                        }}
                                    >
                                        {[facebook_icon, whatsapp_icon, instagram_icon, linkedin_icon, twitter_icon].map(
                                            (icon, index) => (
                                                <IconButton
                                                    key={index}
                                                    aria-label={`Social Icon ${index + 1}`}
                                                    sx={{
                                                        backgroundColor: "#C68A48",
                                                        color: "white",
                                                        padding: "12px",
                                                        height: "40px",
                                                        width: "40px",
                                                        "&:hover": {
                                                            backgroundColor: "#D9A766",
                                                        },
                                                    }}
                                                >
                                                    <img src={icon} alt={`Social Icon ${index + 1}`} />
                                                </IconButton>
                                            )
                                        )}
                                    </Box>
                                )}
                            </Stack>
                        </Box>
                    </Grid>

                    {/* Right Section */}
                    <Grid item xs={12} md={6}>
                        <Box
                            sx={{
                                backgroundImage: `url(${refer_img_background})`,
                                backgroundSize: "contain",
                                backgroundRepeat: "no-repeat",
                                backgroundPosition: "center",
                                height: "350px",
                                // left:"200px",
                                left:"40%",

                                width: "100%",
                                display: { xs: "none", sm: "block" },
                                position: "relative",
                                zIndex: 0,
                            }}
                        >
                            <img
                                src={`${adminUrl}/${referralsDates?.[0]?.additional_data?.[0]?.banner}`}
                                alt="Banner"
                                style={{
                                    position: "absolute", // Make it relative to the parent `Box`
                                    top: "10%", // Adjust as needed
                                    left: "10%", // Adjust as needed
                                    height: "270px",
                                    zIndex: 1,
                                }}
                            />
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        </Box>

    );
};

export default InviteFriends;
