import React from 'react';
import { Box, Typography, Grid } from '@mui/material';
import image1 from "../../../Assets/gold-bars-background-free-photo.jpg"
import image2 from "../../../Assets/aboutUsBgImage.png"
import { adminUrl } from "../../../constants";




const AboutUsContent = ({ aboutDates }) => {

    const VisionData = aboutDates?.[0]?.additional_data?.card_design || [
        {
            title: "Our",
            secondary_title: "Mission",
            content:
                "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen bookLorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book ",
            banner: image1,
            reverse: false,
        },
        {
            title: "Our",
            secondary_title: "Mission",
            content:
                "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages.",
            banner: image2,
            reverse: true,
        },
    ];



    return (
        <Box sx={{ padding: '2rem' }}>
            <Box sx={{ textAlign: "center", mb: 4 }}>
                <Typography
                    variant="h3"
                    gutterBottom
                    sx={{
                        paddingBottom: "5px",
                        //  fontSize: "30px" 
                    }}
                >
                    {/* <span style={{ color: "black", fontWeight: "800" }}>{stakeNowDates?.[0]?.sub_heading || "Default Sub-heading"}</span> */}
                    {aboutDates?.[0]?.heading || "Default Heading"}
                    {/* About Us */}

                </Typography>
                <Typography
                    variant="body1"
                    sx={{
                        paddingBottom: "5px",

                        maxWidth: "800px",
                        margin: "0 auto",
                        textAlign: "center",
                        color: "#b0aaaa",

                    }}
                >

                    {aboutDates?.[0]?.content || "Default content"}
                    {/* Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum. */}

                </Typography>
            </Box>
            {VisionData.map((item, index) => (
                <Grid
                    container
                    xs={12}
                    key={index}
                    direction={item.reverse ? 'row' : 'row-reverse'}

                    alignItems="center"
                    sx={{ marginBottom: '3rem' }}
                >
                    <Grid item xs={12} md={6}>
                        <Box
                            component="img"
                            src={`${adminUrl}/${item.banner}`}
                            alt={item.title}
                            sx={{ width: '100%', borderRadius: '8px', height: { xs: "250px", md: "330px" }, backgroundSize: "cover" }}
                        />
                    </Grid>
                    <Grid item xs={12} md={6} >
                        <Typography variant="h3" fontWeight={800} gutterBottom textAlign="center" color='black'>
                            {item.title} <span style={{ color: "#C38F51" }}>{item.secondary_title}</span>
                        </Typography>
                        <Typography variant="body1" textAlign={{ xs: "justify", md: 'justify' }} color='#747893' sx={{ px: { xs: '0', md: "20px" } }}>{item.content}</Typography>
                    </Grid>
                </Grid>
            ))}
        </Box>
    );
};

export default AboutUsContent;
