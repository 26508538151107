// // // // import React from 'react';
// // // // import { Box, Button, Typography } from '@mui/material';
// // // // import { Formik, Form } from 'formik';
// // // // import CustomInput from '../CustomInput/CustomInput';

// // // // const ForgotPasswordForm = ({ handleSubmit, toggleSignIn }) => {
// // // //   const initialValues = {
// // // //     email: ''
// // // //   };

// // // //   return (
// // // //     <Formik
// // // //       initialValues={initialValues}
// // // //       onSubmit={handleSubmit}
// // // //     >
// // // //       {({ values, handleChange, handleBlur, errors, touched }) => (
// // // //         <Form style={{ width: '100%' }}>
// // // //           <Box mb={1}>
// // // //             <CustomInput
// // // //               name="email"
// // // //               label="Email"
// // // //               custPlaceholder="Enter Email"
// // // //               inputType="text"
// // // //             />
// // // //           </Box>

// // // //           <Button
// // // //             fullWidth
// // // //             type="submit"
// // // //             variant="contained"
// // // //             sx={{
// // // //               borderRadius: 23,
// // // //               py: "7px",
// // // //               color: "white",
// // // //               backgroundColor: "#C38F51"
// // // //             }}
// // // //           >
// // // //             Send Reset Link
// // // //           </Button>

// // // //           <Box
// // // //             display="flex"
// // // //             justifyContent="center"
// // // //             mt={3}
// // // //           >
// // // //             <Typography
// // // //               variant="body1"
// // // //               sx={{ cursor: "pointer", color: "#C38F51" }}
// // // //               onClick={toggleSignIn}
// // // //             >
// // // //               Back to Sign In
// // // //             </Typography>
// // // //           </Box>
// // // //         </Form>
// // // //       )}
// // // //     </Formik>
// // // //   );
// // // // };

// // // // export default ForgotPasswordForm;


// // // // ForgotPasswordForm.js


// // // import React, { useState } from 'react';
// // // import { Box, Button, Typography } from '@mui/material';
// // // import { Formik, Form } from 'formik';
// // // import * as Yup from 'yup';
// // // import CustomInput from '../CustomInput/CustomInput';
// // // import { toast } from 'react-toastify';
// // // import actions from '../../ReduxStore/actions';
// // // import { useDispatch, useSelector } from 'react-redux';
// // // import OtpsSendModal from '../OtpSendModal/OtpsSendModal';

// // // const ForgotPasswordForm = ({ onGoBack }) => {
// // //     const initialValues = {
// // //         email: ''
// // //     };

// // //     const dispatch = useDispatch()

// // //     const { EmailSendOtp } = useSelector((state) => state?.EmailSendOtp);
// // //     const { EmailVerifyOtp } = useSelector((state) => state?.EmailVerifyOtp);

// // //     const [isModalOpen, setModalOpen] = useState(false);
// // //     const [submittedValues, setSubmittedValues] = ("")
// // //     console.log(submittedValues, "otpcodeeeee other component")

// // //     const handleOpenModal = () => {
// // //         setModalOpen(true);

// // //     }
// // //     const handleCloseModal = () => {
// // //         setModalOpen(false);
// // //     };


// // //     const validationSchema = Yup.object({
// // //         email: Yup.string().email('Invalid email address').required('Email is required')
// // //     });

// // //     const handleSubmit = async (values) => {
// // //         // Handle forgot password logic (API call, etc.)
// // //         console.log(values, "3333333handlesend")


// // //         const data1 = {
// // //             data:
// // //                 values,
// // //             method: "post",
// // //             apiName: "sendOTP"
// // //         }

// // //         const OtpSendData = await dispatch(actions.EMAILSENDOTP(data1))

// // //         handleOpenModal()
// // //         if (OtpSendData?.payload?.message === "OTP sent successfully!") {
// // //             toast.success("OTP send successfully");
// // //             setSubmittedValues(values)
// // //         }
// // //         else {
// // //             toast.error("Please enter valid email");

// // //         }


// // //         // toast.success("Password reset link sent!");
// // //         // onGoBack();  // Call back function to go back to the login form
// // //     };

// // //     return (
// // //         <Formik
// // //             initialValues={initialValues}
// // //             validationSchema={validationSchema}
// // //             onSubmit={(values) => handleSubmit(values)}
// // //         >
// // //             {({ values, handleChange, handleBlur, errors, touched }) => (
// // //                 <Form style={{ width: '100%' }}>
// // //                     <Box mb={2}>
// // //                         <CustomInput
// // //                             name="email"
// // //                             label="Email"
// // //                             custPlaceholder="Enter Email"
// // //                             inputType="text"
// // //                         />
// // //                     </Box>
// // //                     <Button
// // //                         fullWidth
// // //                         type="submit"
// // //                         variant="contained"
// // //                         sx={{ borderRadius: 23, py: "7px", color: "white", backgroundColor: "#C38F51" }}
// // //                     >
// // //                         Send Otp
// // //                     </Button>

// // //                     <OtpsSendModal
// // //                         open={isModalOpen}
// // //                         onClose={handleCloseModal}
// // //                         submittedValues={submittedValues}
// // //                     // onSave={handleSaveModal}

// // //                     // Pass the selected heading to the modal
// // //                     />
// // //                 </Form>
// // //             )}
// // //         </Formik>
// // //     );
// // // };

// // // export default ForgotPasswordForm;



// // import React, { useState } from 'react';
// // import { Box, Button, Typography } from '@mui/material';
// // import { Formik, Form } from 'formik';
// // import * as Yup from 'yup';
// // import CustomInput from '../CustomInput/CustomInput';
// // import { toast } from 'react-toastify';
// // import actions from '../../ReduxStore/actions';
// // import { useDispatch, useSelector } from 'react-redux';
// // import OtpsSendModal from '../OtpSendModal/OtpsSendModal';

// // const ForgotPasswordForm = ({ onGoBack }) => {
// //     const initialValues = {
// //         email: ''
// //     };

// //     const dispatch = useDispatch();

// //     const { EmailSendOtp } = useSelector((state) => state?.EmailSendOtp);
// //     const { EmailVerifyOtp } = useSelector((state) => state?.EmailVerifyOtp);

// //     const [isModalOpen, setModalOpen] = useState(false);
// //     const [submittedValues, setSubmittedValues] = useState(""); // Correct initialization

// //     const handleOpenModal = () => {
// //         setModalOpen(true);
// //     };

// //     const handleCloseModal = () => {
// //         setModalOpen(false);
// //     };

// //     const validationSchema = Yup.object({
// //         email: Yup.string().email('Invalid email address').required('Email is required')
// //     });

// //     const handleSubmit = async (values) => {
// //         console.log(values, "handlesend");

// //         const data1 = {
// //             data: values,
// //             method: "post",
// //             apiName: "sendOTP"
// //         };

// //         const OtpSendData = await dispatch(actions.EMAILSENDOTP(data1));

// //         if (OtpSendData?.payload?.message === "OTP sent successfully!") {
// //             toast.success("OTP sent successfully");
// //             setSubmittedValues(values); // Correct usage of setSubmittedValues
// //             handleOpenModal();
// //         } else {
// //             toast.error("Please enter a valid email");
// //         }
// //     };

// //     console.log(EmailVerifyOtp, "EmailVerifyOtpEmailVerifyOtp")


// //     return (
// //         <Formik
// //             initialValues={initialValues}
// //             validationSchema={validationSchema}
// //             onSubmit={(values) => handleSubmit(values)}
// //         >
// //             {({ values, handleChange, handleBlur, errors, touched }) => (
// //                 <Form style={{ width: '100%' }}>
// //                     <Box mb={2}>
// //                         <CustomInput
// //                             name="email"
// //                             label="Email"
// //                             custPlaceholder="Enter Email"
// //                             inputType="text"
// //                         />
// //                         {EmailVerifyOtp?.message === "OTP verified successfully" && (
// //                             <CustomInput
// //                                 name="password"
// //                                 label="Password"
// //                                 custPlaceholder="Enter password"
// //                                 inputType="password"
// //                             />
// //                         )}
// //                     </Box>
// //                     <Button
// //                         fullWidth
// //                         type="submit"
// //                         variant="contained"
// //                         sx={{ borderRadius: 23, py: "7px", color: "white", backgroundColor: "#C38F51" }}
// //                     >
// //                         Send OTP
// //                     </Button>

// //                     <OtpsSendModal
// //                         open={isModalOpen}
// //                         onClose={handleCloseModal}
// //                         submittedValues={submittedValues} // Pass the state correctly
// //                     />
// //                 </Form>
// //             )}
// //         </Formik>
// //     );
// // };

// // export default ForgotPasswordForm;



// import React, { useState } from "react";
// import { Box, Button, Typography } from "@mui/material";
// import { Formik, Form } from "formik";
// import * as Yup from "yup";
// import CustomInput from "../CustomInput/CustomInput";
// import { toast } from "react-toastify";
// import { useDispatch, useSelector } from "react-redux";
// import OtpsSendModal from "../OtpSendModal/OtpsSendModal";
// import actions from "../../ReduxStore/actions";

// const ForgotPasswordForm = ({ onGoBack }) => {
//   const dispatch = useDispatch();

//   // Redux state selectors
//   const { EmailSendOtp } = useSelector((state) => state?.EmailSendOtp);
//   const { EmailVerifyOtp } = useSelector((state) => state?.EmailVerifyOtp);

//   // Component state
//   const [isModalOpen, setModalOpen] = useState(false);
//   const [submittedValues, setSubmittedValues] = useState("");

//   // Formik initial values and validation schema
//   const initialValues = {
//     email: "",
//     password: "",
//   };

//   const validationSchema = Yup.object({
//     email: Yup.string().email("Invalid email address").required("Email is required"),
//     password: Yup.string().when("email", {
//       is: () => EmailVerifyOtp?.message === "OTP verified successfully",
//       then: Yup.string().required("Password is required"),
//     }),
//   });

//   // Modal handlers
//   const handleOpenModal = () => setModalOpen(true);
//   const handleCloseModal = () => setModalOpen(false);

//   // Form submit handler
//   const handleSubmit = async (values) => {
//     if (EmailVerifyOtp?.message === "OTP verified successfully") {
//       // Log email and password
//       console.log("Email:", values.email);
//       console.log("Password:", values.password);
//     } else {
//       const requestData = {
//         data: { email: values.email },
//         method: "post",
//         apiName: "sendOTP",
//       };

//       const OtpSendData = await dispatch(actions.EMAILSENDOTP(requestData));

//       if (OtpSendData?.payload?.message === "OTP sent successfully!") {
//         toast.success("OTP sent successfully");
//         setSubmittedValues(values.email); // Store email in the state
//         handleOpenModal();
//       } else {
//         toast.error("Please enter a valid email");
//       }
//     }
//   };

//   return (
//     <Formik
//       initialValues={initialValues}
//       validationSchema={validationSchema}
//       onSubmit={handleSubmit}
//     >
//       {({ values, errors, touched, setFieldValue }) => (
//         <Form style={{ width: "100%" }}>
//           <Box mb={2}>
//             {/* Email Input */}
//             {EmailVerifyOtp?.message !== "OTP verified successfully" && (
//               <CustomInput
//                 name="email"
//                 label="Email"
//                 custPlaceholder="Enter Email"
//                 inputType="email"
//                 // value={values.email}
//                 // onChange={(e) => setFieldValue("email", e.target.value)}
//                 // error={touched.email && Boolean(errors.email)}
//                 // helperText={touched.email && errors.email}
//               />
//             )}

//             {/* Read-only Email & Password Input */}
//             {EmailVerifyOtp?.message === "OTP verified successfully" && (
//               <>
//                 <CustomInput
//                   name="email"
//                   label="Email"
//                   custPlaceholder="Enter Email"
//                   inputType="text"
//                   value={values.email}
//                   readOnly
//                 />
//                 <CustomInput
//                   name="password"
//                   label="Password"
//                   custPlaceholder="Enter Password"
//                   inputType="password"

//                 />
//               </>
//             )}
//           </Box>

//           {/* Submit Button */}
//           <Button
//             fullWidth
//             type="submit"
//             variant="contained"
//             sx={{
//               borderRadius: 23,
//               py: "7px",
//               color: "white",
//               backgroundColor: "#C38F51",
//               "&:hover": {
//                 backgroundColor: "#B37E42",
//               },
//             }}
//             aria-label="Submit"
//           >
//             {EmailVerifyOtp?.message === "OTP verified successfully" ? "Submit" : "Send OTP"}
//           </Button>

//           {/* OTP Modal */}
//           <OtpsSendModal
//             open={isModalOpen}
//             onClose={handleCloseModal}
//             submittedValues={submittedValues}
//           />

//           {/* Back Button */}
//           {onGoBack && (
//             <Typography
//               variant="body2"
//               onClick={onGoBack}
//               sx={{
//                 mt: 2,
//                 textAlign: "center",
//                 cursor: "pointer",
//                 color: "primary.main",
//               }}
//             >
//               Go Back
//             </Typography>
//           )}
//         </Form>
//       )}
//     </Formik>
//   );
// };

// export default ForgotPasswordForm;


import React, { useState } from "react";
import { Box, Button, Typography } from "@mui/material";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import CustomInput from "../CustomInput/CustomInput";
import { toast, ToastContainer } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import OtpsSendModal from "../OtpSendModal/OtpsSendModal";
import actions from "../../ReduxStore/actions";
import { useNavigate } from "react-router-dom";
import { setInitialStateOfresetPasswordForCustomer } from "../../ReduxStore/Slices/VerificationOtp/resetPasswordForCustomer";

const ForgotPasswordForm = ({ toggleSignIn }) => {
    const dispatch = useDispatch();

    // Redux state selectors
    const { EmailSendOtp } = useSelector((state) => state?.EmailSendOtp);
    const { EmailVerifyOtp } = useSelector((state) => state?.EmailVerifyOtp);
    const { resetPasswordForCustomer } = useSelector((state) => state?.resetPasswordForCustomer);

    const navigate = useNavigate()
    // Component state
    const [isModalOpen, setModalOpen] = useState(false);
    const [submittedValues, setSubmittedValues] = useState("");

    // Modal handlers
    const handleOpenModal = () => setModalOpen(true);
    const handleCloseModal = () => setModalOpen(false);

    // Formik initial values
    const initialValues = {
        email: "",
        password: "",
    };

    // Separate validation schemas
    const validationSchemaBeforeOTP = Yup.object({
        email: Yup.string().email("Invalid email address").required("Email is required"),
    });

    const validationSchemaAfterOTP = Yup.object({
        password: Yup.string().required("Password is required"),
    });

   

    
    const handleSubmit = async (values) => {
        try {
            if (EmailVerifyOtp?.message === "OTP verified successfully") {
                const requestData = {
                    data: {
                        email: values.email,
                        newPassword: values.password,
                    },
                    method: "post",
                    apiName: "resetPasswordForCustomer",
                };

                const newPasswordData = await dispatch(actions.RESETPASSWORDFORCUSTOMER(requestData));

                console.log(newPasswordData, "newPasswordDatanewPasswordDatanewPasswordData")
                if (newPasswordData?.payload?.message === "Password has been reset successfully.") {
                    await dispatch(setInitialStateOfresetPasswordForCustomer());
                    toast.success("Password has been reset successfully.");


                    console.log("hejnjnknbkjbkjbky")

                    // Await the state reset to ensure it completes before moving forward

                    // Delay navigation to ensure user sees the success message
                    setTimeout(() => {
                        toggleSignIn();
                    }, 1500);
                } else {
                    toast.error(newPasswordData?.payload?.message || "Failed to reset the password.");
                }
            } else {
                const requestData = {
                    data: { email: values.email },
                    method: "post",
                    apiName: "sendOTP",
                };

                const OtpSendData = await dispatch(actions.EMAILSENDOTP(requestData));

                if (OtpSendData?.payload?.message === "OTP sent successfully!") {
                    toast.success("OTP sent successfully.");
                    setSubmittedValues(values.email); // Store email in the state
                    handleOpenModal();
                } else {
                    toast.error("Please enter a valid email.");
                }
            }
        } catch (error) {
            console.error("Error during handleSubmit:", error);
            toast.error("An unexpected error occurred. Please try again.");
        }
    };

    return (

        <>

            <Formik
                initialValues={initialValues}
                validationSchema={
                    EmailVerifyOtp?.message === "OTP verified successfully"
                        ? validationSchemaAfterOTP
                        : validationSchemaBeforeOTP
                }
                onSubmit={handleSubmit}
            >
                {({ values, errors, touched, setFieldValue }) => (
                    <Form style={{ width: "100%" }}>
                        <Box mb={2}>
                            {/* Email Input */}
                            {EmailVerifyOtp?.message !== "OTP verified successfully" && (
                                <CustomInput
                                    name="email"
                                    label="Email"
                                    custPlaceholder="Enter Email"
                                    inputType="email"

                                />
                            )}

                            {/* Password Input */}
                            {EmailVerifyOtp?.message === "OTP verified successfully" && (
                                <>
                                    <CustomInput
                                        name="email"
                                        label="Email"
                                        custPlaceholder="Enter Email"
                                        inputType="text"
                                        value={values.email}
                                        readOnly
                                    />
                                    <CustomInput
                                        name="password"
                                        label="Password"
                                        custPlaceholder="Enter Password"
                                        inputType="password"

                                    />
                                </>
                            )}
                        </Box>

                        {/* Submit Button */}
                        <Button
                            fullWidth
                            type="submit"
                            variant="contained"
                            sx={{
                                borderRadius: 23,
                                py: "7px",
                                color: "white",
                                backgroundColor: "#C38F51",
                                "&:hover": {
                                    backgroundColor: "#B37E42",
                                },
                            }}
                            aria-label="Submit"
                        >
                            {EmailVerifyOtp?.message === "OTP verified successfully" ? "Submit" : "Send OTP"}
                        </Button>

                        {/* OTP Modal */}
                        <OtpsSendModal
                            open={isModalOpen}
                            onClose={handleCloseModal}
                            submittedValues={submittedValues}
                        />

                        {/* Back Button */}
                        {toggleSignIn && (
                            <Typography
                                variant="body1"
                                onClick={toggleSignIn}
                                sx={{
                                    mt: 2,
                                    textAlign: "center",
                                    cursor: "pointer",
                                    color: "#C38F51",
                                }}
                            >
                                Go Back
                            </Typography>
                        )}
                       
                    </Form>
                )}
            </Formik>

            
        </>
    );
};

export default ForgotPasswordForm;
