

import React from "react";
import { Box, Button, Typography, Container, Stack } from "@mui/material";
import home_page_Image from "../../../Assets/home_page_Image.png";
// import home_side_image from "../../Assets/Overlay.png"; // Ensure this is the correct path
import { useNavigate } from "react-router-dom";

import actions from "../../../ReduxStore/actions/index";
import { useDispatch } from "react-redux";
import { adminUrl } from "../../../constants";

const LandingPage = ({ bannerDates }) => {
    const navigate = useNavigate()

    const handleGoLoginpage = () => {
        navigate("/login")
    }

    const handleGoStakeNowPage = () => {
        navigate("/stake-now")
    }
    return (
        <Box
            sx={{
                // position: "relative", // Needed for positioning child elements
                background: `linear-gradient(90deg, #000000 0%, #54545400 100%), url(${adminUrl}/${bannerDates?.[0]?.additional_data?.[0]?.banner})`,
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                overflow: "hidden",
                padding: "80px 0px",
                // left:"100px"
                // minHeight: "90vh", // Adjust height as needed
            }}
        >
            <Container maxWidth="lg" sx={{ zIndex: 2 }}>
                <Box
                    display="flex"
                    // alignItems="center"
                    justifyContent="start"
                    flexWrap="wrap"
                >
                    {/* Left Section */}
                    <Box
                        sx={{

                            color: "#fff",
                        }}
                    >
                        <Typography
                            variant="h1"
                            textAlign="start"
                        // sx={{
                        //     fontWeight: "bold",
                        //     fontSize: "50px",
                        //     // mb:"1px"
                        // }}
                        >
                            {bannerDates?.[0]?.heading || "Default Heading"}

                        </Typography>
                        <Typography
                            variant="h2"
                            textAlign="start"
                            sx={{

                                mb: 2,
                            }}
                        >
                            {bannerDates?.[0]?.sub_heading || "Default Sub-heading"}

                        </Typography>
                        <Typography
                            variant="body1"
                            textAlign="start"
                            sx={{
                                mb: 5,
                                lineHeight: "1.6",
                                color: "#ffffff",

                                maxWidth: "500px",


                                letterSpacing: "0px",

                                opacity: 1
                            }}
                        >
                            {bannerDates?.[0]?.content || "Default Content"}
                        </Typography>
                        <Stack spacing={2} direction="row">
                            <Button
                                variant="contained"
                                onClick={handleGoStakeNowPage}

                                sx={{
                                    // backgroundColor: "#C38F51",
                                    textTransform: "none",
                                    color: "white",
                                    fontSize: "16px",
                                    // padding: "0px 40px",
                                    padding: { xs: "0px 20px", md: "0px 40px" },

                                    minHeight: "40px",
                                    borderRadius: "23px",
                                    background: "#C38F51 0% 0% no-repeat padding-box",
                                    // "&:hover": {
                                    //     backgroundColor: "#3B4056",
                                    // },
                                }}
                            >
                                {bannerDates?.[0]?.additional_data?.[0]?.primary_button_text || "Primary Button"}

                            </Button>
                            <Button
                                variant="outlined"
                                onClick={handleGoLoginpage}
                                sx={{
                                    // backgroundColor: "#FFAA17",
                                    border: "1px solid #C38F51",
                                    textTransform: "none",
                                    color: "white",
                                    fontSize: "16px",
                                    padding: { xs: "0px 20px", md: "0px 40px" },
                                    minHeight: "40px",
                                    borderRadius: "23px",
                                    // font: "normal normal normal 16px / 25px Roboto",
                                    letterSpacing: "0px",
                                    color: "#C38F51",
                                    background: "white"
                                    // "&:hover": {
                                    //     backgroundColor: "#C38F51",
                                    // },
                                }}
                            >
                                {bannerDates?.[0]?.additional_data?.[0]?.secondary_button_text || "Secondary Button"}

                            </Button>
                        </Stack>
                    </Box>
                </Box>
            </Container >
        </Box >
    );
};

export default LandingPage;
