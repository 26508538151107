// // // CustomDropdownMui.js
// // import React from "react";
// // import MenuItem from "@mui/material/MenuItem";
// // import Select from "@mui/material/Select";
// // import "./CustomDropdownMui.css";
// // import "../ComponentsCss/componet.css";
// // import { InputAdornment, InputLabel } from "@mui/material";
// // import { Field, ErrorMessage } from "formik";
// // import { Email } from "@mui/icons-material";

// // const CustomDropdownMui = ({
// //   label,
// //   name,
// //   options,
// //   custPlaceholder,
// //   setFieldValue,
// //   selectmovieIdfn,
// //   selectCategoryIdfn,
// //   onChange,
// //   assignOpreatorToCustomerFn,
// //   customerId,
// //   ...rest
// // }) => {
// //   console.log(options, "options122121")
// //   return (
// //     <div style={{ width: "95%" }}>
// //       {label && (
// //         <div>
// //           <label htmlFor={name} className="input-heading12" style={{ fontSize: "16px", fontWeight: "700" }}
// //           >
// //             {label}
// //           </label>
// //         </div>
// //       )}
// //       {/* <Field
// //         as="select"
// //         id={name}
// //         name={name}

// //         {...rest}
// //         className="customDropdown-input"

// //       > */}
// //       <InputAdornment position="start" style={{ position: "absolute", top: "50%", left: "8px", transform: "translateY(-50%)" }}>
// //         <Email style={{ color: "black" }} />
// //       </InputAdornment>
// //       <Field name={name}
// //         style={{
// //           width: "100%",
// //           paddingLeft: "40px", // Add padding to accommodate the icon
// //           boxSizing: "border-box",
// //         }}>
// //         {({ field, form }) => (
// //           <select
// //             id={name}
// //             {...field}
// //             {...rest}
// //             className="customDropdown-input"
// //             onChange={(e) => {
// //               const selectedValue = e.target.value;

// //             }}
// //           >
// //             {custPlaceholder && (
// //               <option value="" disabled className="customDropdown-disabled-option">
// //                 {custPlaceholder ? custPlaceholder : "Select dropddown"}
// //               </option>
// //             )}
// //             {options.map((option) => (
// //               <option key={option.value} value={option.value}>
// //                 {option.label}
// //               </option>
// //             ))}
// //           </select>
// //         )}
// //       </Field>
// //       <ErrorMessage name={name} component="div" className="inputs-error-msg" />
// //     </div>
// //   );
// // };

// // export default CustomDropdownMui;


// import React from "react";
// import MenuItem from "@mui/material/MenuItem";
// import Select from "@mui/material/Select";
// import "./CustomDropdownMui.css";
// import "../ComponentsCss/componet.css";
// import { InputAdornment, InputLabel } from "@mui/material";
// import { Field, ErrorMessage } from "formik";
// import { Email } from "@mui/icons-material";

// const CustomDropdownMui = ({
//   label,
//   name,
//   options,
//   custPlaceholder,
//   setFieldValue,
//   selectmovieIdfn,
//   selectCategoryIdfn,
//   onChange,
//   assignOpreatorToCustomerFn,
//   customerId,
//   image,
//   ...rest
// }) => {
//   return (
//     <div style={{ width: "95%" }}>
//       {label && (
//         <div style={{marginBottom: "6px"}}>
//           <label htmlFor={name} className="input-heading12" style={{ fontSize: "16px", fontWeight: "700" }}>
//             {label}
//           </label>
//         </div>
//       )}
//       {/* Wrapper for icon and dropdown */}
//       <div style={{ position: "relative", width: "100%" }}>
//         {/* Email Icon */}

//         <div
//           style={{
//             position: "absolute",

//             top: "55%",
//             left: "10px",
//             transform: "translateY(-50%)",
//             color: "black",
//           }}
//         >
//           <img src={image} style={{height:"17px"}}></img>
//         </div>
//         {/* Dropdown Field */}
//         <Field name={name}>
//           {({ field, form }) => (
//             <select
//               id={name}
//               {...field}
//               {...rest}
//               className="customDropdown-input"
//               style={{
//                 width: "100%",
//                 paddingLeft: "40px", // Add padding to avoid overlapping with the icon
//                 boxSizing: "border-box",
//               }}
//               onChange={(e) => {
//                 const selectedValue = e.target.value;
//                 // Add your onChange logic here if needed
//               }}
//             >
//               {custPlaceholder && (
//                 <option value="" disabled className="customDropdown-disabled-option">
//                   {custPlaceholder ? custPlaceholder : "Select dropdown"}
//                 </option>
//               )}
//               {options.map((option) => (
//                 <option key={option.value} value={option.value}>
//                   {option.label}
//                 </option>
//               ))}
//             </select>
//           )}
//         </Field>
//       </div>
//       <ErrorMessage name={name} component="div" className="inputs-error-msg" />
//     </div>
//   );
// };

// export default CustomDropdownMui;




import React from "react";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import "./CustomDropdownMui.css";
import "../ComponentsCss/componet.css";
import { InputAdornment, InputLabel } from "@mui/material";
import { Field, ErrorMessage } from "formik";
import { Email } from "@mui/icons-material";
import { FaStarOfLife } from "react-icons/fa6";

const CustomDropdownMui = ({
  label,
  name,
  options,
  custPlaceholder,
  setFieldValue,
  selectmovieIdfn,
  selectCategoryIdfn,
  important,
  onChange,
  assignOpreatorToCustomerFn,
  customerId,
  image,
  ...rest
}) => {
  return (
    <div style={{ width: "100%" }}>
      {label && (
        <div style={{ marginBottom: "6px" }}>
          <label htmlFor={name} className="input-heading12" style={{ fontSize: "16px", fontWeight: "450" }}>
            {label}{important && (
              <FaStarOfLife style={{ color: '#F53E5C', fontSize: "8px", marginLeft: "3px" }} />
            )}
          </label>
        </div>
      )}
      {/* Wrapper for icon and dropdown */}

      {/* Dropdown Field */}
      <div style={{ position: "relative", width: "100%" }}>

        <div
          style={{
            position: "absolute",

            top: "55%",
            left: "10px",
            transform: "translateY(-50%)",
            color: "black",
          }}
        >
          <img src={image} style={{ height: "17px" }}></img>
        </div>
        <Field name={name}>
          {({ field, form }) => (
            <select
              id={name}
              {...field}
              {...rest}
              className="customDropdown-input"
              style={{
                borderRadius:"3px",
                width: "100%",
                color: "#7A7A7A",
                // paddingLeft: "40px", // Add padding to avoid overlapping with the icon
                boxSizing: "border-box",
                paddingLeft:"30px"
              }}

            >
              {custPlaceholder && (
                <option value="" disabled className="customDropdown-disabled-option">
                  {custPlaceholder ? custPlaceholder : "Select dropdown"}
                </option>
              )}
              {options.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          )}
        </Field>
      </div>

      <ErrorMessage name={name} component="div" className="inputs-error-msg"
        style={{ color: "red", fontSize: "14px", fontWeight: '400' }}
      />
    </div >
  );
};

export default CustomDropdownMui;
