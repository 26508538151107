

import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';


import CustomerDashboard from './CustomerDashboard';
import CustomerReferals from './CustomerReferals';

function Customer() {
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const renderTabContent = () => {
        switch (value) {
            case 0:
                return <CustomerDashboard />;
            case 1:
                return <CustomerReferals />;
            // case 2:
            //     return <AllNewOrdersByCustomerId />;
            // case 3:
            //     return <AllShippedOrdersByCustomerId />;
            // case 4:
            //     return <AllDeliveredOrdersByCustomerId />;
            default:
                return null;
        }
    };

    return (
        <div>
            {/* <MyAppBar /> */}
            <Box sx={{
                bgcolor: 'background.paper',
                px: 4,
                // width: '100%' ,  
                [`@media (max-width:600px)`]: {
                    color: "red",

                    fontSize: '0.8rem', // Smaller font on mobile
                    maxWidth: "345px", // Minimum width for each tab


                }
                // Ensures Tabs take full width

            }}>
                <Tabs
                    value={value}
                    onChange={handleChange}
                    textColor="inherit"
                    TabIndicatorProps={{
                        style: {
                            backgroundColor: '#C38F51',
                            height: '3px', // Adjust the thickness of the bottom border
                        },
                    }}
                  
                >
                    <Tab
                        label="My Gold"
                        sx={{
                            color: value === 0 ? '#C38F51' : 'black',
                            textTransform: 'none', // Optional: to remove uppercase styling
                            fontWeight: value === 0 ? 'bold' : 'normal',
                            borderBottom: value === 0 ? '3px solid #C38F51' : 'none', // Custom bottom border
                            width: 'auto', // Adjust width
                        }}
                    />
                    <Tab
                        label="My Referrals"
                        sx={{
                            color: value === 1 ? '#C38F51' : 'black',
                            textTransform: 'none',
                            fontWeight: value === 1 ? 'bold' : 'normal',
                            borderBottom: value === 1 ? '3px solid #C38F51' : 'none',
                            width: 'auto',
                        }}
                    />
                </Tabs>
            </Box>
            <Box sx={{ px: 4, py: 2 }}>
                {renderTabContent()}
            </Box>
            {/* <Footer /> */}
        </div>
    );
}

export default Customer;
