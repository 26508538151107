

import React from "react";
import { Box, Typography, Grid, Container } from "@mui/material";

import sheme_small_image from "../../../Assets/sheme_small_image.png"
import "./AddToCartSchemesById.css"


const AddToCartSchemesById = () => {


    return (
        <>
            <Box sx={{ py: 4 }}>
                <Box sx={{ textAlign: "center", mb: 4 }}>
                    <Typography
                        variant="h3"
                        gutterBottom
                        sx={{ paddingBottom: "5px"}}
                    >
                        <span style={{ color: "black",fontWeight:"800" }}>Scheme Name 1</span>
                    </Typography>
                    <Typography
                    variant="body1"
                        sx={{
                            paddingBottom: "5px",
                            maxWidth: "800px",
                            margin: "0 auto",
                            textAlign: "center",
                            color: "#b0aaaa",
                         
                        }}
                    >
                        Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                        Lorem Ipsum has been the industry’s standard dummy text ever since the
                        1500s, when an unknown printer took a galley of type and scrambled it to
                        make a type specimen book.
                    </Typography>
                </Box>

                <Box
                    sx={{
                        position: "relative", // Needed for positioning child elements
                        background: `linear-gradient(90deg, #000000 0%, #2E2E2ED6 51%, #1D1D1D 100%) , url(${sheme_small_image})`,
                        // background: `linear-gradient(90deg, #000000 0%, #54545400 100%), url(${StakeNowAddToCart})`,
                        // background: `url(${StakeNowAddToCart}),linear- gradient(91deg, #000000 0 %, #2E2E2ED6 51 %, #1D1D1D 100 %)`,
                        backgroundSize: "cover",
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: "center",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        overflow: "hidden",
                        padding: "40px 20px",
                        // left:"100px"
                        // minHeight: "90vh", // Adjust height as needed

                    }}
                >
                    <Container maxWidth="lg" sx={{ zIndex: 2 }}>
                        <Box
                            display="flex"
                            // alignItems="center"
                            justifyContent="space-between"
                            flexWrap="wrap"
                            color="white"
                        >
                            <Box>
                                <Typography variant="subtitle1" sx={{ mb: 0.4 }}>Value of Purchase</Typography>
                                <Typography variant="body2" sx={{ fontWeight: "600", color: "#C38F51", fontSize: "19px" }}>$1000</Typography>

                            </Box>
                            <Box>
                                <Typography variant="subtitle1" sx={{ mb: 0.4 }}>Date of Initiation</Typography>
                                <Typography variant="body2" sx={{ fontWeight: "600", color: "#C38F51", fontSize: "19px" }}>15 Jan 2025</Typography>

                            </Box>
                            <Box>
                                <Typography variant="subtitle1" sx={{ mb: 0.4 }}>Gold Staked</Typography>
                                <Typography variant="body2" sx={{ fontWeight: "600", color: "#C38F51", fontSize: "19px" }}>13.8 Grm</Typography>

                            </Box>
                            <Box>
                                <Typography variant="subtitle1" sx={{  mb: 0.4 }}>Reap Benifit</Typography>
                                <Typography variant="body2" sx={{ fontWeight: "600", color: "#C38F51", fontSize: "19px" }}>6% *<span style={{ fontSize: "12px", paddingLeft: "4px", fontWeight: "none" }}>Per Quarter</span></Typography>

                            </Box>
                        </Box>
                    </Container>
                   

                </Box>



            </Box>
        </>
    );
};

export default AddToCartSchemesById;
