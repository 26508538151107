





/* eslint-disable no-sequences */
/* eslint-disable no-unused-vars */
/* eslint-disable no-param-reassign */
/* eslint-disable no-unused-expressions */

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { defaultReject, defaultState } from "../../../constants";
import { fetchData } from "../../helpers";
// getPurchasesByCustomerId
// GETPURCHASESBYCUSTOMERID
const GETPURCHASESBYCUSTOMERID= createAsyncThunk(
  "getPurchasesByCustomerId/getPurchasesByCustomerId",
  // eslint-disable-next-line default-param-last
  async (
    // eslint-disable-next-line default-param-last
    payload = {},
    { rejectWithValue }
  ) => {
    try {
      const data = await fetchData(
        payload?.data,
        payload?.method,
        payload?.apiName
      );
      return {
        ...defaultState.List,
        message: data?.data.Message,
        data: data?.data?.data,
      };
    } catch (error) {
      return rejectWithValue({
        ...defaultReject.List,
        message: error.message,
      });
    }
  }
);

// PublishPostSlice
const getPurchasesByCustomerIdSlice = createSlice({
  name: "getPurchasesByCustomerIdSlice",
  initialState: {
    getPurchasesByCustomerId: {
      ...defaultState.List,
      loading: false, 
      error: false, 
    },
  },
  reducers: {
    setInitialStateOfgetPurchasesByCustomerId: (state) => {
        state.getPurchasesByCustomerId.loading = false
        state.getPurchasesByCustomerId.error = false
        state.getPurchasesByCustomerId.data = []
        state.getPurchasesByCustomerId.message = ''
      }
  },
  extraReducers: (builder) => {
    builder.addCase(GETPURCHASESBYCUSTOMERID.fulfilled, (state, action) => {
      state.getPurchasesByCustomerId = {
        ...state.getPurchasesByCustomerId,
        loading: false,
        error: false,
        ...action.payload,
      };
    });
    builder.addCase(GETPURCHASESBYCUSTOMERID.pending, (state, action) => {
      state.getPurchasesByCustomerId = {
        ...state.getPurchasesByCustomerId,
        loading: true,
        error: false,
        ...action.payload,
      };
    });
    builder.addCase(GETPURCHASESBYCUSTOMERID.rejected, (state, action) => {
      state.getPurchasesByCustomerId = {
        ...state.getPurchasesByCustomerId,
        loading: false,
        error: true,
        ...action.payload,
      };
    });
  },
});

const getPurchasesByCustomerIdAction = {
    GETPURCHASESBYCUSTOMERID,
};

export { getPurchasesByCustomerIdAction };
export default getPurchasesByCustomerIdSlice.reducer;
export const {setInitialStateOfgetPurchasesByCustomerId} = getPurchasesByCustomerIdSlice.actions
