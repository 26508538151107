
// import React from "react";
// import { Box, Typography, Grid, Button } from "@mui/material";
// import PropertyImage from "../../Assets/featured-image-13.jpg.png";

// const ProsperitySteps = () => {
//   const steps = [
//     {
//       id: "1",
//       heading: "Heading 1",
//       text: "There are many variations of passages of Lorem Ipsum majority have suffered."
//     },
//     {
//       id: "2",
//       heading: "Heading 1",
//       text: "There are many variations of passages of Lorem Ipsum majority have suffered."
//     },
//     {
//       id: "3",
//       heading: "Heading 1",
//       text: "There are many variations of passages of Lorem Ipsum majority have suffered."
//     },
//   ];

//   return (
//     <>
//       <Box sx={{ p: 4 }}>
//         {/* <Typography variant="h4" align="center" gutterBottom sx={{borderBottom:"2px solid #00675B",borderWidth:"20px"}}>
//          <span style={{color:"#FFAA17"}}>Three</span> Steps For Prosperity
//         </Typography> */}
//         <Box >
//           <Typography
//             variant="h4"
//             align="center"
//             gutterBottom
//             sx={{ paddingBottom: "5px" }}
//           >
//             <span style={{ color: "#FFAA17" }}>Three</span> Steps For Prosperity
//           </Typography>
//           <Box
//             sx={{
//               borderBottom: "5px solid #00675B",

//               width: "200px",
//               margin: "0 auto 40px auto",
//             borderRadius:'5px'
//             }}
//           />
//         </Box>

//         <Grid container spacing={3} alignItems="center">
//           <Grid
//             item
//             xs={12}
//             md={6}
//             sx={{
//               justifyContent: "center",
//               display: "flex",
//               alignItems: "center",
//               flexDirection: "column",
//               // width: "400px",

//             }}
//           >
//             {steps.map((text, index) => (
//               <Box
//                 key={index}
//                 mb={3}
//                 sx={{

//                   display: "flex",
//                   alignItems: "center",
//                   gap: 2,
//                   mb: 4

//                 }}
//               >

//                 <Box
//                   sx={{
//                     width: "40px",
//                     height: "40px",
//                     borderRadius: "50%",
//                     backgroundColor: "#FEB729",
//                     color: "white",
//                     display: "flex",
//                     justifyContent: "center",
//                     alignItems: "center",
//                     fontWeight: "bold",
//                     fontSize: "1.5rem", // Adjust font size for better appearance
//                   }}
//                 >
//                   {index + 1}
//                 </Box>
//                 <Box>
//                   <Typography variant="h5" sx={{ flexGrow: 1, mb: 1 }}>
//                     {text.heading}
//                   </Typography>
//                   <Typography variant="body1" sx={{ flexGrow: 1, maxWidth: "400px", color: "#686A6F", fontSize: '12px' }}>
//                     {text.text}
//                   </Typography>
//                 </Box>

//               </Box>
//             ))}
//           </Grid>
//           <Grid item xs={12} md={6}>
//             <img
//               src={PropertyImage}
//               alt="Steps for Prosperity"
//               style={{ width: "100%", borderRadius: "8px" }}
//             />
//           </Grid>
//         </Grid>
//       </Box>
//       <Box
//         sx={{
//           backgroundColor: "#F2F2F2",
//           color: "#4E5054",
//           py: 4,
//           display:"flex",
//           justifyContent:"space-around",
//           // textAlign: "center",
//           alignItems:"center"
//         }}
//       >
//         <Typography variant="h4" component="h1" gutterBottom >
//           REFER & EARN
//         </Typography>
//         <Button variant="contained"  sx={{
//           width:"160px",height:"47px", 
//           // borderRadius:"0",
//           color:"#222429",

//           // padding:"25px",
//           bgcolor:"#FFAA17",textTransform: "none",display:"flex",justifyContent:"center",alignItems:"center"}}>
//           Get Started
//         </Button>
//       </Box>
//     </>
//   );
// };

// export default ProsperitySteps;



// -----------------------------------------------------------------------------------------------------------





// import React from "react";
// import { Box, Typography, Grid, Button } from "@mui/material";
// import PropertyImage from "../../Assets/featured-image-13.jpg.png";
// import ProsperityCard from "../../Components/ProsperityCard/ProsperityCard";
// import StorefrontIcon from "@mui/icons-material/Storefront";
// import styled from "@emotion/styled";


// const DashedArrow = styled("div")(({ theme }) => ({
//   position: "absolute",
//   borderTop: "2px dashed black",
//   width: "calc(100% - 120px)", // Adjusted to fit between cards
//   top: "40px", // Centered to the avatar
//   left: "60px",
//   "&:after": {
//     content: '""',
//     position: "absolute",
//     top: "-6px",
//     right: "-12px",
//     width: "0",
//     height: "0",
//     borderLeft: "8px solid transparent",
//     borderRight: "8px solid transparent",
//     borderTop: "8px solid black",
//   },
// }));
// const ProsperitySteps = () => {
//   const steps = [
//     {
//       title: "Lorem Ipsum",
//       description:
//         "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s",
//       icon: <StorefrontIcon sx={{ fontSize: 40 }} />,
//     },
//     {
//       title: "Lorem Ipsum",
//       description:
//         "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s",
//       icon: <StorefrontIcon sx={{ fontSize: 40 }} />,
//     },
//     {
//       title: "Lorem Ipsum",
//       description:
//         "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s",
//       icon: <StorefrontIcon sx={{ fontSize: 40 }} />,
//     },
//   ];

//   return (
//     <>
//       <Box sx={{ p: 4 }}>
//         {/* <Typography variant="h4" align="center" gutterBottom sx={{borderBottom:"2px solid #00675B",borderWidth:"20px"}}>
//          <span style={{color:"#FFAA17"}}>Three</span> Steps For Prosperity
//         </Typography> */}
//         <Box sx={{ textAlign: "center" }}>
//           <Typography
//             variant="h4"
//             align="center"
//             gutterBottom
//             sx={{ paddingBottom: "5px", fontSize: "30px" }}
//           >
//             <span style={{ color: "black", fontWeight: "800" }}>Three</span> Steps For Prosperity
//           </Typography>
//           {/* <Box
//             sx={{
//               borderBottom: "5px solid #00675B",

//               width: "200px",
//               margin: "0 auto 40px auto",
//             borderRadius:'5px'
//             }}
//           /> */}
//           <Typography
//             // variant="body1"
//             textAlign="center"
//             gutterBottom
//             sx={{
//               paddingBottom: "5px", maxWidth: '900px', margin: "0 auto", textAlign: "center",
//               color: "#b0aaaa",
//               fontSize: "14px"
//             }}
//           >
//             Lorem Ipsum is simply dummy text of the printing and
//             typesetting industry. Lorem Ipsum has been the industry’s
//             standard dummy text ever since the 1500s, when an unknown
//             printer took a galley of type and scrambled it to make a type specimen book.


//           </Typography>
//         </Box>

//         <Box sx={{ position: "relative" }}>
//           <Grid container justifyContent="center" spacing={4} alignItems="center">
//             {steps.map((item, index) => (
//               <Grid item xs={12} sm={4} key={index}>
//                 <ProsperityCard
//                   icon={item.icon}
//                   title={item.title}
//                   description={item.description}
//                 />
//                 {/* Add the dashed arrow except for the last item */}
//                 {index < steps.length - 1 && <DashedArrow />}
//               </Grid>
//             ))}
//           </Grid>
//         </Box>

//       </Box>

//     </>
//   );
// };

// export default ProsperitySteps;



import React from "react";
import { Box, Typography, Grid } from "@mui/material";
import StorefrontIcon from "@mui/icons-material/Storefront";
import styled from "@emotion/styled";
import ProsperityCard from "../../../Components/ProsperityCard/ProsperityCard";
import arrowUpImageCard from "../../../Assets/arrowUpImage.svg"
import arrowDowmImage from "../../../Assets/arrowdDownImage.svg"

const DashedArrow = styled("div")(() => ({
  position: "absolute",
  borderTop: "2px dashed black",
  width: "calc(33% - 40px)", // Adjust the width for proper spacing
  top: "50%",
  transform: "translateY(-50%)",
  left: "calc(33% - 20px)", // Adjust for spacing between cards
  "&:after": {
    content: '""',
    position: "absolute",
    top: "-6px",
    right: "-12px",
    width: "0",
    height: "0",
    borderLeft: "8px solid transparent",
    borderRight: "8px solid transparent",
    borderTop: "8px solid black",
  },
}));


const ProsperitySteps = ({ stakeNowDates }) => {
  const steps =
    stakeNowDates?.[0]?.additional_data?.card_design || [
      {
        title: "Step One",
        content:
          "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
        logo: "",
      },
      {
        title: "Step Two",
        content:
          "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
        logo: '',
      },
      {
        title: "Step Three",
        content:
          "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
        logo: '',
      },
    ];

  return (
    <>
      <Box sx={{ px: 2, py: 4, pb: 6 ,background:"#F7F7F7"}}>
        <Box sx={{ textAlign: "center", mb: 4 }}>
          <Typography
            variant="h3"
            gutterBottom
            sx={{
              paddingBottom: "5px",
              //  fontSize: "30px" 
            }}
          >
            <span style={{ color: "black", fontWeight: "800" }}>{stakeNowDates?.[0]?.sub_heading || "Default Sub-heading"}</span> {stakeNowDates?.[0]?.heading || "Default Heading"}
          </Typography>
          <Typography
            variant="body1"
            sx={{
              paddingBottom: "5px",

              maxWidth: "800px",
              margin: "0 auto",
              textAlign: "center",
              color: "#b0aaaa",

            }}
          >
            {stakeNowDates?.[0]?.content || "Default Content"}

          </Typography>
        </Box>

        {/* <Box sx={{ position: "relative", display: "flex", justifyContent: "space-between" }}>
          <Grid container justifyContent="center" spacing={4} alignItems="center">
            {steps.map((item, index) => (
              <Grid item xs={12} sm={4} key={index}>
                <ProsperityCard
                  icon={item.icon}
                  title={item.title}
                  description={item.description}
                />
                <Box sx={{ border: "1px dashed red" }}> </Box>

              </Grid>

            ))}
          </Grid> */}

        <Box
          sx={{
            position: "relative",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Grid container justifyContent="center" spacing={4} alignItems="center">
            {steps.map((item, index) => (
              <Grid item xs={12} sm={4} key={index} sx={{ position: "relative" }}>
                {/* Upward Arrow */}
                {index > 0 && index % 2 === 0 && (
                  <Box
                    sx={{
                      position: { xs: "none", md: "absolute" },
                      top: { sm: "26px" }, // Adjust for mobile and larger screens
                      left: { sm: "-95%" },
                      transform: "translateX(-50%)",
                      display: { xs: "none", md: "block" }, // Hide on mobile, show on desktop
                    }}
                  >
                    <img
                      src={arrowUpImageCard}
                      alt="Upward Arrow"
                      style={{
                        height: "auto", // Maintain aspect ratio
                        maxWidth: "183px", // Limit the maximum size
                      }}
                    />
                  </Box>
                )}

                {/* Card */}
                <ProsperityCard
                  icon={item.logo}
                  title={item.title}
                  description={item.content}
                />

                {/* Downward Arrow */}
                {index < steps.length - 1 && index % 2 !== 0 && (
                  <Box
                    sx={{
                      position: { xs: "none", md: "absolute" },
                      bottom: { xs: "50px", sm: "105px" },
                      left: { xs: "90%", sm: "107%" },
                      transform: "translateX(-50%)",
                      display: { xs: "none", md: "block" }, // Hide on mobile, show on desktop
                    }}
                  >
                    <img
                      src={arrowDowmImage}
                      alt="Downward Arrow"
                      style={{
                        height: "auto", // Maintain aspect ratio
                        maxWidth: "182px", // Limit the maximum size
                      }}
                    />
                  </Box>
                )}
              </Grid>
            ))}
          </Grid>
        </Box>



        {/* Add the dashed arrows between the cards */}
        {/* {steps.slice(0, -1).map((_, index) => (
            <DashedArrow key={index} style={{ left: `${(index + 1) * 33.33}%` }} />
          ))} */}
        {/* </Box> */}
      </Box>
    </>
  );
};

export default ProsperitySteps;
