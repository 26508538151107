

/* eslint-disable no-sequences */
/* eslint-disable no-unused-vars */
/* eslint-disable no-param-reassign */
/* eslint-disable no-unused-expressions */

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { defaultReject, defaultState } from "../../../constants";
import { fetchData } from "../../../helpers";
// customerRegister 
// CUSTOMERREGISTER
const EMAILVERIFYOTP= createAsyncThunk(
  "EmailVerifyOtp/EmailVerifyOtp",
  // eslint-disable-next-line default-param-last
  async (
    // eslint-disable-next-line default-param-last
    payload = {},
    { rejectWithValue }
  ) => {
    try {
      const data = await fetchData(
        payload?.data,
        payload?.method,
        payload?.apiName
      );
      return {
        ...defaultState.List,
        message: data?.data.Message,
        data: data?.data?.data,
      };
    } catch (error) {
      return rejectWithValue({
        ...defaultReject.List,
        message: error.message,
      });
    }
  }
);

// PublishPostSlice
const EmailVerifyOtpSlice = createSlice({
  name: "EmailVerifyOtpSlice",
  initialState: {
    EmailVerifyOtp: {
      ...defaultState.List,
      loading: false, 
      error: false, 
    },
  },
  reducers: {
    setInitialStateOfEmailVerifyOtp: (state) => {
        state.EmailVerifyOtp.loading = false
        state.EmailVerifyOtp.error = false
        state.EmailVerifyOtp.data = []
        state.EmailVerifyOtp.message = ''
      }
  },
  extraReducers: (builder) => {
    builder.addCase(EMAILVERIFYOTP.fulfilled, (state, action) => {
      state.EmailVerifyOtp = {
        ...state.EmailVerifyOtp,
        loading: false,
        error: false,
        ...action.payload,
      };
    });
    builder.addCase(EMAILVERIFYOTP.pending, (state, action) => {
      state.EmailVerifyOtp = {
        ...state.EmailVerifyOtp,
        loading: true,
        error: false,
        ...action.payload,
      };
    });
    builder.addCase(EMAILVERIFYOTP.rejected, (state, action) => {
      state.EmailVerifyOtp = {
        ...state.EmailVerifyOtp,
        loading: false,
        error: true,
        ...action.payload,
      };
    });
  },
});

const EmailVerifyOtpAction = {
    EMAILVERIFYOTP,
};

export { EmailVerifyOtpAction };
export default EmailVerifyOtpSlice.reducer;
export const {setInitialStateOfEmailVerifyOtp} = EmailVerifyOtpSlice.actions
