// // import React from "react";
// // import clsx from 'clsx';

// // const StatusCard = ({ icon, bgcolor, iconcolor }) => {
// //   const color = "blue"
// //   const hov = "red"
// //   return (
// //     <div
// //       // className={`p-4 bg-white rounded-lg shadow-lg border-b-[4px] border-b-${color}-500 hover:border-b-${hov}`}>
// //       className={clsx(
// //         "p-4 bg-white rounded-lg shadow-lg",
// //         `border-b-[4px] border-b-${color}-500`,
// //         `hover:border-b-${hov}-500`
// //       )}
// //     >
// //       <div className="flex items-center">
// //         {/* Icon */}
// //         <div className={`p-2  rounded text-[12px]`} style={{ background: bgcolor, color: iconcolor }}>
// //           {icon}

// //         </div>
// //         {/* Text */}
// //         <div className="ml-4">
// //           <h3 className="text-xl font-semibold text-gray-800">42,026</h3>
// //           {/* <p className="text-sm text-gray-500">Total Users</p> */}
// //         </div>
// //       </div>
// //       <p className="text-sm text-black-500 pt-2">Total Users</p>
// //       <p className="mt-1 text-sm text-black-500">
// //         +18.2% <span className="text-gray-500 text-[10px]">than last week</span>
// //       </p>
// //     </div>
// //   );
// // };

// // export default StatusCard;



// import React from "react";
// import { Box, Typography, Icon, Paper } from "@mui/material";
// import { clsx } from "clsx";

// const StatusCard = ({ icon, bgcolor, iconcolor }) => {
//   const color = "blue";
//   const hov = "red";

//   return (
//     <Paper
//       sx={{
//         padding: 2,
//         backgroundColor: "white",
//         borderRadius: 2,
//         boxShadow: 3,

//       }}
//     >

//       <Typography variant="subtitle2" sx={{ fontSize: "14px", color: "#7A7A7A" }} >
//         Total Users
//       </Typography>
//       <Typography variant="body2" color="#C38F51" mt={1}>17.72 
//         <span style={{ fontSize: "16px", color: "black", fontWeight: "400" }}>Grams </span>
//       </Typography>
//     </Paper>
//   );
// };

// export default StatusCard;


import React from "react";
import { Box, Typography, Icon, Paper } from "@mui/material";
import { clsx } from "clsx";
 
const StatusCard = ({ icon, bgcolor, iconcolor, text, Grams}) => {
  const color = "blue";
  const hov = "red";
 
  return (
    <Paper
      sx={{
        padding: 2,
        backgroundColor: "white",
        borderRadius: 2,
        boxShadow: 3,
 
      }}
    >
 
      <Typography variant="subtitle2" sx={{ fontSize: "14px", color: "#7A7A7A" }} >
        {text}
      </Typography>
      <Typography variant="body2" color="#C38F51" mt={1}>{Grams}
        <span style={{ fontSize: "16px", color: "black", fontWeight: "400" }}> Grams </span>
      </Typography>
    </Paper>
  );
};
 
export default StatusCard;