



import React, { useEffect, useState } from 'react'
// import StatusCard from '../../../Components/StatusCard/StatusCard'
import { ImTree } from 'react-icons/im'
import { Box, Grid } from '@mui/material'

// import CustomTable from '../../../Components/Table/CustomTable'
import StatusCard from '../../Components/StatusCard/StatusCard'
import CustomTable from '../../Components/Table/CustomTable'
import actions from "../../ReduxStore/actions/index";
import { useDispatch, useSelector } from "react-redux";

const CustomerReferals = () => {

  const dispatch = useDispatch();

  const tableHeadData = ['S.No', 'Scheme Name', 'Member Id', 'Referral', 'Purchase', "Amount", "Type", "Benefit", 'Actions'];
  const sessioncustomer_id = sessionStorage.getItem("sessioncustomer_id");
  const sessionFirstName = sessionStorage.getItem("sessionFirstName");


  const tableData = [
    { id: 1, title: 'G1- Introduction', customer_id: 'GS0001011', createdAt: '15-Nov-2024', purchase_date: "15-Nov-2026", value_of_purchase: "5000 USD", tier_name: "Diamond", reward_amount_gold_grams: "5mg of Gold" },
    { id: 2, title: 'G1- Introduction', MemberId: 'GS0001011', Referral: '15-Nov-2024', Purchase: "15-Nov-2026", Amount: "5000 USD", Value: "Diamond", Benefit: "5mg of Gold" },
  ];

  const { getReferralRewardDashboard } = useSelector((state) => state?.getReferralRewardDashboard);

  console.log(getReferralRewardDashboard, "getReferralRewardDashboard");


  useEffect(() => {
    const fetchData = async () => {
      const data1 = { data: {}, method: "get", apiName: `getReferralRewardDashboard/${sessioncustomer_id}` };
      await dispatch(actions.GETREFERRALREWARDDASHBOADD(data1));
    };
    fetchData();
  }, [dispatch]);





  const { getCustomerRewardsList } = useSelector((state) => state?.getCustomerRewardsList);
  console.log(getCustomerRewardsList?.data, "getCustomerRewardsList");

  useEffect(() => {
    const fetchData = async () => {
      const data1 = { data: {}, method: "get", apiName: `getCustomerRewardsList/customemr/${sessioncustomer_id}` };
      await dispatch(actions.GETCUSTOMERREWARDSLIST(data1));
    };
    fetchData();
  }, [dispatch]);

  //  reward_amount_gold_grams: "5mg of Gold" },


  const [rowTableData, setRowTableData] = useState([
    {
      title: "--",
      customer_id: "--",
      createdAt: "--",
      purchase_date: "--",
      value_of_purchase: "--",
      tier_name: "--",
      reward_amount_gold_grams: "--",
    },
  ]);

  useEffect(() => {
    const tempArr = [];
    getCustomerRewardsList?.data?.map((data, index) => {

      return tempArr.push({
        title: data?.purchase?.title,
        customer_id: data?.triggered_by?.customer_id,
        createdAt: data?.createdAt,
        purchase_date: data?.purchase?.purchase_date,
        value_of_purchase: data?.purchase?.value_of_purchase,
        tier_name: data?.tier_name,
        reward_amount_gold_grams: data?.reward_amount_gold_grams,

      });
    });
    setRowTableData(tempArr);
  }, [dispatch, getCustomerRewardsList]);



  return (
    <div>
      <Grid container spacing={4}>
        {getReferralRewardDashboard?.data?.levels?.map((item, index) => (
          <Grid item xs={12} md={4} lg={3} sm={4} key={index}>
            <StatusCard
              icon={<ImTree />}
              bgcolor="#e5e5e8"
              iconcolor="#666cff"
              text={item.tier_name}
              Grams={item.total_gold_grams}
              count={item.reward_count}
            />
          </Grid>
        ))}

        <Grid item xs={12} md={4} lg={3} sm={4}>
          <StatusCard icon={<ImTree />} bgcolor="#FFE4E2" iconcolor="#FF4D49" text="Referrals Bonus Earned" Grams={getReferralRewardDashboard?.data?.total_gold_grams_of_all_tier?.total_gold_grams_of_all_tier} />
        </Grid>

      </Grid>

      <Grid sx={{ background: 'white', borderRadius: "5px", mt: 2 }}>

        <CustomTable
          tableData={rowTableData}
          tableHeadData={tableHeadData}
        />
      </Grid>

    </div>
  )
}

export default CustomerReferals
