





/* eslint-disable no-sequences */
/* eslint-disable no-unused-vars */
/* eslint-disable no-param-reassign */
/* eslint-disable no-unused-expressions */

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { defaultReject, defaultState } from "../../../constants";
import { fetchData } from "../../helpers";
// PublishPost
// PUBLISHPOST
const GETKYCBYCOUNTRY= createAsyncThunk(
  "getKYCByCountry/getKYCByCountry",
  // eslint-disable-next-line default-param-last
  async (
    // eslint-disable-next-line default-param-last
    payload = {},
    { rejectWithValue }
  ) => {
    try {
      const data = await fetchData(
        payload?.data,
        payload?.method,
        payload?.apiName
      );
      return {
        ...defaultState.List,
        message: data?.data.Message,
        data: data?.data?.data,
      };
    } catch (error) {
      return rejectWithValue({
        ...defaultReject.List,
        message: error.message,
      });
    }
  }
);

// PublishPostSlice
const getKYCByCountrySlice = createSlice({
  name: "getKYCByCountrySlice",
  initialState: {
    getKYCByCountry: {
      ...defaultState.List,
      loading: false, 
      error: false, 
    },
  },
  reducers: {
    setInitialStateOfgetKYCByCountry: (state) => {
        state.getKYCByCountry.loading = false
        state.getKYCByCountry.error = false
        state.getKYCByCountry.data = []
        state.getKYCByCountry.message = ''
      }
  },
  extraReducers: (builder) => {
    builder.addCase(GETKYCBYCOUNTRY.fulfilled, (state, action) => {
      state.getKYCByCountry = {
        ...state.getKYCByCountry,
        loading: false,
        error: false,
        ...action.payload,
      };
    });
    builder.addCase(GETKYCBYCOUNTRY.pending, (state, action) => {
      state.getKYCByCountry = {
        ...state.getKYCByCountry,
        loading: true,
        error: false,
        ...action.payload,
      };
    });
    builder.addCase(GETKYCBYCOUNTRY.rejected, (state, action) => {
      state.getKYCByCountry = {
        ...state.getKYCByCountry,
        loading: false,
        error: true,
        ...action.payload,
      };
    });
  },
});

const getKYCByCountryAction = {
    GETKYCBYCOUNTRY,
};

export { getKYCByCountryAction };
export default getKYCByCountrySlice.reducer;
export const {setInitialStateOfgetKYCByCountry} = getKYCByCountrySlice.actions
