






import React from 'react';
import AddtoCartMainPage from './AddtoCartMainPage';
import AddToCartSchemesById from './AddToCartSchemesById';
import GoldInvestment from './GoldInvestment';
import GoldInfoAccordion from './GoldInfoAccordion';


const AddToCartHome = () => {
    const handleScrollToTop = () => {
        window.scrollTo({ top: 0, behavior: "smooth" });
    };
    return (

        <div style={{ position: "relative" }}>
            {/* <MyAppBar /> */}

            {/* <AddtoCartMainPage /> */}
            <AddToCartSchemesById />
            <GoldInvestment />
            <GoldInfoAccordion />

            {/* <Footer /> */}


        </div>
    )
}


export default AddToCartHome;



