
import React, { useEffect, useState } from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Grid,
  Box,
  InputBase,
  Menu,
  MenuItem,
  Button,
  CardMedia,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Link, useLocation, useNavigate } from "react-router-dom";
import "./CustomAppBar.css";
import call_image_white from "../../Assets/call_white_color.svg"
import logo_img from "../../Assets/new_logo.png";
import search_icom from "../../Assets/search_icom.svg";

import profile_icon from "../../Assets/profile_icon.svg";

import cart_icon from "../../Assets/cart_icon.svg";
import { useDispatch, useSelector } from "react-redux";
import actions from "../../ReduxStore/actions";





const MyAppBar = () => {

  const sessioncustomer_id = sessionStorage.getItem("sessioncustomer_id");
  const sessionFirstName = sessionStorage.getItem("sessionFirstName");
  const location = useLocation();
  const dispatch = useDispatch()
  const [drawerOpen, setDrawerOpen] = useState(false);
  const { getCartItemCount } = useSelector((state) => state?.getCartItemCount);
  console.log(getCartItemCount, "getCartItemCountgetCartItemCount")
  useEffect(() => {
    const fetchData = async () => {
      const data1 = { data: {}, method: "get", apiName: `getCartItemCount/${sessioncustomer_id}` };
      await dispatch(actions.GETCARTITEMCOUNT(data1));
    };
    fetchData();
  }, [dispatch]);


  const navItems = [
    { label: "Dashboard", path: "/Customer-dashboard", requiresSession: true },


    { label: "Home", path: "/" },
    { label: "Stake Now", path: "/stake-now" },
    { label: "Referrals", path: "/referrals" },

    { label: "About Us", path: "/about-us" },
    { label: "Blog", path: "/blogs" },


    { label: "Contact Us", path: "/Contactus" },

  ];

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setDrawerOpen(open);
  };



  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleNavigationLogout = (path) => {
    sessionStorage.setItem("sessioncustomer_id", null);

    sessionStorage.setItem("sessionFirstName", "");
    navigate(path);
    handleMenuClose();
  };

  const handleNavigation = (path) => {
    navigate(path);
    handleMenuClose();
  };




  const [isCurrencyMenuOpen, setCurrencyMenuOpen] = useState(false);
  const [currencyAnchorEl, setCurrencyAnchorEl] = useState(null);
  const [selectedCurrency, setSelectedCurrency] = useState('$ US Dollar');

  // Open menu
  const handleCurrencyMenuOpen = (event) => {
    setCurrencyAnchorEl(event.currentTarget);
    setCurrencyMenuOpen(true);
  };

  // Close menu
  const handleCurrencyMenuClose = () => {
    setCurrencyMenuOpen(false);
  };

  // Handle currency selection
  const handleCurrencySelect = (currency) => {
    setSelectedCurrency(currency);
    console.log(`Selected Currency: ${currency}`);
    handleCurrencyMenuClose();  // Close the menu after selection
  };

  const filteredNavItems = navItems.filter((item) => {
    // Check if `requiresSession` is true and `sessioncustomer_id` is valid
    if (item.requiresSession) {
      return sessioncustomer_id && sessioncustomer_id !== "null";
    }
    return true;
  });



  return (
    <>
      <Box
        sx={{
          // width: "100%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          // padding: "5px 20px",
          p: { xs: "0px 20px", md: "0px 20px" },

          background: "#C38F51",
          color: "white",
        }}
      >

        <Typography variant="body1" sx={{ display: "flex", alignItems: "center" }}>
          <img src={call_image_white} style={{ marginRight: "5px", height: "15px", width: "15px" }}></img> +1 (123) 1234567
        </Typography>

        <Button
          endIcon={<ExpandMoreIcon />}
          onClick={handleCurrencyMenuOpen}
          sx={{
            color: 'white',
            textTransform: 'none',
            fontSize: '14px',
          }}
        >
          {selectedCurrency}
        </Button>
        <Menu
          anchorEl={currencyAnchorEl}
          open={isCurrencyMenuOpen}
          onClose={handleCurrencyMenuClose}
        >
          <MenuItem onClick={() => handleCurrencySelect('$ US Dollar')}
            sx={{
              '&:hover': {
                backgroundColor: 'white',
                color: '#C38F51',
              },
            }}
            fontSize="14px"><span style={{ fontWeight: "800", marginRight: "5px" }}>$ </span>US Dollar</MenuItem>
          <MenuItem onClick={() => handleCurrencySelect('€ Euro')}
            sx={{
              '&:hover': {
                backgroundColor: 'white',
                color: '#C38F51',
              },
            }}><span style={{ fontWeight: "800", marginRight: "5px" }}>€</span>Euro</MenuItem>
          <MenuItem onClick={() => handleCurrencySelect('₹ Rupee')}
            sx={{
              '&:hover': {
                backgroundColor: 'white',
                color: '#C38F51',
              },
            }}><span style={{ fontWeight: "800", marginRight: "5px" }}>₹ </span>Rupee</MenuItem>
        </Menu>

      </Box>
      <AppBar position="sticky" sx={{ boxShadow: "none" }}>


        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",

            // width: "100%",
            // padding: "10px 50px",
            p: { xs: "10px 10px", md: "20px 30px" },

            background: "white", borderBottom: "1px solid #C38F51"
          }}
        >

          <Box
            sx={{
              display: { xs: "none", md: "flex" },
              alignItems: "center",
              // background: "#f0f0f0",
              borderRadius: "4px",
              padding: "2px 10px",
              marginRight: "20px",
            }}
          >

            <Box sx={{
              border: "1px solid black",
              borderRadius: "50%", background: "black",
              // padding:"10px",
              display: "flex",
              justifyContent: "center", alignItems: "center",
              height: "35px",
              width: "35px"
            }}>
              <img src={search_icom} style={{ height: "16px", width: "16px" }}></img>

            </Box>
            <InputBase
              placeholder="Search…"
              inputProps={{ "aria-label": "search" }}
              sx={{ marginLeft: "10px", fontSize: "14px", borderBottom: "1.5px solid #C38F51" }}
            />
          </Box>
          {/* Logo */}
          <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            <CardMedia
              component="img"
              src={logo_img}
              alt="Logo"
              // height="50"
              sx={{ height: { xs: "30px", md: "50px" }, width: { xs: "95px", md: "180px" } }}

            // sx={{ verticalAlign: "middle" }}
            />


          </Box>

          {/* Search Bar */}


          {/* Icons */}
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",

              gap: "15px",
              color: "black",
            }}
          >
            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
              {/* <AccountCircleIcon /> */}
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  cursor: 'pointer',
                }}
                onClick={handleMenuOpen}
              >
                <Box
                  sx={{
                    border: '1px solid black',
                    borderRadius: '50%',
                    background: 'black',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    // height: { xs: "20px", md: '35px' },
                    // width: { xs: "20px", md: '35px' },

                    p: { xs: "5px", md: '10px' }

                  }}
                >
                  <Box
                    component="img"
                    src={profile_icon}
                    alt="profile-icon"
                    sx={{
                      height: { xs: "14px", md: '16px' },
                      width: { xs: "14px", md: '16px' },
                    }}
                  />
                </Box>
                <Box sx={{ paddingLeft: { xs: '5px', md: "10px" } }}>
                  <Typography variant="body1" sx={{ fontSize: { xs: '12px', md: "14px" } }}>
                    My Account
                  </Typography>

                  <Typography variant="body1" sx={{ fontSize: { xs: '10px', md: "12px" }, color: "#AAAAAA" }}>
                    {sessionFirstName ? sessionFirstName : "Register"}
                  </Typography>
                </Box>
              </Box>

              {/* <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
                sx={{ mt: 1 }}
              >
                <MenuItem onClick={() => handleNavigation('/login')}>Login</MenuItem>
                <MenuItem onClick={() => handleNavigation('/register')}>Register</MenuItem>
                <MenuItem onClick={() => handleNavigation('/profile')}>Profile</MenuItem>

              </Menu> */}
              {/* <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
                sx={{ mt: 1 }}
              >
                {sessioncustomer_id !== null ? (
                  <>
                    <MenuItem onClick={() => handleNavigation('/order-history')}>Order History</MenuItem>
                    <MenuItem onClick={() => handleNavigation('/profile')}>Profile</MenuItem>
                    <MenuItem onClick={() => handleNavigationLogout('/')}>Logout</MenuItem>
                  </>
                ) : (
                  <>
                    <MenuItem onClick={() => handleNavigation('/login')}>Login</MenuItem>
                    <MenuItem onClick={() => handleNavigation('/register')}>Register</MenuItem>
            </>
                )}
          </Menu> */}
              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
                sx={{ mt: 1 }}
              >
                {sessioncustomer_id === "null" || sessioncustomer_id === null ? (
                  // If sessionCustomerId is null or "null", show login and register options
                  <>
                    <MenuItem onClick={() => handleNavigation('/login')}>Login</MenuItem>
                    <MenuItem onClick={() => handleNavigation('/register')}>Register</MenuItem>
                  </>
                ) : (
                  // If sessionCustomerId is not null, show order history, profile, and logout options
                  <>
                    <MenuItem onClick={() => handleNavigation('/order-history')}>Order History</MenuItem>
                    <MenuItem onClick={() => handleNavigation('/profile')}>Profile</MenuItem>
                    <MenuItem onClick={() => handleNavigationLogout('/')}>Logout</MenuItem>
                  </>
                )}
              </Menu>


            </Box>
            {/* <Box sx={{ textAlign: "center" }}> */}
            {/* <Box

              onClick={() => { navigate('/addtocart') }}
              sx={{
                display: "flex", justifyContent: "space-between", alignItems: "center",
                cursor: 'pointer',
                cursor: 'pointer',

              }}>

              
              <Box


                sx={{
                  border: "1px solid black",
                  borderRadius: "50%", background: "black",
                  // padding:"10px",
                  display: "flex",
                  justifyContent: "center", alignItems: "center",
                  cursor: 'pointer',
                  p: { xs: "5px", md: '10px' }


                }}>
                <Box
                  component="img"
                  src={cart_icon}
                  alt="cart-icon"
                  sx={{
                    height: { xs: "14px", md: '16px' },
                    width: { xs: "14px", md: '16px' },
                  }}
                />
              </Box>
              <Box>
                <Typography variant="body1" sx={{
                  fontSize: { xs: '12px', md: "14px" },
                  paddingLeft: { xs: '5px', md: "10px" }


                }}>
                  My Cart
                </Typography>

              </Box>
            </Box> */}
            <Box
              onClick={() => navigate('/addtocart')}
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                cursor: 'pointer',
              }}
            >
              <Box
                sx={{
                  position: 'relative', // Required for badge positioning
                  border: '1px solid black',
                  borderRadius: '50%',
                  background: 'black',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  cursor: 'pointer',
                  p: { xs: '5px', md: '10px' },
                }}
              >
                <Box
                  component="img"
                  src={cart_icon}
                  alt="cart-icon"
                  sx={{
                    height: { xs: '14px', md: '16px' },
                    width: { xs: '14px', md: '16px' },
                  }}
                />
                {/* Notification Circle */}
                <Box
                  sx={{
                    position: 'absolute',
                    top: '3px',
                    right: '0px',
                    backgroundColor: '#C38F51',
                    borderRadius: '50%',
                    width: { xs: '16px', md: '20px' },
                    height: { xs: '16px', md: '20px' },
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    color: 'white',
                    fontSize: { xs: '10px', md: '12px' },
                    fontWeight: 'bold',
                    transform: 'translate(50%, -50%)',
                  }}
                >
                  {/* 2 */}
                  {getCartItemCount?.data?.item_count || 0}
                  {/* {cartCount}  */}
                </Box>
              </Box>
              <Box>
                <Typography
                  variant="body1"
                  sx={{
                    fontSize: { xs: '12px', md: '14px' },
                    paddingLeft: { xs: '5px', md: '10px' },
                  }}
                >
                  My Cart
                </Typography>
              </Box>
            </Box>

          </Box>
        </Box >
        <Toolbar
          sx={{
            display: "flex",
            justifyContent: "space-between",
            // paddingTop: "10px",
            // paddingBottom: "10px",
            // background: "#00675B",

            background: "white",
            boxShadow: " 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0)",
            // borderBottom: "1px solid #989898",
          }}
        >
          {/* Logo */}
          {/* <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            <img src={logo_image} alt="Logo" height="30" />
          </Typography> */}

          {/* Desktop Navigation */}
          <Grid
            container
            sx={{
              display: { xs: "none", md: "flex" },
              justifyContent: "center",
            }}
          >
            {filteredNavItems.map((item) => (

              <Grid item key={item.path}>
                <ListItem
                  component={Link}
                  to={item.path}
                  // className={`item ${location.pathname === item.path ? "active" : ""}`}
                  sx={{
                    textTransform: "none",
                    textDecoration: "none",
                    fontWeight: "500",

                    fontSize: "17px",
                    marginRight: "20px",
                    color: location.pathname === item.path ? "#C38F51" : "#252525",
                    borderRadius: 1,

                    "&:hover": {
                      color: "#C38F51",
                    },
                  }}
                >
                  {item.label}
                </ListItem>

              </Grid>
            ))}
          </Grid>

          {/* Mobile Menu Icon */}

          <IconButton
            edge="end"
            color="black"
            // alignItems="end"
            aria-label="menu"
            // sx={{ display: { xs: "block", md: "none" } }}

            sx={{ display: { xs: "block", md: "none" }, ml: "auto" }}
            onClick={toggleDrawer(true)}
          >
            <MenuIcon />
          </IconButton>

          {/* Mobile Drawer */}
          <Drawer
            anchor="left"
            open={drawerOpen}
            onClose={toggleDrawer(false)}
          >
            <Box
              sx={{ width: 250 }}
              role="presentation"
              onClick={toggleDrawer(false)}
              onKeyDown={toggleDrawer(false)}
            >
              <List>
                {filteredNavItems.map((item) => (
                  <ListItem key={item.path} disablePadding>
                    <ListItemButton component={Link} to={item.path}>
                      <ListItemText
                        primary={item.label}
                        sx={{
                          color: location.pathname === item.path ? "#ffaa17" : "black",
                        }}
                      />
                    </ListItemButton>
                  </ListItem>
                ))}
              </List>
            </Box>
          </Drawer>
        </Toolbar>
      </AppBar >
    </>
  );
};

export default MyAppBar;
