



import React, { useEffect, useState } from 'react'
// import StatusCard from '../../../Components/StatusCard/StatusCard'
import { ImTree } from 'react-icons/im'
import { Box, Grid } from '@mui/material'

// import CustomTable from '../../../Components/Table/CustomTable'
import StatusCard from '../../Components/StatusCard/StatusCard'
import CustomTable from '../../Components/Table/CustomTable'
import actions from "../../ReduxStore/actions/index";
import { useDispatch, useSelector } from "react-redux";

const CustomerDashboard = () => {
    const sessioncustomer_id = sessionStorage.getItem("sessioncustomer_id");
    const sessionFirstName = sessionStorage.getItem("sessionFirstName");


    const dispatch = useDispatch();

    const tableHeadData = ['S.No', 'Scheme Name', 'Order ID', 'Stake Starting', 'Stake Maturity', "Lock-In", "Value", "Gold Staked", 'Actions'];

    const { getCustomerGoldBalanceDashboard } = useSelector((state) => state?.getCustomerGoldBalanceDashboard);

    console.log(getCustomerGoldBalanceDashboard?.data, "getCustomerGoldBalanceDashboard");


    useEffect(() => {
        const fetchData = async () => {
            const data1 = { data: {}, method: "get", apiName: `getCustomerGoldBalanceDashboard/${sessioncustomer_id}` };
            await dispatch(actions.GETCUSTOMERGOLDBALANCEDASHBOARD(data1));
        };
        fetchData();
    }, [dispatch]);



    const { getPurchasesByCustomerId } = useSelector((state) => state?.getPurchasesByCustomerId);

    console.log(getPurchasesByCustomerId?.data, "getPurchasesByCustomerIdgetPurchasesByCustomerId");

    useEffect(() => {
        const fetchData = async () => {
            const data1 = { data: {}, method: "get", apiName: `getPurchasesByCustomerId/${sessioncustomer_id}` };
            await dispatch(actions.GETPURCHASESBYCUSTOMERID(data1));
        };
        fetchData();
    }, [dispatch]);


    // const tableData = [
    //     {
    //         purchase_id: 1, title: 'G1- Introduction',
    //         purchase_id: 'GS0001011', stake_starting: '15-Nov-2024',
    //         stake_maturity: "15-Nov-2026", lock_in_months: "2 YEARS",
    //         value_of_purchase: "5000 USD", purchase_stake: "55 GRAMS"
    //     },
    // ];


    const [rowTableData, setRowTableData] = useState([
        {
            purchase_id: "--",
            title: "--",
            purchase_id: "--",
            stake_starting: "--",
            stake_maturity: "--",
            lock_in_months: "--",
            value_of_purchase: "--",
            purchase_stake: "--",
        },
    ]);


    useEffect(() => {
        const tempArr = [];
        getPurchasesByCustomerId?.data?.map((data, index) => {

            return tempArr.push({
                title: data?.title,
                purchase_id: data?.purchase_id,
                purchase_id: data?.purchase_id,
                stake_starting: data?.stake_starting,
                stake_maturity: data?.stake_maturity,
                lock_in_months: data?.lock_in_months,
                value_of_purchase: data?.value_of_purchase,
                purchase_stake: data?.purchase_stake,
            });
        });
        setRowTableData(tempArr);
    }, [dispatch, getPurchasesByCustomerId]);


    return (
        <div>
            <Grid container spacing={4}>
                <Grid item xs={12} md={4} lg={3} sm={4}>
                    <StatusCard icon={<ImTree />} bgcolor="#e5e5e8" iconcolor="#666cff" text="Total Gold Purchase" Grams={getCustomerGoldBalanceDashboard?.data?.goldBalance?.total_purchased_gold || 0} />
                </Grid>
                <Grid item xs={12} md={4} lg={3} sm={4}>
                    <StatusCard icon={<ImTree />} bgcolor="#f9fedc" iconcolor="#FDB528" text="Additional Reap" Grams={getCustomerGoldBalanceDashboard?.data?.goldBalance?.earned_gold_referrals || 0} />
                </Grid>
                <Grid item xs={12} md={4} lg={3} sm={4}>
                    <StatusCard icon={<ImTree />} bgcolor="#FFE4E2" iconcolor="#FF4D49" text="Total Gold Available" Grams={getCustomerGoldBalanceDashboard?.data?.goldBalance?.available_gold || 0} />
                </Grid>
                <Grid item xs={12} md={4} lg={3} sm={4}>
                    <StatusCard icon={<ImTree />} bgcolor="#d9f7ff" iconcolor="#26C6F9" text="Today’s Gold Rate" Grams="50.6" />
                </Grid>

            </Grid>

            <Grid sx={{ background: 'white', borderRadius: "5px", mt: 2 }}>

                <CustomTable
                    tableData={rowTableData}
                    tableHeadData={tableHeadData}
                />
            </Grid>

        </div>
    )
}

export default CustomerDashboard
