

// import React, { useState } from "react";
// import {
//     Box,
//     Card,
//     CardMedia,
//     Typography,
//     Table,
//     TableBody,
//     TableCell,
//     TableContainer,
//     TableHead,
//     TableRow,
//     IconButton,
//     Button,
//     Divider,
//     Grid,
// } from "@mui/material";

// import cardImage2 from "../../../../Assets/home_page_Image.png";
// import cardImage1 from "../../../../Assets/sheme_small_image.png";
// import { CiSquarePlus, CiSquareMinus } from "react-icons/ci";
// import { Formik, Form } from "formik";
// import CustomInputLogin from "../../../../Components/CustomInput/CustomInput";
// import { AiFillThunderbolt } from "react-icons/ai";
// import { Navigate, useNavigate } from "react-router-dom";
// import { useSelector, useDispatch } from "react-redux";
// import { incrementQuantity, decrementQuantity } from "../../../../ReduxStore/Slices/AddToCard/cartSlice";

// const CartPageComponent = () => {

//     const cartItems = useSelector((state) => state.cart.cart);
//     const dispatch = useDispatch();
//     const navigate = useNavigate();

//     // const [cartItems, setCartItems] = useState([
//     //     { id: 1, name: "Scheme 1", price: 1000, quantity: 1, image: cardImage1 },
//     //     { id: 2, name: "Scheme 2", price: 10000, quantity: 1, image: cardImage2 },
//     // ]);

//     const initialValues = {
//         coupon: "",
//     };

//     const handleSubmit = (values) => {
//         console.log("Form values:", values);
//         navigate("/payment")
//         // Handle form submission logic here
//     };

//     // const handleQuantityChange = (id, delta) => {
//     //     setCartItems((prevItems) =>
//     //         prevItems.map((item) =>
//     //             item.id === id
//     //                 ? { ...item, quantity: Math.max(1, item.quantity + delta) }
//     //                 : item
//     //         )
//     //     );
//     // };

//     // const subtotal = cartItems.reduce(
//     //     (total, item) => total + item.price * item.quantity,
//     //     0
//     // );

//     // ==============================================================
//     const handleIncrement = (item) => {
//         dispatch(incrementQuantity(item));
//     };

//     const handleDecrement = (item) => {
//         dispatch(decrementQuantity(item));
//     };


//     const subtotal = cartItems.reduce(
//         (total, item) => total + item.price * item.quantity,
//         0
//     );

//     return (
//         <>
//             {/* <MyAppBar /> */}

//             <Grid
//                 container
//                 spacing={2}
//                 sx={{ p: { xs: 2, md: 4 }, flexDirection: { xs: "column", md: "row" } }}
//             >
//                 {/* Product Table */}
//                 <Grid item xs={12} md={8}>
//                     <TableContainer
//                         component={Card}
//                         sx={{
//                             p: 2,
//                             background: "#F7F7F7",
//                             border: "none",
//                             boxShadow: "none",
//                             width: "auto"
//                         }}
//                     >
//                         <Table>
//                             <TableHead>
//                                 <TableRow>
//                                     <TableCell
//                                         sx={{
//                                             border: "none",
//                                             // fontWeight: 700,
//                                             // fontSize: "16px",
//                                             whiteSpace: 'nowrap',  // Prevents text from wrapping
//                                             overflow: 'hidden',    // Hides overflow text
//                                             textOverflow: 'ellipsis', // Adds ellipsis for overflow text
//                                             textAlign: 'left',
//                                         }}
//                                     >
//                                         <Typography variant="h5"
//                                         // fontSize="16px"
//                                         >
//                                             Product
//                                         </Typography>

//                                     </TableCell>
//                                     <TableCell
//                                         sx={{
//                                             border: "none",
//                                             whiteSpace: 'nowrap',  // Prevents text from wrapping
//                                             overflow: 'hidden',    // Hides overflow text
//                                             textOverflow: 'ellipsis', // Adds ellipsis for overflow text
//                                             textAlign: 'left',

//                                         }}
//                                     >
//                                         <Typography variant="h5"
//                                         // fontSize="16px"
//                                         >
//                                             Price
//                                         </Typography>
//                                     </TableCell>
//                                     <TableCell
//                                         sx={{
//                                             border: "none",
//                                             whiteSpace: 'nowrap',  // Prevents text from wrapping
//                                             overflow: 'hidden',    // Hides overflow text
//                                             textOverflow: 'ellipsis', // Adds ellipsis for overflow text
//                                             textAlign: 'left',

//                                         }}
//                                     >

//                                         <Typography variant="h5"
//                                         // fontSize="16px"
//                                         >
//                                             Quantity
//                                         </Typography>
//                                     </TableCell>
//                                     <TableCell
//                                         sx={{
//                                             border: "none",
//                                             whiteSpace: 'nowrap',  // Prevents text from wrapping
//                                             overflow: 'hidden',    // Hides overflow text
//                                             textOverflow: 'ellipsis', // Adds ellipsis for overflow text
//                                             textAlign: 'left',

//                                         }}
//                                     >

//                                         <Typography variant="h5"
//                                         // fontSize="16px"
//                                         >
//                                             Total Price
//                                         </Typography>
//                                     </TableCell>
//                                 </TableRow>
//                             </TableHead>
//                             <TableBody>
//                                 {cartItems.map((item, index) => (
//                                     <TableRow
//                                         key={item.id}
//                                         sx={{
//                                             borderBottom:
//                                                 index === cartItems.length - 1
//                                                     ? "none"
//                                                     : "1px solid #E0E0E0",
//                                         }}
//                                     >
//                                         <TableCell sx={{
//                                             whiteSpace: 'nowrap',  // Prevents text from wrapping
//                                             overflow: 'hidden',    // Hides overflow text
//                                             textOverflow: 'ellipsis', // Adds ellipsis for overflow text
//                                             textAlign: 'left',
//                                         }}>
//                                             <Box
//                                                 sx={{
//                                                     display: "flex",
//                                                     alignItems: "center",
//                                                     gap: 2,
//                                                 }}
//                                             >
//                                                 <CardMedia
//                                                     component="img"
//                                                     image={item.image}
//                                                     alt={item.name}
//                                                     sx={{
//                                                         width: 60,
//                                                         height: 60,
//                                                         borderRadius: 1,
//                                                     }}
//                                                 />
//                                                 <Typography
//                                                     variant="body1"
//                                                     sx={{
//                                                         fontSize: "14px",
//                                                         color: "#7A7A7A",
//                                                     }}
//                                                 >
//                                                     {item.name}
//                                                 </Typography>
//                                             </Box>
//                                         </TableCell>
//                                         <TableCell variant="body1" sx={{
//                                             color: "#7A7A7A", whiteSpace: 'nowrap',  // Prevents text from wrapping
//                                             overflow: 'hidden',    // Hides overflow text
//                                             textOverflow: 'ellipsis', // Adds ellipsis for overflow text
//                                             textAlign: 'left',
//                                         }}>
//                                             ${item.price}
//                                         </TableCell>
//                                         <TableCell>
//                                             <Box
//                                                 sx={{
//                                                     display: "flex",
//                                                     alignItems: "center",
//                                                     whiteSpace: 'nowrap',  // Prevents text from wrapping
//                                                     overflow: 'hidden',    // Hides overflow text
//                                                     textOverflow: 'ellipsis', // Adds ellipsis for overflow text
//                                                     textAlign: 'left',
//                                                     // fontSize: "14px",
//                                                 }}
//                                             >
//                                                 <IconButton


//                                                     onClick={() => handleDecrement({
//                                                         ...item,
//                                                         id: item.id,
//                                                         name: item.name,
//                                                         price: item.price,
//                                                     })}

//                                                 >
//                                                     <CiSquareMinus
//                                                         style={{
//                                                             color: "#C38F51",
//                                                         }}
//                                                     />
//                                                 </IconButton>
//                                                 <Typography
//                                                     variant="body1"
//                                                 // sx={{ fontSize: "14px" }}
//                                                 >
//                                                     {item.quantity}
//                                                 </Typography>
//                                                 <IconButton
//                                                     onClick={() => handleIncrement({
//                                                         ...item,
//                                                         id: item.id,
//                                                         name: item.name,
//                                                         price: item.price,
//                                                     })}
//                                                 >
//                                                     <CiSquarePlus
//                                                         style={{
//                                                             color: "#C38F51",
//                                                         }}
//                                                     />
//                                                 </IconButton>
//                                             </Box>
//                                         </TableCell>
//                                         <TableCell
//                                             sx={{
//                                                 // fontSize: "14px",
//                                                 color: "#C38F51",
//                                                 whiteSpace: 'nowrap',  // Prevents text from wrapping
//                                                 overflow: 'hidden',    // Hides overflow text
//                                                 textOverflow: 'ellipsis', // Adds ellipsis for overflow text
//                                                 textAlign: 'left',
//                                             }}
//                                         >
//                                             <Typography variant="body1">
//                                                 ${item.price * item.quantity}
//                                             </Typography>
//                                         </TableCell>
//                                     </TableRow>
//                                 ))}
//                             </TableBody>
//                         </Table>
//                     </TableContainer>
//                 </Grid>

//                 {/* Order Summary */}
//                 <Grid item xs={12} md={4}>
//                     <Card
//                         sx={{
//                             p: 2,
//                             background: "#F7F7F7",
//                             border: "none",
//                             boxShadow: "none",
//                         }}
//                     >
//                         <Typography
//                             variant="h5"
//                             // gutterBottom
//                             sx={{
//                                 color: "#252525",
//                                 // fontWeight: 700,
//                                 // fontSize: "16px",
//                             }}
//                         >
//                             Order Summary
//                         </Typography>
//                         {/* <Divider /> */}
//                         {cartItems.map((item) => (
//                             <Box
//                                 key={item.id}
//                                 sx={{
//                                     display: "flex",
//                                     justifyContent: "space-between",
//                                     mt: 2,
//                                 }}
//                             >
//                                 <Typography
//                                     variant="body1"
//                                     sx={{
//                                         // fontSize: "14px",
//                                         color: "#7A7A7A",
//                                     }}
//                                 >
//                                     {item.quantity}x {item.name}
//                                 </Typography>
//                                 <Typography
//                                     variant="body1"

//                                     sx={{
//                                         // fontSize: "14px",
//                                         color: "#7A7A7A",
//                                     }}
//                                 >
//                                     ${item.price * item.quantity}
//                                 </Typography>
//                             </Box>
//                         ))}
//                         <Divider sx={{ my: 2 }} />
//                         <Box
//                             sx={{
//                                 display: "flex",
//                                 justifyContent: "space-between",
//                             }}
//                         >
//                             <Typography
//                                 variant="h5"
//                             // sx={{ fontSize: "16px" }}
//                             >
//                                 Sub Total
//                             </Typography>
//                             <Typography
//                                 variant="h5"
//                                 sx={{
//                                     fontSize: "16px",
//                                     color: "#C38F51",
//                                 }}
//                             >
//                                 ${subtotal}
//                             </Typography>
//                         </Box>
//                         <Formik
//                             initialValues={initialValues}
//                             onSubmit={handleSubmit}
//                         >
//                             {() => (
//                                 <Form>
//                                     <Box mt={2}>
//                                         <CustomInputLogin
//                                             name="coupon"
//                                             custPlaceholder="Apply Coupon"
//                                         />
//                                     </Box>
//                                     <Box
//                                         sx={{
//                                             display: "flex",
//                                             justifyContent: "center",
//                                             alignItems: "center",
//                                         }}
//                                     >
//                                         <Button
//                                             variant="contained"
//                                             type="submit"
//                                             startIcon={<AiFillThunderbolt />}
//                                             sx={{
//                                                 textTransform: "none",
//                                                 color: "white",
//                                                 // fontSize: "16px",
//                                                 padding: "7px 20px",
//                                                 mt: 2,
//                                                 minHeight: "30px",
//                                                 borderRadius: "23px",
//                                                 background:
//                                                     "#C38F51 0% 0% no-repeat padding-box",
//                                             }}
//                                         >
//                                             Checkout
//                                         </Button>
//                                     </Box>
//                                 </Form>
//                             )}
//                         </Formik>
//                     </Card>
//                 </Grid>
//             </Grid>

//             {/* <Footer /> */}
//         </>
//     );
// };

// export default CartPageComponent;





import React, { useState } from "react";
import {
    Box,
    Card,
    CardMedia,
    Typography,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    IconButton,
    Button,
    Divider,
    Grid,
} from "@mui/material";

import cardImage2 from "../../../../Assets/home_page_Image.png";
import cardImage1 from "../../../../Assets/sheme_small_image.png";
import { CiSquarePlus, CiSquareMinus } from "react-icons/ci";
import { Formik, Form } from "formik";
import CustomInputLogin from "../../../../Components/CustomInput/CustomInput";
import { AiFillThunderbolt } from "react-icons/ai";
import { Navigate, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { incrementQuantity, decrementQuantity } from "../../../../ReduxStore/Slices/AddToCard/cartSlice";

const CartPageComponent = () => {

    const cartItems = useSelector((state) => state.cart.cart);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    // const [cartItems, setCartItems] = useState([
    //     { id: 1, name: "Scheme 1", price: 1000, quantity: 1, image: cardImage1 },
    //     { id: 2, name: "Scheme 2", price: 10000, quantity: 1, image: cardImage2 },
    // ]);

    const initialValues = {
        coupon: "",
    };

    const handleSubmit = (values) => {
        console.log("Form values cartItems:", values);
        navigate("/payment")
        // Handle form submission logic here
    };

    // const handleQuantityChange = (id, delta) => {
    //     setCartItems((prevItems) =>
    //         prevItems.map((item) =>
    //             item.id === id
    //                 ? { ...item, quantity: Math.max(1, item.quantity + delta) }
    //                 : item
    //         )
    //     );
    // };

    // const subtotal = cartItems.reduce(
    //     (total, item) => total + item.price * item.quantity,
    //     0
    // );

    // ==============================================================
    const handleIncrement = (item) => {
        dispatch(incrementQuantity(item));
    };

    const handleDecrement = (item) => {
        dispatch(decrementQuantity(item));
    };


    const subtotal = cartItems.reduce(
        (total, item) => total + item.value_of_purchase * item.quantity,
        0
    );

    return (
        <>
            {/* <MyAppBar /> */}

            <Grid
                container
                spacing={2}
                sx={{ p: { xs: 2, md: 4 }, flexDirection: { xs: "column", md: "row" } }}
            >
                {/* Product Table */}
                <Grid item xs={12} md={8}>
                    <TableContainer
                        component={Card}
                        sx={{
                            p: 2,
                            background: "#F7F7F7",
                            border: "none",
                            boxShadow: "none",
                            width: "auto"
                        }}
                    >
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell
                                        sx={{
                                            border: "none",
                                            // fontWeight: 700,
                                            // fontSize: "16px",
                                            whiteSpace: 'nowrap',  // Prevents text from wrapping
                                            overflow: 'hidden',    // Hides overflow text
                                            textOverflow: 'ellipsis', // Adds ellipsis for overflow text
                                            textAlign: 'left',
                                        }}
                                    >
                                        <Typography variant="h5"
                                        // fontSize="16px"
                                        >
                                            Product
                                        </Typography>

                                    </TableCell>
                                    <TableCell
                                        sx={{
                                            border: "none",
                                            whiteSpace: 'nowrap',  // Prevents text from wrapping
                                            overflow: 'hidden',    // Hides overflow text
                                            textOverflow: 'ellipsis', // Adds ellipsis for overflow text
                                            textAlign: 'left',

                                        }}
                                    >
                                        <Typography variant="h5"
                                        // fontSize="16px"
                                        >
                                            Price
                                        </Typography>
                                    </TableCell>
                                    <TableCell
                                        sx={{
                                            border: "none",
                                            whiteSpace: 'nowrap',  // Prevents text from wrapping
                                            overflow: 'hidden',    // Hides overflow text
                                            textOverflow: 'ellipsis', // Adds ellipsis for overflow text
                                            textAlign: 'left',

                                        }}
                                    >

                                        <Typography variant="h5"
                                        // fontSize="16px"
                                        >
                                            Quantity
                                        </Typography>
                                    </TableCell>
                                    <TableCell
                                        sx={{
                                            border: "none",
                                            whiteSpace: 'nowrap',  // Prevents text from wrapping
                                            overflow: 'hidden',    // Hides overflow text
                                            textOverflow: 'ellipsis', // Adds ellipsis for overflow text
                                            textAlign: 'left',

                                        }}
                                    >

                                        <Typography variant="h5"
                                        // fontSize="16px"
                                        >
                                            Total Price
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {cartItems.map((item, index) => (
                                    <TableRow
                                        key={item.plan_id}
                                        sx={{
                                            borderBottom:
                                                index === cartItems.length - 1
                                                    ? "none"
                                                    : "1px solid #E0E0E0",
                                        }}
                                    >
                                        <TableCell sx={{
                                            whiteSpace: 'nowrap',  // Prevents text from wrapping
                                            overflow: 'hidden',    // Hides overflow text
                                            textOverflow: 'ellipsis', // Adds ellipsis for overflow text
                                            textAlign: 'left',
                                        }}>
                                            <Box
                                                sx={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    gap: 2,
                                                }}
                                            >
                                                <CardMedia
                                                    component="img"
                                                    image={item.image_url}
                                                    alt={item.title}
                                                    sx={{
                                                        width: 60,
                                                        height: 60,
                                                        borderRadius: 1,
                                                    }}
                                                />
                                                <Typography
                                                    variant="body1"
                                                    sx={{
                                                        fontSize: "14px",
                                                        color: "#7A7A7A",
                                                    }}
                                                >
                                                    {item.title}
                                                </Typography>
                                            </Box>
                                        </TableCell>
                                        <TableCell variant="body1" sx={{
                                            color: "#7A7A7A", whiteSpace: 'nowrap',  // Prevents text from wrapping
                                            overflow: 'hidden',    // Hides overflow text
                                            textOverflow: 'ellipsis', // Adds ellipsis for overflow text
                                            textAlign: 'left',
                                        }}>
                                            ${item.value_of_purchase}
                                        </TableCell>
                                        <TableCell>
                                            <Box
                                                sx={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    whiteSpace: 'nowrap',  // Prevents text from wrapping
                                                    overflow: 'hidden',    // Hides overflow text
                                                    textOverflow: 'ellipsis', // Adds ellipsis for overflow text
                                                    textAlign: 'left',
                                                    // fontSize: "14px",
                                                }}
                                            >
                                                <IconButton


                                                    onClick={() => handleDecrement({
                                                        ...item,
                                                        id: item.plan_id,
                                                        name: item.title,
                                                        price: item.value_of_purchase,
                                                    })}

                                                >
                                                    <CiSquareMinus
                                                        style={{
                                                            color: "#C38F51",
                                                        }}
                                                    />
                                                </IconButton>
                                                <Typography
                                                    variant="body1"
                                                // sx={{ fontSize: "14px" }}
                                                >
                                                    {item.quantity}
                                                </Typography>
                                                <IconButton
                                                    onClick={() => handleIncrement({
                                                        ...item,
                                                        id: item.plan_id,
                                                        name: item.title,
                                                        price: item.value_of_purchase,
                                                    })}
                                                >
                                                    <CiSquarePlus
                                                        style={{
                                                            color: "#C38F51",
                                                        }}
                                                    />
                                                </IconButton>
                                            </Box>
                                        </TableCell>
                                        <TableCell
                                            sx={{
                                                // fontSize: "14px",
                                                color: "#C38F51",
                                                whiteSpace: 'nowrap',  // Prevents text from wrapping
                                                overflow: 'hidden',    // Hides overflow text
                                                textOverflow: 'ellipsis', // Adds ellipsis for overflow text
                                                textAlign: 'left',
                                            }}
                                        >
                                            <Typography variant="body1">
                                                ${item.value_of_purchase * item.quantity}
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>

                {/* Order Summary */}
                <Grid item xs={12} md={4}>
                    <Card
                        sx={{
                            p: 2,
                            background: "#F7F7F7",
                            border: "none",
                            boxShadow: "none",
                        }}
                    >
                        <Typography
                            variant="h5"
                            // gutterBottom
                            sx={{
                                color: "#252525",
                                // fontWeight: 700,
                                // fontSize: "16px",
                            }}
                        >
                            Order Summary
                        </Typography>
                        {/* <Divider /> */}
                        {cartItems.map((item) => (
                            <Box
                                key={item.plan_id}
                                sx={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    mt: 2,
                                }}
                            >
                                <Typography
                                    variant="body1"
                                    sx={{
                                        // fontSize: "14px",
                                        color: "#7A7A7A",
                                    }}
                                >
                                    {item.quantity}x {item.title}
                                </Typography>
                                <Typography
                                    variant="body1"

                                    sx={{
                                        // fontSize: "14px",
                                        color: "#7A7A7A",
                                    }}
                                >
                                    ${item.value_of_purchase * item.quantity}
                                </Typography>
                            </Box>
                        ))}
                        <Divider sx={{ my: 2 }} />
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "space-between",
                            }}
                        >
                            <Typography
                                variant="h5"
                            // sx={{ fontSize: "16px" }}
                            >
                                Sub Total
                            </Typography>
                            <Typography
                                variant="h5"
                                sx={{
                                    fontSize: "16px",
                                    color: "#C38F51",
                                }}
                            >
                                ${subtotal}
                            </Typography>
                        </Box>
                        <Formik
                            initialValues={initialValues}
                            onSubmit={handleSubmit}
                        >
                            {() => (
                                <Form>
                                    <Box mt={2}>
                                        <CustomInputLogin
                                            name="coupon"
                                            custPlaceholder="Apply Coupon"
                                        />
                                    </Box>
                                    <Box
                                        sx={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                        }}
                                    >
                                        <Button
                                            variant="contained"
                                            type="submit"
                                            startIcon={<AiFillThunderbolt />}
                                            sx={{
                                                textTransform: "none",
                                                color: "white",
                                                // fontSize: "16px",
                                                padding: "7px 20px",
                                                mt: 2,
                                                minHeight: "30px",
                                                borderRadius: "23px",
                                                background:
                                                    "#C38F51 0% 0% no-repeat padding-box",
                                            }}
                                        >
                                            Checkout
                                        </Button>
                                    </Box>
                                </Form>
                            )}
                        </Formik>
                    </Card>
                </Grid>
            </Grid>

            {/* <Footer /> */}
        </>
    );
};

export default CartPageComponent;

